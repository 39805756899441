/** @format */

import { InputProps } from '@app/types/input.type';
import './Textarea.scss';

export default function Textarea({
    customRef,
    name,
    isReadOnly,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
}: InputProps) {
    function handlerChange(newValue: any): void {
        if (onChange) {
            onChange(newValue);
        }
    }

    function handlerFocus(): void {
        if (onFocus) {
            onFocus();
        }
    }

    function handlerBlur(): void {
        if (onBlur) {
            onBlur();
        }
    }

    return (
        <textarea
            ref={customRef}
            name={name}
            readOnly={isReadOnly}
            placeholder={placeholder}
            value={value}
            onChange={({ target }) => handlerChange(target.value)}
            onFocus={handlerFocus}
            onBlur={handlerBlur}
            className="textarea"></textarea>
    );
}
