/** @format */

import { iEnvironment } from '@app/types/environment.type';
import configDev from './config.dev';
import configProd from './config.prod';

const environmentKey =
    process.env.NODE_ENV === 'development' ? 'configDev' : 'configProd';
const Environment: iEnvironment =
    { configDev, configProd }[environmentKey] || configDev;

export { Environment };
