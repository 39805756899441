import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './Goods.scss';
import { GenderType, Good, SaleType } from '@app/types/good.type';
import Input from '@app/components/common/input/Input';
import { TranslationContext } from '@app/contexts/translationContext';
import Select from '@app/components/common/select/Select';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { SelectItem } from '@app/types/select.type';
import InputFile from '@app/components/common/input-file/InputFile';
import { IconType, ImageTypes, VideoTypes } from '@app/types/image.type';
import { LanguageType } from '@app/types/language.type';
import Textarea from '@app/components/common/textarea/Textarea';

enum FormMode {
    HIDDEN,
    ADD,
    EDIT,
}

export default function Goods() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const texts = useMemo(
        () => contextTranslation.PersonalArea.goods,
        [contextTranslation],
    );

    const goodTypes = useMemo(
        () =>
            Object.keys(contextTranslation.GoodTypes).map(key => ({
                name: contextTranslation.GoodTypes[key].title,
                value: key,
            })),
        [contextTranslation],
    );
    const [type, setType] = useState<SelectItem>(goodTypes[0]);
    useEffect(() => {
        if (!goodTypes.length) {
            return;
        }

        const currentType = goodTypes.find(gt => gt.value === type?.value);
        setType(currentType || goodTypes[0]);
    }, [goodTypes, type]);

    const [showForm, setShowForm] = useState(FormMode.HIDDEN);
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        titleEn: '',
        titleUa: '',
        titleRu: '',
        descriptionEn: '',
        descriptionUa: '',
        descriptionRu: '',
        applicationEn: '',
        applicationUa: '',
        applicationRu: '',
        additionalEn: '',
        additionalUa: '',
        additionalRu: '',
        quantity: '',
        price: '',
        sale: '',
        sex: '',
    });
    const [media, setMedia] = useState<string[]>([]);
    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [goods, setGoods] = useState<Good[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);
    const [isEditingStarted, setIsEditingStarted] = useState(false);
    const saleReasons = useMemo(
        () => [
            {
                name: texts.saleReason.default,
                value: SaleType.DEFAULT,
            },
            {
                name: texts.saleReason.brokenPackage,
                value: SaleType.BROKEN_PACKAGE,
            },
        ],
        [texts],
    );
    const [selectedReason, setSelectedReason] = useState(saleReasons[0]);

    const getGoodSubTypes = useCallback(
        (_type: string) => {
            const currentType = contextTranslation.GoodTypes[_type];
            if (!currentType || !currentType.subTypes) {
                return [];
            }

            return Object.keys(currentType.subTypes).map(key => ({
                name:
                    typeof currentType.subTypes[key] === 'string'
                        ? currentType.subTypes[key]
                        : currentType.subTypes[key].title || '',
                value: key,
            }));
        },
        [contextTranslation],
    );
    const goodSubTypes = useMemo(
        () => getGoodSubTypes(type.value),
        [contextTranslation, type],
    );
    const [subType, setSubType] = useState<SelectItem>(goodSubTypes[0]);
    useEffect(() => {
        if (!goodSubTypes.length) {
            return;
        }

        const currentType = goodSubTypes.find(
            gt => gt.value === subType?.value,
        );

        setSubType(currentType || goodSubTypes[0]);
    }, [goodSubTypes, subType]);

    const getGoodSubSubTypes = useCallback(
        (_type: string, _subType: string) => {
            const currentType = contextTranslation.GoodTypes[_type];
            const currentSubType = currentType?.subTypes?.[_subType];

            if (
                !currentSubType ||
                typeof currentSubType === 'string' ||
                !currentSubType.subTypes
            ) {
                return [];
            }

            return Object.keys(currentSubType.subTypes).map(key => ({
                name: currentSubType.subTypes[key],
                value: key,
            }));
        },
        [contextTranslation],
    );
    const goodSubSubTypes = useMemo(
        () => getGoodSubSubTypes(type.value, subType.value),
        [contextTranslation, type, subType],
    );
    const [subSubType, setSubSubType] = useState<SelectItem>(
        goodSubSubTypes[0],
    );
    useEffect(() => {
        if (goodSubSubTypes.length > 0) {
            const currentType = goodSubSubTypes.find(
                gt => gt.value === subSubType?.value,
            );
            setSubSubType(currentType || goodSubSubTypes[0]);
        }
    }, [goodSubSubTypes, subSubType]);

    const [manufacturers, setManufacturers] = useState<SelectItem[]>([]);
    const [manufacturer, setManufacturer] = useState({ value: '', name: '' });

    const sexs: SelectItem[] = useMemo(
        () => [
            { name: contextTranslation.Filter.woman, value: GenderType.WOMAN },
            { name: contextTranslation.Filter.man, value: GenderType.MAN },
            { name: contextTranslation.Filter.all, value: GenderType.ALL },
        ],
        [contextTranslation],
    );
    const [sex, setSex] = useState(sexs[0]);

    const isShowForm = useMemo(
        () => showForm === FormMode.ADD || showForm === FormMode.EDIT,
        [showForm],
    );
    const isButtonDisabled = useMemo(() => {
        return isEditingStarted || (
            (showForm === FormMode.ADD &&
                (Object.keys(formData).some(
                    key =>
                        key !== 'sale' && key !== 'sex' && formData[key] === '',
                ) ||
                    !media.length)) ||
            (showForm === FormMode.EDIT &&
                Object.keys(formData).every(
                    key => formData[key] === defaultData[key],
                ) &&
                defaultData.type === type.value &&
                defaultData.subType === subType.value &&
                defaultData?.subSubType === subSubType?.value &&
                defaultData.manufacturer === manufacturer.value &&
                defaultData.media === media &&
                defaultData.sex === sex.value)
        );
    }, [
        isEditingStarted,
        showForm,
        formData,
        defaultData,
        saleReasons,
        selectedReason,
        media,
        sex,
        type,
        subType,
        subSubType,
        manufacturer,
    ]);

    const GoodService = injectorService.get('GoodService');
    const ManufacturersService = injectorService.get('ManufacturersService');
    const NotificationService = injectorService.get('NotificationService');
    const TranslationService = injectorService.get('TranslationService');

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    const titleMinLength = 3;
    const titleMaxLength = 250;
    const descriptionMinLength = 30;
    const descriptionMaxLength = 5000;
    const applicationMinLength = 30;
    const applicationMaxLength = 5000;
    const additionalMinLength = 30;
    const additionalMaxLength = 5000;
    const quantityMin = 0;
    const quantityMax = 9999;
    const priceMin = 10;
    const priceMax = 10000;
    const minMedia = 1;
    const maxMedia = 5;
    const minSale = 0.1;
    const maxSale = 90;
    const paginationLength = 8;

    useEffect(() => {
        loadManufacturers();
    }, []);

    useEffect(() => {
        if (showForm === FormMode.HIDDEN) {
            clearData();
        }
    }, [showForm]);

    useEffect(() => {
        if (goods.length === 0) {
            loadGoods();
        }
    }, [goods]);

    async function loadGoods(): Promise<void> {
        try {
            const from = goods.length;
            const to = goods.length
                ? goods.length + paginationLength
                : paginationLength;

            const data = await GoodService.get({
                from,
                to,
            });

            setGoods(prev => JSON.parse(JSON.stringify([...prev, ...data])));

            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.messages.notLoaded,
                ),
            );
        }
    }

    const loadManufacturers = useCallback(async () => {
        try {
            const data = (await ManufacturersService.get()).map(
                manufacturer => ({
                    name: manufacturer.title,
                    value: manufacturer.title,
                }),
            );
            setManufacturers(data);
            setManufacturer(data[0]);
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }, []);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const trimValues = useCallback(() => {
        for (let key in formData) {
            if (key === 'price') {
                formData[key] = Number(formData[key]).toFixed(2);
            } else if (key === 'quantity') {
                formData[key] = Number(formData[key]).toFixed(0);
            } else if (key === 'media') {
                formData.media = media.filter(file => file);
            } else if (key !== 'subSubType' && key !== 'sex') {
                formData[key] = formData[key].trim();
            }
        }
    }, [formData, media]);

    const verifyData = useCallback((): boolean => {
        const {
            titleEn,
            titleUa,
            titleRu,
            descriptionEn,
            descriptionUa,
            descriptionRu,
            applicationEn,
            applicationUa,
            applicationRu,
            additionalEn,
            additionalUa,
            additionalRu,
            quantity,
            price,
            sale,
        } = formData;

        const errors = [
            {
                value: titleEn.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.en,
            },
            {
                value: titleUa.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.ua,
            },
            {
                value: titleRu.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.ru,
            },
            {
                value: descriptionEn.length,
                minLength: descriptionMinLength,
                maxLength: descriptionMaxLength,
                message: texts.messages.descriptionLength.en,
            },
            {
                value: descriptionUa.length,
                minLength: descriptionMinLength,
                maxLength: descriptionMaxLength,
                message: texts.messages.descriptionLength.ua,
            },
            {
                value: descriptionRu.length,
                minLength: descriptionMinLength,
                maxLength: descriptionMaxLength,
                message: texts.messages.descriptionLength.ru,
            },
            {
                value: applicationEn.length,
                minLength: applicationMinLength,
                maxLength: applicationMaxLength,
                message: texts.messages.applicationLength.en,
            },
            {
                value: applicationUa.length,
                minLength: applicationMinLength,
                maxLength: applicationMaxLength,
                message: texts.messages.applicationLength.ua,
            },
            {
                value: applicationRu.length,
                minLength: applicationMinLength,
                maxLength: applicationMaxLength,
                message: texts.messages.applicationLength.ru,
            },
            {
                value: additionalEn.length,
                minLength: additionalMinLength,
                maxLength: additionalMaxLength,
                message: texts.messages.additionalLength.en,
            },
            {
                value: additionalUa.length,
                minLength: additionalMinLength,
                maxLength: additionalMaxLength,
                message: texts.messages.additionalLength.ua,
            },
            {
                value: additionalRu.length,
                minLength: additionalMinLength,
                maxLength: additionalMaxLength,
                message: texts.messages.additionalLength.ru,
            },
            {
                value: Number(quantity),
                minLength: quantityMin,
                maxLength: quantityMax,
                message: texts.messages.quantityLength,
            },
            {
                value: Number(price),
                minLength: priceMin,
                maxLength: priceMax,
                message: texts.messages.priceLength,
            },
            {
                value: media.length,
                minLength: minMedia,
                maxLength: maxMedia,
                message: texts.messages.mediaLength,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                setContextNotification(prev =>
                    NotificationService.add(
                        prev,
                        NotificationType.ERROR,
                        message,
                    ),
                );
                isError = true;
            }
        }

        if (sale && (sale < minSale || sale > maxSale)) {
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.messages.sale,
                ),
            );
            isError = true;
        }

        return isError;
    }, [formData, media, texts.messages, showNotification]);

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const add = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                setIsEditingStarted(true);
                const newProduct = await GoodService.add({
                    title: {
                        en: formData.titleEn,
                        ua: formData.titleUa,
                        ru: formData.titleRu,
                    },
                    type: type.value,
                    subType: subType.value,
                    subSubType: subSubType?.value || null,
                    manufacturer: manufacturer.value,
                    description: {
                        en: formData.descriptionEn,
                        ua: formData.descriptionUa,
                        ru: formData.descriptionRu,
                    },
                    application: {
                        en: formData.applicationEn,
                        ua: formData.applicationUa,
                        ru: formData.applicationRu,
                    },
                    additional: {
                        en: formData.additionalEn,
                        ua: formData.additionalUa,
                        ru: formData.additionalRu,
                    },
                    quantity: +formData.quantity,
                    price: +formData.price,
                    sale: formData.sale
                        ? {
                              value: +formData.sale,
                              reason: selectedReason.value,
                          }
                        : undefined,
                    media,
                    sex: sex.value,
                });

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.added,
                );

                clearData();
                setGoods([...goods, newProduct]);
            } catch (error) {
                console.log('Add good error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notAdded,
                );
            } finally {
                setIsEditingStarted(false);
            }
        },
        [
            verifyData,
            formData,
            type,
            subType,
            subSubType,
            saleReasons,
            selectedReason,
            manufacturer,
            sex,
            goods,
            GoodService,
            showNotification,
            texts.messages,
        ],
    );

    const clearData = useCallback(() => {
        for (let key in formData) {
            formData[key] = '';
        }

        setType(goodTypes[0]);
        if (manufacturers?.length) {
            setManufacturer(manufacturers[0]);
        }
        setSelectedReason(saleReasons[0]);
        setMedia([]);
        setSex(sexs[0]);
    }, [formData]);

    const setUpEditData = useCallback(
        async (_id: string) => {
            try {
                const good = goods.find(goods => goods._id === _id);

                if (!good) {
                    return console.log('Error edit good');
                }

                const newData = {
                    _id,
                    titleEn: good.title.en,
                    titleUa: good.title.ua,
                    titleRu: good.title.ru,
                    type: good.type,
                    subType: good.subType,
                    subSubType: good.subSubType,
                    manufacturer: good.manufacturer,
                    descriptionEn: good.description.en,
                    descriptionUa: good.description.ua,
                    descriptionRu: good.description.ru,
                    applicationEn: good.application.en,
                    applicationUa: good.application.ua,
                    applicationRu: good.application.ru,
                    additionalEn: good.additional.en,
                    additionalUa: good.additional.ua,
                    additionalRu: good.additional.ru,
                    quantity: String(good.quantity),
                    price: String(good.price),
                    sale: String(good.sale?.value || ''),
                    sex: good.sex,
                    media: good.media,
                };

                setFormData({
                    ...newData,
                });
                setDefaultData({
                    ...newData,
                });

                const typeForSelect = goodTypes.find(
                    selectItem => selectItem.value === good.type,
                );
                if (typeForSelect) {
                    setType(typeForSelect);

                    const subTypeForSelect = getGoodSubTypes(
                        typeForSelect.value,
                    ).find(selectItem => selectItem.value === good.subType);

                    if (subTypeForSelect) {
                        setTimeout(() => {
                            setSubType(subTypeForSelect);

                            const subSubTypeForSelect = getGoodSubSubTypes(
                                typeForSelect.value,
                                subTypeForSelect.value,
                            ).find(
                                selectItem =>
                                    selectItem.value === good.subSubType,
                            );

                            if (subSubTypeForSelect) {
                                setTimeout(() => {
                                    setSubSubType(subSubTypeForSelect);
                                }, 100);
                            }
                        }, 0);
                    }
                }

                if (good.sale && good.sale?.value) {
                    const reasonForSelect = saleReasons.find(
                        selectItem => selectItem.value === good.sale?.reason,
                    );

                    if (reasonForSelect) {
                        setSelectedReason(reasonForSelect);
                    }
                }

                const manufacturerForSelect = manufacturers.find(
                    selectItem => selectItem.value === good.manufacturer,
                );
                if (manufacturerForSelect) {
                    setManufacturer(manufacturerForSelect);
                }

                const sexForSelect = sexs.find(
                    selectItem => selectItem.value === good.sex,
                );
                if (sexForSelect) {
                    setSex(sexForSelect);
                }

                setMedia(good.media || []);
                setShowForm(FormMode.EDIT);
            } catch (error) {
                console.log('Error loading good data:', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notLoaded,
                );
            }
        },
        [
            GoodService,
            goods,
            goodTypes,
            goodSubTypes,
            goodSubSubTypes,
            manufacturers,
            type,
            subType,
            subSubType,
            manufacturer,
            saleReasons,
            selectedReason,
            sexs,
            sex,
            media,
            formData,
            showNotification,
            texts.messages,
        ],
    );

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                setIsEditingStarted(true);
                await new Promise((res, rej) => {
                    setTimeout(() => {
                        res(true)
                    }, 2000);
                })
                const goodToEdit = goods.find(
                    good => good._id === formData._id,
                );
                if (!goodToEdit) {
                    throw 'Good not found';
                }

                formData.type = type.value;
                formData.subType = subType.value;
                formData.subSubType = subSubType?.value || null;
                formData.manufacturer = manufacturer.value;
                formData.title = {
                    en: formData.titleEn,
                    ua: formData.titleUa,
                    ru: formData.titleRu,
                };
                formData.description = {
                    en: formData.descriptionEn,
                    ua: formData.descriptionUa,
                    ru: formData.descriptionRu,
                };
                formData.application = {
                    en: formData.applicationEn,
                    ua: formData.applicationUa,
                    ru: formData.applicationRu,
                };
                formData.additional = {
                    en: formData.additionalEn,
                    ua: formData.additionalUa,
                    ru: formData.additionalRu,
                };
                formData.sale = {
                    value: +formData.sale,
                    reason: selectedReason.value,
                };
                formData.sex = sex.value;

                const updatedFields: { [key: string]: any } = {};

                if (!goodToEdit.sale) {
                    goodToEdit.sale = {
                        value: 0,
                        reason: SaleType.DEFAULT,
                    };
                }

                for (let key in goodToEdit) {
                    if (
                        key === 'rate' ||
                        key === 'updatedAt' ||
                        key === '__v'
                    ) {
                        continue;
                    }

                    const originalValue: any =
                        goodToEdit[key as keyof typeof goodToEdit];

                    const newValue = formData[key];
                    if (key === 'quantity' || key === 'price') {
                        if (Number(originalValue) != Number(newValue)) {
                            updatedFields[key] = Number(newValue);
                        }
                    } else if (
                        key === 'title' ||
                        key === 'description' ||
                        key === 'sale'
                    ) {
                        if (
                            !Object.keys(originalValue).every(
                                (objectKey: string) =>
                                    originalValue[objectKey] ===
                                    newValue[objectKey],
                            )
                        ) {
                            updatedFields[key] = newValue;
                        }
                    } else if (key === 'media') {
                        if (originalValue !== media) {
                            updatedFields[key] = media;
                        }
                    } else if (originalValue !== newValue) {
                        updatedFields[key] = newValue;
                    }
                }

                for (const [key, value] of Object.entries(updatedFields)) {
                    await GoodService.edit(formData._id, key, value);
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );

                clearData();
                setGoods([]);
                setShowForm(FormMode.HIDDEN);
            } catch (error) {
                console.log('Edit good error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            } finally {
                setIsEditingStarted(false);
            }
        },
        [
            verifyData,
            type,
            subType,
            subSubType,
            manufacturer,
            saleReasons,
            selectedReason,
            media,
            formData,
            sex,
            goods,
            GoodService,
            showNotification,
            texts.messages,
        ],
    );

    const remove = useCallback(
        async (_id: string) => {
            try {
                await GoodService.remove(_id);
                setGoods(prev => prev.filter(good => good._id !== _id));
                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.removed,
                );
            } catch (error) {
                console.log('Remove good error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notRemoved,
                );
            }
        },
        [GoodService, showNotification, texts.messages],
    );

    const addMedia = useCallback(
        (value: string) => {
            setMedia([...media, value]);
        },
        [media],
    );

    const removeMedia = useCallback(
        (index: number) => {
            let newList = [...media];
            newList.splice(index, 1);
            setMedia([...newList]);
        },
        [media],
    );

    return (
        <div className="goods">
            <p
                className="goods_show-hide"
                onClick={() =>
                    setShowForm(isShowForm ? FormMode.HIDDEN : FormMode.ADD)
                }>
                {isShowForm ? texts.buttons.hide : texts.buttons.show}
            </p>
            <form className={`goods_form ${isShowForm ? 'show' : ''}`}>
                <Select
                    selectedItem={type}
                    itemsList={goodTypes}
                    onChange={setType}
                />
                {subType && goodSubTypes.length ? (
                    <Select
                        selectedItem={subType}
                        itemsList={goodSubTypes}
                        onChange={setSubType}
                    />
                ) : (
                    ''
                )}
                {subSubType && goodSubSubTypes.length ? (
                    <Select
                        selectedItem={subSubType}
                        itemsList={goodSubSubTypes}
                        onChange={setSubSubType}
                    />
                ) : (
                    <div className="invisible"></div>
                )}
                {manufacturers.length ? (
                    <Select
                        selectedItem={manufacturer}
                        itemsList={manufacturers}
                        onChange={setManufacturer}
                    />
                ) : (
                    ''
                )}

                <Input
                    value={formData.titleEn}
                    onChange={(value: string) => handleChange('titleEn', value)}
                    placeholder={texts.placeholders.title.en}
                />
                <Textarea
                    placeholder={texts.placeholders.description.en}
                    value={formData.descriptionEn}
                    onChange={(value: string) =>
                        handleChange('descriptionEn', value)
                    }
                />
                <Input
                    value={formData.titleUa}
                    onChange={(value: string) => handleChange('titleUa', value)}
                    placeholder={texts.placeholders.title.ua}
                />
                <Textarea
                    placeholder={texts.placeholders.description.ua}
                    value={formData.descriptionUa}
                    onChange={(value: string) =>
                        handleChange('descriptionUa', value)
                    }
                />
                <Input
                    value={formData.titleRu}
                    onChange={(value: string) => handleChange('titleRu', value)}
                    placeholder={texts.placeholders.title.ru}
                />
                <Textarea
                    placeholder={texts.placeholders.description.ru}
                    value={formData.descriptionRu}
                    onChange={(value: string) =>
                        handleChange('descriptionRu', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.application.en}
                    value={formData.applicationEn}
                    onChange={(value: string) =>
                        handleChange('applicationEn', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.additional.en}
                    value={formData.additionalEn}
                    onChange={(value: string) =>
                        handleChange('additionalEn', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.application.ua}
                    value={formData.applicationUa}
                    onChange={(value: string) =>
                        handleChange('applicationUa', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.additional.ua}
                    value={formData.additionalUa}
                    onChange={(value: string) =>
                        handleChange('additionalUa', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.application.ru}
                    value={formData.applicationRu}
                    onChange={(value: string) =>
                        handleChange('applicationRu', value)
                    }
                />
                <Textarea
                    placeholder={texts.placeholders.additional.ru}
                    value={formData.additionalRu}
                    onChange={(value: string) =>
                        handleChange('additionalRu', value)
                    }
                />

                <Input
                    value={formData.quantity}
                    onChange={(value: string) =>
                        handleChange('quantity', value)
                    }
                    placeholder={texts.placeholders.quantity}
                />
                <Input
                    value={formData.price}
                    onChange={(value: string) => handleChange('price', value)}
                    placeholder={texts.placeholders.price}
                />
                <Input
                    value={formData.sale}
                    onChange={(value: string) => handleChange('sale', value)}
                    placeholder={texts.placeholders.sale}
                />
                <Select
                    selectedItem={selectedReason}
                    itemsList={saleReasons}
                    onChange={setSelectedReason}
                />
                <Select selectedItem={sex} itemsList={sexs} onChange={setSex} />

                {media.length ? (
                    <div className="goods_form_media">
                        {media.map((file, i) => (
                            <figure
                                className="goods_form_media_item"
                                key={`admin new media #${i}`}>
                                {file.includes('data:video/') ? (
                                    <video src={file} autoPlay muted></video>
                                ) : (
                                    <img src={`${file}`} />
                                )}
                                <div className="media_controls">
                                    <IconType.CROSS
                                        onClick={() => removeMedia(i)}
                                    />
                                </div>
                            </figure>
                        ))}
                    </div>
                ) : (
                    ''
                )}

                <div className="goods_form_buttons">
                    {media.length < maxMedia ? (
                        <InputFile
                            types={[...ImageTypes, ...VideoTypes]}
                            onChange={addMedia}
                        />
                    ) : (
                        ''
                    )}
                    <Button
                        label={
                            showForm === FormMode.ADD
                                ? texts.buttons.add
                                : texts.buttons.edit
                        }
                        disabled={isButtonDisabled}
                        type={ButtonType.OUTLINED}
                        handlerClick={showForm === FormMode.ADD ? add : edit}
                    />
                </div>
            </form>

            {goods.length ? (
                <div className="goods_list">
                    {goods.map(good => (
                        <div className="goods_list_item" key={good._id}>
                            <figure>
                                {good.media[0].includes('data:video/') ? (
                                    <video
                                        src={good.media[0]}
                                        autoPlay
                                        muted></video>
                                ) : (
                                    <img src={`${good.media[0]}`} />
                                )}
                            </figure>
                            <div className="goods_list_item_description">
                                <h4>{good.manufacturer.toUpperCase()}</h4>
                                <p>
                                    {
                                        good.title[
                                            language as keyof typeof good.title
                                        ]
                                    }
                                </p>
                            </div>

                            <div className="goods_list_item_other">
                                <h4>{good.quantity}</h4>
                                <div className="goods_list_item_other_controls">
                                    <Button
                                        type={ButtonType.OUTLINED}
                                        label={texts.buttons.edit}
                                        handlerClick={() =>
                                            good._id && setUpEditData(good._id)
                                        }
                                    />
                                    <Button
                                        type={ButtonType.OUTLINED}
                                        label={texts.buttons.remove}
                                        handlerClick={() =>
                                            good._id && remove(good._id)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    {isShowPaginationButton ? (
                        <div className="goods_list_pagination">
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadGoods}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <h4 className="goods_empty">{texts.goodsListEmpty}</h4>
            )}
        </div>
    );
}
