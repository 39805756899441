/** @format */

import { ReactComponent as SEARCH } from '../../assets/images/search.svg';
import { ReactComponent as PERSON } from '../../assets/images/person.svg';
import { ReactComponent as HEART } from '../../assets/images/heart.svg';
import { ReactComponent as CART } from '../../assets/images/cart.svg';
import { ReactComponent as BURGER } from '../../assets/images/burger.svg';
import { ReactComponent as FACEBOOK } from '../../assets/images/facebook.svg';
import { ReactComponent as INSTAGRAM } from '../../assets/images/instagram.svg';
import { ReactComponent as TELEGRAM } from '../../assets/images/telegram.svg';
import { ReactComponent as VIBER } from '../../assets/images/viber.svg';
import { ReactComponent as ARROW_LEFT } from '../../assets/images/arrowLeft.svg';
import { ReactComponent as ARROW_RIGHT } from '../../assets/images/arrowRight.svg';
import { ReactComponent as STAR } from '../../assets/images/star.svg';
import { ReactComponent as PLUS } from '../../assets/images/plus.svg';
import { ReactComponent as MINUS } from '../../assets/images/minus.svg';
import { ReactComponent as CROSS } from '../../assets/images/cross.svg';
import { ReactComponent as PRODUCTS } from '../../assets/images/products.svg';
import { ReactComponent as ORDERS } from '../../assets/images/orders.svg';
import { ReactComponent as DOOR } from '../../assets/images/door.svg';
import { ReactComponent as COG } from '../../assets/images/cog.svg';
import { ReactComponent as BOOK } from '../../assets/images/book.svg';
import { ReactComponent as IMAGE } from '../../assets/images/image.svg';
import { ReactComponent as ARTICLES } from '../../assets/images/articles.svg';
import { ReactComponent as SALE } from '../../assets/images/sale.svg';
import { ReactComponent as MANUFACTURER } from '../../assets/images/manufacturer.svg';

const ImageType: { [key: string]: string } = {
    LOGO: require('../../assets/images/logo.png'),
    MAKEUP: require('../../assets/images/home/makeup.webp'),
    HAIR: require('../../assets/images/home/hair.webp'),
    CARE: require('../../assets/images/home/care.webp'),
    BLOG: require('../../assets/images/home/blog.webp'),
};

const IconType: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    SEARCH,
    PERSON,
    HEART,
    CART,
    BURGER,
    FACEBOOK,
    INSTAGRAM,
    TELEGRAM,
    VIBER,
    ARROW_LEFT,
    ARROW_RIGHT,
    STAR,
    PLUS,
    MINUS,
    CROSS,
    PRODUCTS,
    ORDERS,
    DOOR,
    COG,
    BOOK,
    IMAGE,
    ARTICLES,
    SALE,
    MANUFACTURER,
};

const ImageTypes = ['.jpg', '.png', '.webp'];
const VideoTypes = ['.mkv', '.webm'];

export { ImageType, IconType, ImageTypes, VideoTypes };
