import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './Manufacturers.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Input from '@app/components/common/input/Input';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { Manufacturer } from '@app/types/manufacturer.type';

enum FormMode {
    HIDDEN,
    ADD,
}

export default function Manufacturers() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [showForm, setShowForm] = useState(FormMode.HIDDEN);
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        title: '',
    });
    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);

    const texts = useMemo(
        () => contextTranslation.PersonalArea.manufacturers,
        [contextTranslation],
    );
    const isShowForm = useMemo(() => showForm === FormMode.ADD, [showForm]);
    const isButtonDisabled = useMemo(() => {
        return (
            showForm === FormMode.ADD &&
            Object.keys(formData).some(key => formData[key] === '')
        );
    }, [showForm, formData]);

    const NotificationService = injectorService.get('NotificationService');
    const ManufacturersService = injectorService.get('ManufacturersService');

    const titleMinLength = 3;
    const titleMaxLength = 30;

    useEffect(() => {
        if (showForm === FormMode.HIDDEN) {
            clearFields();
        }
    }, [showForm]);

    useEffect(() => {
        if (manufacturers.length === 0) {
            loadManufacturers();
        }
    }, [manufacturers]);

    const loadManufacturers = useCallback(async () => {
        try {
            const data = await ManufacturersService.get();

            setManufacturers(data);
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }, [manufacturers]);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }
    }, [formData]);

    const verifyData = useCallback((): boolean => {
        const { title } = formData;

        const errors = [
            {
                value: title.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                showNotification(NotificationType.ERROR, message);
                isError = true;
            }
        }

        return isError;
    }, [formData, texts.messages]);

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const add = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const { title } = formData;
                const newManufacturer = await ManufacturersService.add({
                    title,
                });

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.added,
                );

                clearFields();
                setManufacturers([...manufacturers, newManufacturer]);
            } catch (error) {
                console.log('Add promocode error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notAdded,
                );
            }
        },
        [formData, texts.messages],
    );

    const clearFields = useCallback(() => {
        for (let key in formData) {
            formData[key] = '';
        }
    }, [formData]);

    const remove = useCallback(
        async (_id: string) => {
            try {
                await ManufacturersService.delete(_id);
                setManufacturers(prev =>
                    prev.filter(promocode => promocode._id !== _id),
                );
                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.removed,
                );
            } catch (error) {
                console.log('Remove promocode error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notRemoved,
                );
            }
        },
        [ManufacturersService, showNotification, texts.messages],
    );

    const formFields = [
        { key: 'title', placeholder: texts.placeholders.title },
    ];

    return (
        <div className="manufacturers">
            <p
                className="manufacturers_show-hide"
                onClick={() =>
                    setShowForm(isShowForm ? FormMode.HIDDEN : FormMode.ADD)
                }>
                {isShowForm ? texts.buttons.hide : texts.buttons.show}
            </p>
            <form className={`manufacturers_form ${isShowForm ? 'show' : ''}`}>
                {formFields.map(field => (
                    <Input
                        key={`admin field ${field.key}`}
                        value={formData[field.key]}
                        onChange={(value: string) =>
                            handleChange(field.key, value)
                        }
                        placeholder={field.placeholder}
                    />
                ))}

                <Button
                    label={
                        showForm === FormMode.ADD
                            ? texts.buttons.add
                            : texts.buttons.edit
                    }
                    disabled={isButtonDisabled}
                    type={ButtonType.OUTLINED}
                    handlerClick={add}
                />
            </form>

            {manufacturers.length ? (
                <div className="manufacturers_list">
                    {manufacturers.map(promocode => (
                        <div
                            className="manufacturers_list_item"
                            key={promocode._id}>
                            <h4>{promocode.title}</h4>

                            <div className="manufacturers_list_item_controls">
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.remove}
                                    handlerClick={() =>
                                        promocode._id && remove(promocode._id)
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <h4 className="manufacturers_empty">{texts.listEmpty}</h4>
            )}
        </div>
    );
}
