/** @format */

import {
    iPromocodesService,
    iWebSocketService,
} from '@app/types/injector.type';
import injectorService from './injector.service';
import { Promocode } from '@app/types/promocode.type';

const urls = Object.freeze({
    GET: 'get',
    SET: 'set',
    ADD: 'add',
    DELETE: 'delete',
    APPLY: 'apply',
});

export default class PromocodesService implements iPromocodesService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'promocodes';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async add(newPromocode: Promocode): Promise<Promocode> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: newPromocode,
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `PromocodesService add error: ${error}`;
        }
    }

    async get({
        from,
        to,
        title,
        userEmail,
    }: {
        from?: number;
        to?: number;
        title?: string;
        userEmail?: string;
    }): Promise<Promocode[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: { from, to, title, userEmail },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `PromocodesService get error: ${error}`;
        }
    }

    public async set(_id: string, key: string, value: any): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.SET}`,
                data: {
                    _id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `PromocodesService set error: ${error}`;
        }
    }

    public async remove(_id: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.DELETE}`,
                data: { _id },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `PromocodesService remove error: ${error}`;
        }
    }

    public async apply(_id: string, email: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.APPLY}`,
                data: { _id, email },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `PromocodesService apply error: ${error}`;
        }
    }
}
