import './Loader.scss';

export default function Loader() {
    return (
        <div className="loader">
            <div className="loader_spinner">
                {new Array(4).fill(0).map((_, i) => (
                    <div className="loader_spinner_item" key={i}></div>
                ))}
            </div>
        </div>
    );
}
