import { useNavigate } from 'react-router-dom';
import './BackButton.scss';
import { useContext } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import { IconType } from '@app/types/image.type';

export default function BackButton({
    handlerClick,
}: {
    handlerClick?: Function;
}) {
    const { contextTranslation } = useContext(TranslationContext);

    const navigate = useNavigate();

    function goBack(): void {
        if (!handlerClick) {
            return navigate(-1);
        }

        handlerClick();
    }

    return (
        <p className="backButton" onClick={goBack}>
            <IconType.ARROW_LEFT /> {contextTranslation.Buttons.comeBack}
        </p>
    );
}
