/** @format */

import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import injectorService from './services/injector.service';
import { TranslationService } from './services/translation.service';
import Router from './services/navigation.service';
import { useContext, useLayoutEffect } from 'react';
import { LoaderContext } from './contexts/loaderContext';
import { AppInfoContext } from './contexts/appInfoContext';
import AppInfoService from './services/app-info.service';
import UserService from './services/user.service';
import WishListService from './services/wish-list.service';
import { WishListContext } from './contexts/wishListContext';
import CartListService from './services/cart-list.service';
import { CartListContext } from './contexts/cartListContext';
import { WebSocketService } from './services/websocket.service';
import Loader from './components/common/loader/Loader';
import Notifications from './components/common/notifications/Notifications';
import { NotificationService } from './services/notification.service';
import GoodService from './services/good.service';
import CoursesService from './services/courses.service';
import { UserContext } from './contexts/userContext';
import SeoService from './services/seo.service';
import SearchService from './services/search.service';
import OrderService from './services/order.service';
import ArticleService from './services/article.service';
import PromocodesService from './services/promocodes.service';
import ManufacturersService from './services/manufacturers.service';
import { ManufacturersContext } from './contexts/manufacturersContext';

injectorService.register('TranslationService', TranslationService);
injectorService.register('WebSocketService', WebSocketService);
injectorService.register('AppInfoService', AppInfoService);
injectorService.register('CoursesService', CoursesService);
injectorService.register('UserService', UserService);
injectorService.register('GoodService', GoodService);
injectorService.register('WishListService', WishListService);
injectorService.register('CartListService', CartListService);
injectorService.register('NotificationService', NotificationService);
injectorService.register('SeoService', SeoService);
injectorService.register('SearchService', SearchService);
injectorService.register('OrderService', OrderService);
injectorService.register('ArticleService', ArticleService);
injectorService.register('PromocodesService', PromocodesService);
injectorService.register('ManufacturersService', ManufacturersService);

export default function App() {
    const { contextLoader, setContextLoader } = useContext(LoaderContext);
    const { setContextAppInfo } = useContext(AppInfoContext);
    const { setContextManufacturers } = useContext(ManufacturersContext);
    const { setContextUser } = useContext(UserContext);
    const { setContextWishList } = useContext(WishListContext);
    const { setContextCartList } = useContext(CartListContext);

    const AppInfoService = injectorService.get('AppInfoService');
    const UserService = injectorService.get('UserService');
    const GoodService = injectorService.get('GoodService');
    const WishListService = injectorService.get('WishListService');
    const CartListService = injectorService.get('CartListService');

    const timeoutTime = 2;

    useLayoutEffect(() => {
        init();
    }, []);

    async function init(): Promise<void> {
        await getAppInfo();

        checkToken();

        getBrands();

        getWishList();
        getCartList();
    }

    async function getAppInfo(): Promise<void> {
        try {
            setContextLoader({ show: true });

            const info = await AppInfoService.get();

            setContextAppInfo(info);

            setContextLoader({ show: false });
        } catch (error) {
            console.log('getAppInfo error: ', error);

            setTimeout(() => {
                getAppInfo();
            }, timeoutTime * 1000);
        }
    }

    async function checkToken(): Promise<void> {
        try {
            const user = await UserService.checkToken();

            setContextUser(user);
        } catch (error) {}
    }

    async function getBrands(): Promise<void> {
        try {
            const brands = await GoodService.getBrands();

            setContextManufacturers(brands);
        } catch (error) {}
    }

    function getWishList(): void {
        filterWishList(WishListService.getData());
    }

    function getCartList(): void {
        filterCartList(CartListService.getData());
    }

    async function filterWishList(list: string[]): Promise<void> {
        try {
            if (!list.length) {
                return;
            }

            const data = (
                await GoodService.get({
                    _ids: [...list],
                    type: 'checkAvailability',
                })
            ).reduce((acc: string[], curr) => {
                if (curr._id) {
                    acc.push(curr._id);
                }
                return acc;
            }, []);

            setContextWishList([...data]);
            WishListService.setData([...data]);
        } catch (error) {
            console.error('filterWishList exception: ', error);
        }
    }

    async function filterCartList(list: {
        [key: string]: number;
    }): Promise<void> {
        try {
            if (!Object.keys(list).length) {
                return;
            }

            const data = await GoodService.get({
                _ids: [...Object.keys(list)],
                type: 'checkAvailability',
            });

            const newList: { [key: string]: number } = {};
            data.forEach(product => {
                if (product._id && list[product._id]) {
                    newList[product._id] = list[product._id];
                }
            });

            setContextCartList({ ...newList });
            CartListService.setData({ ...newList });
        } catch (error) {
            console.error('filterCartList exception: ', error);
        }
    }

    return (
        <>
            {contextLoader.show ? <Loader /> : ''}

            <div className="app">
                <Router />
            </div>

            <Notifications />
        </>
    );
}
