import { AuthScreenType } from '@app/types/screen.type';
import './SignIn.scss';
import Input from '@app/components/common/input/Input';
import { useContext, useMemo, useState } from 'react';
import Button from '@app/components/common/button/Button';
import { TranslationContext } from '@app/contexts/translationContext';
import injectorService from '@app/services/injector.service';
import { NotificationContext } from '@app/contexts/notificationContext';
import { NotificationType } from '@app/types/notification.type';
import { User, UserRole } from '@app/types/user.type';
import { UserContext } from '@app/contexts/userContext';

export default function SignIn({ changeScreen }: { changeScreen: Function }) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextNotification, setContextNotification } =
        useContext(NotificationContext);
    const { setContextUser } = useContext(UserContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const texts = useMemo(() => contextTranslation.Auth, [contextTranslation]);

    const NotificationService = injectorService.get('NotificationService');
    const UserService = injectorService.get('UserService');

    async function login(event: any): Promise<void> {
        try {
            event.preventDefault();

            const user = await sendData();
            setContextUser(user);

            if (user.role < UserRole.DEFAULT) {
                goConfirm();
            }
        } catch (error) {
            console.log('Login error: ', error);
            setContextNotification(
                NotificationService.add(
                    contextNotification,
                    NotificationType.ERROR,
                    texts.error.login,
                ),
            );
        }
    }

    async function sendData(): Promise<User> {
        return await UserService.login(email, password);
    }

    function goConfirm(): void {
        changeScreen(AuthScreenType.CONFIRM);
    }

    function goRestore(): void {
        changeScreen(AuthScreenType.RESTORE);
    }

    function goRegister(): void {
        changeScreen(AuthScreenType.REGISTER);
    }

    return (
        <form className="signIn">
            <div className="signIn_inputs">
                <Input
                    value={email}
                    onChange={setEmail}
                    placeholder={texts.email}
                />
                <Input
                    type="password"
                    value={password}
                    onChange={setPassword}
                    placeholder={texts.password}
                />

                <div className="row">
                    <p className="link" onClick={goRestore}>
                        {texts.goRestore}
                    </p>
                    <p className="link" onClick={goRegister}>
                        {texts.goRegister}
                    </p>
                </div>
            </div>

            <Button label={texts.buttons.login} handlerClick={login} />
        </form>
    );
}
