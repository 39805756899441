import './Catalogue.scss';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Good } from '@app/types/good.type';
import GoodsList from '@app/components/common/goods-list/GoodsList';
import Filter from '@app/components/common/filter/Filter';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { TranslationContext } from '@app/contexts/translationContext';
import { LoaderContext } from '@app/contexts/loaderContext';
import { AppInfoContext } from '@app/contexts/appInfoContext';

export default function Catalogue() {
    const { contextTranslation } = useContext(TranslationContext);
    // const { setContextLoader } = useContext(LoaderContext);
    // const { contextAppInfo } = useContext(AppInfoContext);

    // const [loadingStarted, setLoadingStarted] = useState(false);
    const [filteredGoods, setFilteredGoods] = useState<Good[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(false);

    const filterRef = useRef<any>(null);

    // useLayoutEffect(() => {
    //     const isShow = !contextAppInfo.projectTitle || loadingStarted;

    //     setContextLoader({ show: isShow });
    // }, [loadingStarted, contextAppInfo]);

    async function loadGoods(): Promise<void> {
        if (filterRef.current) {
            filterRef.current.loadData();
        }
    }

    return (
        <div className="catalogue container">
            <Filter
                ref={filterRef}
                goods={filteredGoods}
                setFilteredGoods={setFilteredGoods}
                setIsShowPaginationButton={setIsShowPaginationButton}
            />

            {filteredGoods.length ? (
                <GoodsList list={filteredGoods} />
            ) : (
                <h3 className="catalogue_empty">
                    {contextTranslation.notFound}
                </h3>
            )}

            {isShowPaginationButton ? (
                <div className="catalogue_pagination">
                    <Button
                        type={ButtonType.OUTLINED}
                        label={contextTranslation.Buttons.pagination}
                        handlerClick={loadGoods}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
