/** @format */

import { InputProps } from '@app/types/input.type';
import './Input.scss';
import { useLayoutEffect } from 'react';

export default function Input({
    customRef,
    name,
    isReadOnly,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    type = 'text',
}: InputProps) {
    const phoneCode = '+380';
    const phoneLength = 9;

    useLayoutEffect(() => {
        if (type !== 'phone') {
            return;
        }

        if (!value?.startsWith(phoneCode)) {
            const updatedValue = phoneCode + value;
            handlerChange(sanitizeValue(updatedValue));
        }
    }, [value]);

    function handlerChange(newValue: any): void {
        if (type === 'phone') {
            newValue = sanitizeValue(newValue);

            if (
                (value === phoneCode && !newValue.startsWith(phoneCode)) ||
                newValue.slice(4).length > phoneLength
            ) {
                return;
            }
        }

        if (onChange) {
            onChange(newValue);
        }
    }

    function sanitizeValue(input: string): string {
        let sanitized = input.replace(/[^0-9+]/g, '').replace(/\+(?=.*)/g, '');
        if (!sanitized.startsWith('+')) {
            sanitized = `+${sanitized}`;
        }
        return sanitized;
    }

    function handlerFocus(): void {
        if (onFocus) {
            onFocus();
        }
    }

    function handlerBlur(): void {
        if (onBlur) {
            onBlur();
        }
    }

    return (
        <input
            ref={customRef}
            name={name}
            readOnly={isReadOnly}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={({ target }) => handlerChange(target.value)}
            onFocus={handlerFocus}
            onBlur={handlerBlur}
            className="input"
        />
    );
}
