/** @format */

import React, { useState } from 'react';

interface CartListContextProviderProps {
    children: React.ReactNode;
}

export interface CartListContextType {
    contextCartList: {[key: string]: number};
    setContextCartList: React.Dispatch<React.SetStateAction<{[key: string]: number}>>;
}

export const CartListContext = React.createContext<CartListContextType>({
    contextCartList: {},
    setContextCartList: () => {},
});

export default function CartListContextProvider({
    children,
}: CartListContextProviderProps) {
    const [contextCartList, setContextCartList] = useState<{[key: string]: number}>({});

    return (
        <CartListContext.Provider
            value={{
                contextCartList,
                setContextCartList,
            }}>
            {children}
        </CartListContext.Provider>
    );
}
