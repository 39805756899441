/** @format */

export default async function sendRequest<T = Record<string, unknown>>(
    url: string,
    params: string,
    data?: {},
): Promise<T> {
    try {
        const fetchUrl = `${url}?${params}`;
        const fetchOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (data) {
            fetchOptions.body = JSON.stringify(data);
        }

        const response = await fetch(fetchUrl, fetchOptions);

        if (!response.ok) {
            throw new Error('HTTP Error: ' + response.status);
        }

        if (response.status === 204) {
            throw 'Response body is empty'
        }

        return await response.json();
    } catch (error: any) {
        throw new Error(`Request error: ${error}`);
    }
}
