import { iWebSocketService } from '@app/types/injector.type';
import { Environment } from '../../environments/environment';

export class WebSocketService implements iWebSocketService {
    private socket: WebSocket | null = null;
    private url: string;
    private reconnectDelay: number = 3000;
    private pendingRequests: Map<
        string,
        { resolve: (data: any) => void; reject: (error: any) => void }
    > = new Map();
    private messageQueue: any[] = [];
    private isConnected: boolean = false;
    private requestIdCounter: number = 0;

    constructor() {
        this.url = `${Environment.admin.protocol}://${Environment.admin.host}${Environment.admin.port}`;
        this.connect();
    }

    private connect() {
        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
            console.log('WebSocket connection established');
            this.isConnected = true;

            this.processMessageQueue();
        };

        this.socket.onmessage = event => {
            try {
                const response = JSON.parse(event.data);
                const { requestId, success, data } = response;

                if (requestId && this.pendingRequests.has(requestId)) {
                    const { resolve } = this.pendingRequests.get(requestId)!;
                    resolve({ success, data });
                    this.pendingRequests.delete(requestId);
                } else {
                    console.warn('Unmatched response:', response);
                }
            } catch (error) {
                console.error('Error processing message:', error);
            }
        };

        this.socket.onclose = () => {
            console.warn('WebSocket connection closed. Reconnecting...');
            this.isConnected = false;
            setTimeout(() => this.connect(), this.reconnectDelay);
        };

        this.socket.onerror = error => {
            console.error('WebSocket error:', error);
            this.isConnected = false;
            this.socket?.close();
        };
    }

    public sendMessage<T>(message: any): Promise<T> {
        return new Promise((resolve, reject) => {
            const requestId = this.generateRequestId();
            const messageWithId = { ...message, requestId };

            this.pendingRequests.set(requestId, { resolve, reject });

            if (this.isConnected && this.socket?.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify(messageWithId));
            } else {
                console.warn('WebSocket is not connected. Message added to queue.');
                this.messageQueue.push({ message: messageWithId, reject });
            }
        });
    }

    private processMessageQueue() {
        while (this.messageQueue.length > 0) {
            const { message, reject } = this.messageQueue.shift()!;
            if (this.isConnected && this.socket?.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify(message));
            } else {
                console.warn('Failed to send message from queue. WebSocket not ready.');
                reject(new Error('WebSocket is not connected'));
            }
        }
    }

    private generateRequestId(): string {
        return `req_${Date.now()}_${this.requestIdCounter++}`;
    }
}
