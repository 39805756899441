/** @format */

import { iArticleService, iWebSocketService } from '@app/types/injector.type';
import injectorService from './injector.service';
import { TranslatableVariable } from '@app/types/language.type';
import { Article } from '@app/types/article.type';

const urls = Object.freeze({
    ADD: 'add',
    GET: 'get',
    SET: 'set',
    DELETE: 'delete',
});

export default class ArticleService implements iArticleService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'article';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async add({
        title,
        content,
    }: {
        title: TranslatableVariable;
        content: TranslatableVariable;
    }): Promise<Article> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: {
                    title,
                    content,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `ArticleService add error: ${error}`;
        }
    }

    public async get({
        from,
        to,
        ids,
    }: {
        from?: number;
        to?: number;
        ids?: string[];
    }): Promise<Article[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: {
                    from,
                    to,
                    ids,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `ArticleService get error: ${error}`;
        }
    }

    public async update(_id: string, key: string, value: any): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.SET}`,
                data: {
                    _id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw data;
            }
        } catch (error) {
            throw `ArticleService set error: ${error}`;
        }
    }

    public async remove(_id: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.DELETE}`,
                data: {
                    _id,
                },
            });

            if (!success) {
                throw data;
            }
        } catch (error) {
            throw `ArticleService delete error: ${error}`;
        }
    }
}
