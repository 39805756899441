import './Auth.scss';
import { useContext, useLayoutEffect, useState } from 'react';
import SignIn from './components/sign-in/SignIn';
import SignUp from './components/sign-up/SignUp';
import Restore from './components/restore/Restore';
import Confirm from './components/confirm/Confirm';
import { AuthScreenType, ScreenPath } from '@app/types/screen.type';
import injectorService from '@app/services/injector.service';
import { UserContext } from '@app/contexts/userContext';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '@app/types/user.type';

enum DirectionType {
    LEFT = 'left',
    RIGHT = 'right',
}

export default function Auth() {
    const { contextUser, setContextUser } = useContext(UserContext);

    const [state, setState] = useState({
        activeScreen: AuthScreenType.DEFAULT,
        prevScreen: null as AuthScreenType | null,
        direction: '',
    });
    const { activeScreen, prevScreen, direction } = state;

    const navigate = useNavigate();

    const UserService = injectorService.get('UserService');

    useLayoutEffect(() => {
        checkToken();
    }, [contextUser]);

    async function checkToken(): Promise<void> {
        try {
            const user = await UserService.checkToken();

            if (user.role < UserRole.DEFAULT) {
                throw "Account wasn't verified";
            }

            setContextUser(user);

            navigate(`/${ScreenPath.PERSONAL_AREA}`, { replace: true });
            
        } catch (error) {
            console.log("Token isn't valid: ", error);
        }
    }

    const changeScreen = (screen: AuthScreenType) => {
        setState(prevState => ({
            prevScreen: prevState.activeScreen,
            activeScreen: screen,
            direction:
                screen > prevState.activeScreen
                    ? DirectionType.LEFT
                    : DirectionType.RIGHT,
        }));
    };

    const renderScreen = (screen: AuthScreenType) => {
        switch (screen) {
            case AuthScreenType.REGISTER:
                return <SignUp changeScreen={changeScreen} />;
            case AuthScreenType.RESTORE:
                return <Restore changeScreen={changeScreen} />;
            case AuthScreenType.CONFIRM:
                return <Confirm changeScreen={changeScreen} />;
            default:
                return <SignIn changeScreen={changeScreen} />;
        }
    };

    return (
        <div className="auth">
            <div className="auth_screen">
                {prevScreen !== null && (
                    <div
                        className={`auth_slide auth_slide--exit-${direction}`}
                        key={`prev-${prevScreen}`}>
                        {renderScreen(prevScreen)}
                    </div>
                )}
                <div
                    className={`auth_slide auth_slide--enter-${direction}`}
                    key={`active-${activeScreen}`}>
                    {renderScreen(activeScreen)}
                </div>
            </div>
        </div>
    );
}
