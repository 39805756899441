import { useContext, useMemo } from 'react';
import Button from '../button/Button';
import Rating from '../rating/Rating';
import './Card.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { ButtonType } from '@app/types/button.type';
import { IconType } from '@app/types/image.type';
import { WishListContext } from '@app/contexts/wishListContext';
import injectorService from '@app/services/injector.service';
import { CartListContext } from '@app/contexts/cartListContext';
import { Link } from 'react-router-dom';
import { Good } from '@app/types/good.type';
import { ScreenPath } from '@app/types/screen.type';
import { LanguageType } from '@app/types/language.type';

export default function Card({ data }: { data: Good }) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextWishList, setContextWishList } = useContext(WishListContext);
    const { contextCartList, setContextCartList } = useContext(CartListContext);

    const isInWishList = useMemo(
        () => data?._id && contextWishList.includes(data._id),
        [contextWishList],
    );
    const isInCartList = useMemo(
        () => data?._id && contextCartList[data._id],
        [contextCartList],
    );

    const TranslationService = injectorService.get('TranslationService');
    const WishListService = injectorService.get('WishListService');
    const CartListService = injectorService.get('CartListService');

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    function updateWish(): void {
        const isNew = data?._id && !contextWishList.includes(data._id);
        const newList = isNew
            ? addToWish(contextWishList)
            : removeFromWish(contextWishList);

        setContextWishList([...newList]);
        WishListService.setData([...newList]);
    }

    function updateCart(): void {
        const isNew = data?._id && !contextCartList[data._id];
        const newList = isNew
            ? addToCart(contextCartList)
            : removeFromCart(contextCartList);

        setContextCartList({ ...newList });
        CartListService.setData({ ...newList });
    }

    function addToWish(context: string[]): string[] {
        if (!data._id) {
            return context;
        }
        return [...context, data._id];
    }

    function removeFromWish(context: string[]): string[] {
        if (!data._id) {
            return context;
        }
        return [...context].filter(item => item !== data._id);
    }

    function addToCart(context: { [key: string]: number }): {
        [key: string]: number;
    } {
        if (!data._id) {
            return context;
        }
        return {
            ...context,
            [data._id]: 1,
        };
    }

    function removeFromCart(context: { [key: string]: number }): {
        [key: string]: number;
    } {
        if (!data._id) {
            return context;
        }
        const newContext = { ...context };
        delete newContext[data._id];

        return newContext;
    }

    return (
        <div className={`card ${data.quantity === 0 ? 'ended' : ''}`}>
            <Link to={`/${ScreenPath.CATALOGUE}/${data?.type}/${data?._id}`}>
                {data.sale?.value ? <div className="sale">SALE</div> : ''}
                <figure>
                    <img src={data?.media[0]} alt={data?.title.ua} />
                    <span
                        className={`forward ${isInWishList ? 'filled' : ''}`}
                        onClick={event => {
                            event.preventDefault();
                            updateWish();
                        }}>
                        <IconType.HEART />
                    </span>
                </figure>
                <h4>{data?.manufacturer.toUpperCase()}</h4>
                <p>{data?.title[language as keyof typeof data.title]}</p>
                <h4 className="price">
                    UAH{' '}
                    {data.sale?.value ? (
                        <>
                            {(
                                data?.price -
                                (data?.price * data.sale?.value) / 100
                            ).toFixed(2)}{' '}
                            <sup>
                                <s>{data?.price.toFixed(2)}</s>
                            </sup>
                        </>
                    ) : (
                        data?.price.toFixed(2)
                    )}
                </h4>
            </Link>

            <div className="card_bottom">
                <Rating _id={data?._id || ''} rate={data?.rate} />

                <Button
                    disabled={data.quantity === 0}
                    label={
                        isInCartList
                            ? contextTranslation.Buttons.removeFromCart
                            : contextTranslation.Buttons.addToCart
                    }
                    type={ButtonType.OUTLINED}
                    handlerClick={() => updateCart()}
                />
            </div>
        </div>
    );
}
