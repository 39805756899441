import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';
import { useContext, useMemo } from 'react';
import { ScreenPath } from '@app/types/screen.type';
import { UserContext } from '@app/contexts/userContext';
import { TranslationContext } from '@app/contexts/translationContext';
import { AppInfoContext } from '@app/contexts/appInfoContext';
import { UserRole } from '@app/types/user.type';
import SocialMedia from '../social-media/SocialMedia';

export default function Footer() {
    const { contextUser } = useContext(UserContext);
    const { contextTranslation } = useContext(TranslationContext);
    const { contextAppInfo } = useContext(AppInfoContext);

    const location = useLocation();

    const isShowFooter = useMemo(
        () =>
            !(
                contextUser.role > UserRole.DEFAULT &&
                location.pathname.includes(ScreenPath.PERSONAL_AREA)
            ),
        [contextUser, location],
    );

    const texts = useMemo(
        () => contextTranslation.Footer,
        [contextTranslation],
    );

    function formatPhoneNumber(phone: string): string {
        const match = phone.match(/^\+(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (!match) {
            console.warn('Invalid phone number format');
            return '';
        }

        const [, countryCode, areaCode, firstPart, secondPart, thirdPart] =
            match;
        return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`;
    }

    return (
        <>
            {isShowFooter ? (
                <footer>
                    <div className="container">
                        <div className="column">
                            <div className="shop-name">
                                <h3 className="column_title">
                                    {contextAppInfo.projectTitle}
                                </h3>
                                <h4>{contextAppInfo.projectSubTitle}</h4>
                            </div>
                            {contextAppInfo.phone ? (
                                <a href={`tel:${contextAppInfo.phone}`}>
                                    <h4>
                                        {formatPhoneNumber(
                                            contextAppInfo.phone,
                                        )}
                                    </h4>
                                </a>
                            ) : (
                                ''
                            )}

                            {contextAppInfo.email ? (
                                <a href={`mailto:${contextAppInfo.email}`}>
                                    <h4>{contextAppInfo.email}</h4>
                                </a>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="column">
                            <h4 className="column_title">
                                {texts.tabs['about-us']}
                            </h4>

                            <div className="column column_tabs">
                                {/* <Link to={`/${ScreenPath.ABOUT_STORE}`}>
                                    {texts.tabs['about-shop']}
                                </Link> */}
                                <Link to={`/${ScreenPath.DELIVERY}`}>
                                    {texts.tabs.delivery}
                                </Link>
                                {/* <Link to={`/${ScreenPath.PAYMENT}`}>
                                    {texts.tabs.payment}
                                </Link> */}
                                {/* <Link to={`/${ScreenPath.REFUND}`}>
                                    {texts.tabs.refund}
                                </Link> */}
                                <Link to={`/${ScreenPath.AGREEMENT}`}>
                                    {texts.agreement}
                                </Link>
                                <Link
                                    to={`/${ScreenPath.PRIVACY}`}
                                    className="link">
                                    {texts.privacy}
                                </Link>
                            </div>
                        </div>
                        <div className="column">
                            <h4 className="column_title">
                                {texts.tabs.catalogue}
                            </h4>

                            <div className="column column_tabs">
                                <Link to={`/${ScreenPath.CATALOGUE}/makeup`}>
                                    {contextTranslation.GoodTypes.makeup.title}
                                </Link>
                                <Link to={`/${ScreenPath.CATALOGUE}/hair`}>
                                    {contextTranslation.GoodTypes.hair.title}
                                </Link>
                                <Link to={`/${ScreenPath.CATALOGUE}/care`}>
                                    {contextTranslation.GoodTypes.care.title}
                                </Link>
                                <Link
                                    to={`/${ScreenPath.CATALOGUE}/accessories`}>
                                    {
                                        contextTranslation.GoodTypes.accessories
                                            .title
                                    }
                                </Link>
                                <Link to={`/${ScreenPath.CATALOGUE}/presents`}>
                                    {
                                        contextTranslation.GoodTypes.presents
                                            .title
                                    }
                                </Link>
                            </div>
                        </div>

                        <div className="mobile">
                            <SocialMedia />
                        </div>
                    </div>
                </footer>
            ) : (
                ''
            )}
        </>
    );
}
