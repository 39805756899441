/** @format */

import React, { useState } from 'react';
import { InitialUserData, User } from '../types/user.type';

interface UserContextType {
    contextUser: User;
    setContextUser: React.Dispatch<React.SetStateAction<User>>;
}

interface UserContextProviderProps {
    children: React.ReactNode;
}

export const UserContext = React.createContext<UserContextType>({
    contextUser: InitialUserData,
    setContextUser: () => {},
});

export default function UserContextProvider({
    children,
}: UserContextProviderProps) {
    const [contextUser, setContextUser] = useState<User>(InitialUserData);

    return (
        <UserContext.Provider
            value={{
                contextUser,
                setContextUser,
            }}>
            {children}
        </UserContext.Provider>
    );
}
