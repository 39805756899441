import { AuthScreenType } from '@app/types/screen.type';
import './Restore.scss';
import { useContext, useMemo, useState } from 'react';
import Input from '@app/components/common/input/Input';
import { TranslationContext } from '@app/contexts/translationContext';
import Button from '@app/components/common/button/Button';
import BackButton from '@app/components/common/back-button/BackButton';
import injectorService from '@app/services/injector.service';

export default function Restore({ changeScreen }: { changeScreen: Function }) {
    const { contextTranslation } = useContext(TranslationContext);

    const [email, setEmail] = useState('');

    const texts = useMemo(() => contextTranslation.Auth, [contextTranslation]);

    const UserService = injectorService.get('UserService');

    async function restore(): Promise<void> {
        try {
            await UserService.restore(email);

            goConfirm();
        } catch (error) {
            
        }
    }

    function goLogin(): void {
        changeScreen(AuthScreenType.DEFAULT);
    }

    function goConfirm(): void {
        changeScreen(AuthScreenType.CONFIRM);
    }

    return (
        <div className="restore">
            <div className="signIn_inputs">
                <Input
                    value={email}
                    onChange={setEmail}
                    placeholder={texts.email}
                />

                <BackButton handlerClick={goLogin} />
            </div>

            <Button label={texts.buttons.restore} handlerClick={restore} />
        </div>
    );
}
