/** @format */

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import Header from '@app/components/common/header/Header';
import Home from '@app/components/pages/home/Home';
import Catalogue from '@app/components/pages/catalogue/Catalogue';
import PersonalArea from '@app/components/pages/personal-area/PersonalArea';
import Cart from '@app/components/pages/cart/Cart';
import Privacy from '@app/components/pages/privacy/Privacy';
import Footer from '@app/components/common/footer/Footer';
import AboutStore from '@app/components/pages/about-store/AboutStore';
import Auth from '@app/components/pages/auth/Auth';
import CardPage from '@app/components/pages/card-page/CardPage';
import Order from '@app/components/pages/order/Order';
import WishList from '@app/components/pages/wish-list/WishList';
import Delivery from '@app/components/pages/delivery/Delivery';
import Blog from '@app/components/pages/blog/Blog';
import Article from '@app/components/pages/article/Article';
import Agreement from '@app/components/pages/agreement/Agreement';

export default function Router() {
    return (
        <BrowserRouter>
            <Header />

            <main>
                <Routes>
                    <Route path={`/${ScreenPath.HOME}`} element={<Home />} />

                    <Route
                        path={`/${ScreenPath.CATALOGUE}/manufacturer/:id`}
                        element={<Catalogue />}
                    />

                    <Route
                        path={`/${ScreenPath.CATALOGUE}/:id/:id`}
                        element={<CardPage />}
                    />

                    <Route
                        path={`/${ScreenPath.CATALOGUE}/:id?`}
                        element={<Catalogue />}
                    />

                    <Route
                        path={`/${ScreenPath.WISH_LIST}`}
                        element={<WishList />}
                    />

                    <Route path={`/${ScreenPath.CART}`} element={<Cart />} />

                    <Route path={`/${ScreenPath.ORDER}`} element={<Order />} />

                    <Route
                        path={`/${ScreenPath.ABOUT_STORE}`}
                        element={<AboutStore />}
                    />

                    <Route
                        path={`/${ScreenPath.PRIVACY}`}
                        element={<Privacy />}
                    />

                    <Route
                        path={`/${ScreenPath.AGREEMENT}`}
                        element={<Agreement />}
                    />

                    <Route
                        path={`/${ScreenPath.DELIVERY}`}
                        element={<Delivery />}
                    />

                    {/* <Route
                        path={`/${ScreenPath.PAYMENT}`}
                        element={<Payment />}
                    /> */}

                    <Route path={`/${ScreenPath.AUTH}`} element={<Auth />} />

                    <Route
                        path={`/${ScreenPath.PERSONAL_AREA}`}
                        element={<PersonalArea />}
                    />

                    <Route
                        path={`/${ScreenPath.BLOG}/:id`}
                        element={<Article />}
                    />

                    <Route path={`/${ScreenPath.BLOG}`} element={<Blog />} />
                </Routes>
            </main>

            <Footer />
        </BrowserRouter>
    );
}
