import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './Courses.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Input from '@app/components/common/input/Input';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import InputFile from '@app/components/common/input-file/InputFile';
import { IconType, VideoTypes } from '@app/types/image.type';
import { Course } from '@app/types/course.type';
import { LanguageType } from '@app/types/language.type';
import { User, UserRole } from '@app/types/user.type';
import Select from '@app/components/common/select/Select';
import { SelectItem } from '@app/types/select.type';

enum FormMode {
    HIDDEN,
    ADD,
    EDIT,
}

export default function Courses() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [showForm, setShowForm] = useState(FormMode.HIDDEN);
    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        titleEn: '',
        titleUa: '',
        titleRu: '',
        descriptionEn: '',
        descriptionUa: '',
        descriptionRu: '',
    });
    const [media, setMedia] = useState<string>('');
    const [usersIds, setUsersIds] = useState<string[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);

    const texts = useMemo(
        () => contextTranslation.PersonalArea.courses,
        [contextTranslation],
    );
    const isShowForm = useMemo(
        () => showForm === FormMode.ADD || showForm === FormMode.EDIT,
        [showForm],
    );

    const userSelectList = useMemo(
        () =>
            users.length
                ? users
                      .filter(
                          user =>
                              !usersIds.includes(user._id) &&
                              user.role < UserRole.ADMIN &&
                              user.role > UserRole.GUEST,
                      )
                      .map(user => ({
                          name: user.email,
                          value: user._id,
                      }))
                : [],
        [users, usersIds],
    );
    const selectedUserEmails = useMemo(
        () =>
            users.length && usersIds.length
                ? users
                      .filter(user => usersIds.includes(user._id))
                      .map(user => user.email)
                : [],
        [users, usersIds],
    );
    const isButtonDisabled = useMemo(() => {
        return (
            (showForm === FormMode.ADD &&
                (Object.keys(formData).some(key => key !== '_id' && formData[key] === '') ||
                    !media)) ||
            (showForm === FormMode.EDIT &&
                Object.keys(formData).every(
                    key => formData[key] === defaultData[key],
                ) &&
                defaultData.users === usersIds &&
                defaultData.media === media)
        );
    }, [showForm, formData, defaultData, media, usersIds]);

    const NotificationService = injectorService.get('NotificationService');
    const CoursesService = injectorService.get('CoursesService');
    const UserService = injectorService.get('UserService');
    const TranslationService = injectorService.get('TranslationService');

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    const titleMinLength = 3;
    const titleMaxLength = 20;
    const descriptionMinTitle = 10;
    const descriptionMaxTitle = 500;
    const paginationLength = 8;

    useEffect(() => {
        loadUsers();
    }, []);

    useEffect(() => {
        if (showForm === FormMode.HIDDEN) {
            clearFields();
        }
    }, [showForm]);

    useEffect(() => {
        if (courses.length === 0) {
            loadCourses();
        }
    }, [courses]);

    const loadCourses = useCallback(async () => {
        try {
            const data = await CoursesService.get({
                from: courses.length,
                to: courses.length + paginationLength,
            });

            setCourses([...courses, ...data]);
            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }, [courses, setCourses]);

    const loadUsers = useCallback(async () => {
        try {
            const data = await UserService.getAll();

            setUsers([...data]);
        } catch (error) {
            console.log("Users weren't loaded: ", error);
        }
    }, [courses, setCourses]);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }
    }, [formData]);

    const verifyData = useCallback((): boolean => {
        const {
            titleEn,
            titleUa,
            titleRu,
            descriptionEn,
            descriptionUa,
            descriptionRu,
        } = formData;

        const errors = [
            {
                value: titleEn.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength,
            },
            {
                value: titleUa.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength,
            },
            {
                value: titleRu.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength,
            },
            {
                value: descriptionEn.length,
                minLength: descriptionMinTitle,
                maxLength: descriptionMaxTitle,
                message: texts.messages.descriptionLength,
            },
            {
                value: descriptionUa.length,
                minLength: descriptionMinTitle,
                maxLength: descriptionMaxTitle,
                message: texts.messages.descriptionLength,
            },
            {
                value: descriptionRu.length,
                minLength: descriptionMinTitle,
                maxLength: descriptionMaxTitle,
                message: texts.messages.descriptionLength,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                showNotification(NotificationType.ERROR, message);
                isError = true;
            }
        }

        return isError;
    }, [formData, texts.messages, showNotification]);

    const add = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const newCourse = await CoursesService.add({
                    title: {
                        en: formData.titleEn,
                        ua: formData.titleUa,
                        ru: formData.titleRu,
                    },
                    description: {
                        en: formData.descriptionEn,
                        ua: formData.descriptionUa,
                        ru: formData.descriptionRu,
                    },
                    media,
                    users: usersIds,
                });

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.added,
                );

                clearFields();
                setCourses([...courses, newCourse]);
            } catch (error) {
                console.log('Add course error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notAdded,
                );
            }
        },
        [
            verifyData,
            formData,
            media,
            usersIds,
            CoursesService,
            showNotification,
            texts.messages,
        ],
    );

    const clearFields = useCallback(() => {
        for (let key in formData) {
            formData[key] = '';
        }
        setMedia('');
        setUsersIds([]);
    }, [formData, media]);

    const setUpEditData = useCallback(
        async (_id: string) => {
            try {
                const course = courses.find(course => course._id === _id);

                if (!course) {
                    return console.log('Error edit course');
                }

                const newData = {
                    _id,
                    titleEn: course.title.en,
                    titleUa: course.title.ua,
                    titleRu: course.title.ru,
                    descriptionEn: course.description.en,
                    descriptionUa: course.description.ua,
                    descriptionRu: course.description.ru,
                };

                setFormData({
                    ...newData,
                });
                setDefaultData({
                    ...newData,
                    users: course.users,
                });

                setMedia(course.media || '');
                setUsersIds(course.users || []);
                setShowForm(FormMode.EDIT);
            } catch (error) {
                console.log('Error loading course data:', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notLoaded,
                );
            }
        },
        [
            CoursesService,
            courses,
            media,
            formData,
            showNotification,
            texts.messages,
        ],
    );

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const courseToEdit = courses.find(
                    course => course._id === formData._id,
                );
                if (!courseToEdit) {
                    throw "Course wasn't found";
                }

                formData.title = {
                    en: formData.titleEn,
                    ua: formData.titleUa,
                    ru: formData.titleRu,
                };
                formData.description = {
                    en: formData.descriptionEn,
                    ua: formData.descriptionUa,
                    ru: formData.descriptionRu,
                };
                formData.users = usersIds;

                const updatedFields: { [key: string]: any } = {};

                for (let key in courseToEdit) {
                    const originalValue: any =
                        courseToEdit[key as keyof typeof courseToEdit];

                    if (!originalValue) {
                        continue;
                    }

                    const newValue = formData[key];
                    if (key === 'title' || key === 'description') {
                        if (
                            !Object.keys(originalValue).every(
                                (objectKey: string) =>
                                    originalValue[objectKey] ===
                                    newValue[objectKey],
                            )
                        ) {
                            updatedFields[key] = newValue;
                        }
                    } else if (key === 'media') {
                        if (originalValue !== media) {
                            updatedFields[key] = media;
                        }
                    } else if (key === 'users') {
                        if (originalValue !== usersIds) {
                            updatedFields[key] = usersIds;
                        }
                    } else if (originalValue !== newValue) {
                        updatedFields[key] = newValue;
                    }
                }

                for (const [key, value] of Object.entries(updatedFields)) {
                    await CoursesService.set(formData._id, key, value);
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );
                clearFields();
                setShowForm(FormMode.HIDDEN);

                setCourses([]);
                setTimeout(() => {
                    loadCourses();
                }, 100);
            } catch (error) {
                console.log('Edit course error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [
            verifyData,
            media,
            usersIds,
            formData,
            courses,
            setCourses,
            CoursesService,
            showNotification,
            texts.messages,
            setIsShowPaginationButton,
        ],
    );

    const remove = useCallback(
        async (_id: string) => {
            try {
                await CoursesService.remove(_id);
                setCourses(prev => prev.filter(course => course._id !== _id));
                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.removed,
                );
            } catch (error) {
                console.log('Remove course error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notRemoved,
                );
            }
        },
        [CoursesService, showNotification, texts.messages],
    );

    const formFields = [
        { key: 'titleEn', placeholder: texts.placeholders.title.en },
        { key: 'titleUa', placeholder: texts.placeholders.title.ua },
        { key: 'titleRu', placeholder: texts.placeholders.title.ru },
        {
            key: 'descriptionEn',
            placeholder: texts.placeholders.description.en,
        },
        {
            key: 'descriptionUa',
            placeholder: texts.placeholders.description.ua,
        },
        {
            key: 'descriptionRu',
            placeholder: texts.placeholders.description.ru,
        },
    ];

    const addMedia = useCallback(
        (value: string) => {
            setMedia(value);
        },
        [media],
    );

    const removeMedia = useCallback(() => {
        setMedia('');
    }, [media]);

    const addUser = useCallback(
        (user: SelectItem) => {
            setUsersIds(prev => [...prev, user.value]);
        },
        [usersIds],
    );

    const removeUser = useCallback(
        (email: string) => {
            const id = users.find(user => user.email === email)?._id;
            const newList = usersIds.filter(listId => listId !== id);
            setUsersIds(newList);
        },
        [users, usersIds],
    );

    return (
        <div className="courses">
            <p
                className="courses_show-hide"
                onClick={() =>
                    setShowForm(isShowForm ? FormMode.HIDDEN : FormMode.ADD)
                }>
                {isShowForm ? texts.buttons.hide : texts.buttons.show}
            </p>
            <form className={`courses_form ${isShowForm ? 'show' : ''}`}>
                {formFields.map(field => (
                    <Input
                        key={`admin field ${field.key}`}
                        value={formData[field.key]}
                        onChange={(value: string) =>
                            handleChange(field.key, value)
                        }
                        placeholder={field.placeholder}
                    />
                ))}

                <Select
                    selectedItem={contextTranslation.Selects.emptyValue}
                    itemsList={userSelectList}
                    onChange={addUser}
                />

                {media ? (
                    <div className="courses_form_media">
                        <figure>
                            <video src={media} autoPlay muted></video>
                            <div className="media_controls">
                                <IconType.CROSS onClick={removeMedia} />
                            </div>
                        </figure>
                    </div>
                ) : (
                    ''
                )}

                {selectedUserEmails.length ? (
                    <div className="courses_form_users">
                        {selectedUserEmails.map(email => (
                            <div
                                className="courses_form_users_label"
                                title="remove"
                                key={`courses_form_users_label ${email}`}
                                onClick={() => removeUser(email)}>
                                {email}
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )}

                <div className="courses_form_buttons">
                    {media ? (
                        ''
                    ) : (
                        <InputFile types={VideoTypes} onChange={addMedia} />
                    )}
                    <Button
                        label={
                            showForm === FormMode.ADD
                                ? texts.buttons.add
                                : texts.buttons.edit
                        }
                        disabled={isButtonDisabled}
                        type={ButtonType.OUTLINED}
                        handlerClick={showForm === FormMode.ADD ? add : edit}
                    />
                </div>
            </form>

            {courses.length ? (
                <div className="courses_list">
                    {courses.map(course => (
                        <div className="courses_list_item" key={course._id}>
                            <figure>
                                {course.media.includes('data:video/') ? (
                                    <video
                                        src={course.media}
                                        autoPlay
                                        muted></video>
                                ) : (
                                    <img src={`${course.media}`} />
                                )}
                            </figure>
                            <div className="courses_list_item_title">
                                <h4>
                                    {
                                        course.title[
                                            language as keyof typeof course.title
                                        ]
                                    }
                                </h4>
                            </div>

                            <div className="courses_list_item_description">
                                <p>
                                    {
                                        course.description[
                                            language as keyof typeof course.description
                                        ]
                                    }
                                </p>
                            </div>

                            <div className="courses_list_item_controls">
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.edit}
                                    handlerClick={() =>
                                        course._id && setUpEditData(course._id)
                                    }
                                />
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.remove}
                                    handlerClick={() =>
                                        course._id && remove(course._id)
                                    }
                                />
                            </div>
                        </div>
                    ))}

                    {isShowPaginationButton ? (
                        <div className="courses_list_pagination">
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadCourses}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <h4 className="courses_empty">{texts.coursesListEmpty}</h4>
            )}
        </div>
    );
}
