/** @format */

import { iSearchService, iWebSocketService } from '@app/types/injector.type';
import injectorService from './injector.service';

const urls = Object.freeze({
    GET: 'get',
});

export default class SearchService implements iSearchService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'search';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async get(
        model: string,
        key: string,
        query: string,
    ): Promise<string[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: {
                    model,
                    key,
                    query,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `SearchService get error: ${error}`;
        }
    }
}
