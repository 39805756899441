import { AuthScreenType } from '@app/types/screen.type';
import './Confirm.scss';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';

export default function Confirm({ changeScreen }: { changeScreen: Function }) {
    const { contextTranslation } = useContext(TranslationContext);

    const texts = useMemo(
        () => contextTranslation.Auth,
        [contextTranslation],
    );

    const timeoutRef = useRef<any>();

    const timeoutSec = 5;

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            goLogin();
        }, timeoutSec * 1000);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    function goLogin(): void {
        changeScreen(AuthScreenType.DEFAULT);
    }

    return (
        <div className="confirm">
            <p>{texts.confirmation}</p>
        </div>
    );
}
