/** @format */

import React, { useState } from 'react';

interface WishListContextProviderProps {
    children: React.ReactNode;
}

export interface WishListContextType {
    contextWishList: string[];
    setContextWishList: React.Dispatch<React.SetStateAction<string[]>>;
}

export const WishListContext = React.createContext<WishListContextType>({
    contextWishList: [],
    setContextWishList: () => {},
});

export default function WishListContextProvider({
    children,
}: WishListContextProviderProps) {
    const [contextWishList, setContextWishList] = useState<string[]>([]);

    return (
        <WishListContext.Provider
            value={{
                contextWishList,
                setContextWishList,
            }}>
            {children}
        </WishListContext.Provider>
    );
}
