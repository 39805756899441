import './LanguageSelector.scss';
import { LanguageType } from '@app/types/language.type';
import injectorService from '@app/services/injector.service';
import { useContext, useMemo } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';

export default function LanguageSelector() {
    const { setContextTranslation } = useContext(TranslationContext);

    const languages = Object.values(LanguageType).filter(
        language => typeof language === 'string',
    );
    const TranslationService = injectorService.get('TranslationService');

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    function changeLanguage(language: string): void {
        TranslationService.language =
            LanguageType[language as keyof typeof LanguageType];

        setContextTranslation(TranslationService.translation);
    }

    return (
        <div className="languageSelector">
            <div className="languageSelector_current">{language}</div>
            <div className="languageSelector_list">
                {languages.filter(listItem => listItem.toLowerCase() !== language).map(language => (
                    <p
                        className="languageSelector_list_item"
                        onClick={() => changeLanguage(language)}
                        key={`language ${language}`}>
                        {language}
                    </p>
                ))}
            </div>
        </div>
    );
}
