import './Rating.scss';
import {
    useState,
    useEffect,
    useRef,
    useContext,
    useMemo,
    useCallback,
} from 'react';
import { IconType } from '@app/types/image.type';
import injectorService from '@app/services/injector.service';
import { UserContext } from '@app/contexts/userContext';
import { UserRole } from '@app/types/user.type';
import { useNavigate } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import { NotificationContext } from '@app/contexts/notificationContext';
import { NotificationType } from '@app/types/notification.type';
import { TranslationContext } from '@app/contexts/translationContext';

export default function Rating({ _id, rate }: { _id: string; rate: number }) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextUser } = useContext(UserContext);
    const { setContextNotification } = useContext(NotificationContext);

    const texts = useMemo(() => contextTranslation.Auth, [contextTranslation]);
    const userRole = useMemo(() => contextUser.role, [contextUser]);

    const [hovered, setHovered] = useState(rate);
    const [active, setActive] = useState(0);

    const navigate = useNavigate();

    const timeoutsRef = useRef<number[]>([]);

    const clearAllTimeouts = () => {
        timeoutsRef.current.forEach(clearTimeout);
        timeoutsRef.current = [];
    };

    const GoodService = injectorService.get('GoodService');
    const NotificationService = injectorService.get('NotificationService');

    useEffect(() => {
        clearAllTimeouts();

        if (hovered > active) {
            for (let i = active + 1; i <= hovered; i++) {
                const timeoutId = setTimeout(
                    () => setActive(i),
                    (i - active) * 100,
                ) as any as number;
                timeoutsRef.current.push(timeoutId);
            }
        } else if (hovered < active) {
            for (let i = active; i > hovered; i--) {
                const timeoutId = setTimeout(
                    () => setActive(i - 1),
                    (active - i + 1) * 100,
                ) as any as number;
                timeoutsRef.current.push(timeoutId);
            }
        }
    }, [hovered]);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    async function updateRating(value: number): Promise<void> {
        if (!_id) {
            return console.error('id is undefined');
        }

        if (!contextUser._id || userRole < UserRole.DEFAULT) {
            navigate(`/${ScreenPath.AUTH}`);
        } else if (userRole > UserRole.DEFAULT) {
            return showNotification(
                NotificationType.ERROR,
                texts.error.adminCantUpdateRate,
            );
        }

        try {
            await GoodService.edit(_id, 'rate', {
                userId: contextUser._id,
                value,
            });
        } catch (error) {
            console.error('Rating update error: ', error);
        }
    }

    return (
        <div
            className="rating"
            onMouseLeave={() => {
                clearAllTimeouts();
                setHovered(rate);
            }}>
            {new Array(5).fill(0).map((_, i) => (
                <IconType.STAR
                    key={`rating-star-${i}`}
                    className={`star ${i < active ? 'filled' : ''}`}
                    onMouseEnter={() => setHovered(i + 1)}
                    onClick={() => updateRating(i + 1)}
                />
            ))}
        </div>
    );
}
