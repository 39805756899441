import { RadiobuttonProps } from '@app/types/radiobutton.type';
import './Radiobutton.scss';

export default function RadioButton({
    label,
    isSelected,
    onChange,
}: RadiobuttonProps) {
    return (
        <div className="radiobutton">
            <input
                id={label}
                type="radio"
                checked={isSelected}
                onChange={onChange}
                hidden
            />
            <label htmlFor={label}>
                <span></span>
                {label}
            </label>
        </div>
    );
}
