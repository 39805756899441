/** @format */

import {
    iManufacturersService,
    iWebSocketService,
} from '@app/types/injector.type';
import injectorService from './injector.service';
import { Manufacturer } from '@app/types/manufacturer.type';

const urls = Object.freeze({
    GET: 'get',
    ADD: 'add',
    DELETE: 'delete',
});

export default class ManufacturersService implements iManufacturersService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'manufacturers';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async add(newManufacturer: Manufacturer): Promise<Manufacturer> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: newManufacturer,
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `ManufacturersService add error: ${error}`;
        }
    }

    public async get(): Promise<Manufacturer[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: {},
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `ManufacturersService get error: ${error}`;
        }
    }

    public async delete(_id: string): Promise<Manufacturer[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.DELETE}`,
                data: { _id },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `ManufacturersService delete error: ${error}`;
        }
    }
}
