/** @format */

import { TranslatableVariable } from './language.type';

export interface Good {
    _id?: string;
    title: TranslatableVariable;
    type: string;
    subType: string;
    subSubType: string;
    manufacturer: string;
    description: TranslatableVariable;
    application: TranslatableVariable;
    additional: TranslatableVariable;
    quantity: number;
    price: number;
    sale?: {
        value: number;
        reason: SaleType;
    };
    rate: number;
    media: string[];
    sex: GenderType;
}

export enum SaleType {
    DEFAULT,
    BROKEN_PACKAGE,
}

export enum GenderType {
    WOMAN,
    MAN,
    ALL,
}

export enum SortType {
    RATE_TOP,
    PRICE_TOP,
    PRICE_LOW,
    SALE_TOP,
    SALE_LOW,
}
