import {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Order.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { CartListContext } from '@app/contexts/cartListContext';
import { Good } from '@app/types/good.type';
import injectorService from '@app/services/injector.service';
import { LanguageType } from '@app/types/language.type';
import { UserRole } from '@app/types/user.type';
import { UserContext } from '@app/contexts/userContext';
import Button from '@app/components/common/button/Button';
import { ButtonSize, ButtonType } from '@app/types/button.type';
import Select from '@app/components/common/select/Select';
import { SelectItem } from '@app/types/select.type';
import Input from '@app/components/common/input/Input';
import InputRadio from '@app/components/common/input-radio/InputRadio';
import {
    CityData,
    DeliveryServiceType,
    PaymentType,
    Warehouse,
} from '@app/types/order.type';
import { Link, useNavigate } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import { NotificationType } from '@app/types/notification.type';
import Validator from 'validator';
import { NotificationContext } from '@app/contexts/notificationContext';
import { Promocode } from '@app/types/promocode.type';

export default function Order() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);
    const { contextCartList, setContextCartList } = useContext(CartListContext);
    const { contextUser } = useContext(UserContext);

    const deliveryPrepayment = 200;
    const selfExportAdresses = [
        {
            name: 'Павлоград, Олімпія, вулиця Незалежності 166/1',
            value: 'Павлоград, Олімпія, вулиця Незалежності 166/1',
        },
        {
            name: 'Павлоград, вулиця Центральна 21',
            value: 'Павлоград, вулиця Центральна 21',
        },
    ];

    const [contextUserDefaultData, setContextUserDefaultData] = useState({
        ...contextUser,
    });
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [goods, setGoods] = useState<Good[]>([]);
    const [cityQuery, setCityQuery] = useState('');
    const [cities, setCities] = useState<SelectItem[]>([]);
    const [selectedCity, setSelectedCity] = useState<SelectItem>(cities[0]);
    const [warehouses, setWarehouses] = useState<SelectItem[]>([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState<SelectItem>(
        selfExportAdresses[0],
    );
    const [promocodeQuery, setPromocodeQuery] = useState('');
    const [promocode, setPromocode] = useState<Promocode>();
    const [paymentType, setPaymentType] = useState<PaymentType>(
        PaymentType.CARD,
    );
    const [isPaymentStarted, setIsPaymentStarted] = useState(false);

    const NotificationService = injectorService.get('NotificationService');
    const CartListService = injectorService.get('CartListService');
    const GoodService = injectorService.get('GoodService');
    const OrderService = injectorService.get('OrderService');
    const UserService = injectorService.get('UserService');
    const TranslationService = injectorService.get('TranslationService');
    const PromocodesService = injectorService.get('PromocodesService');

    const texts = useMemo(() => contextTranslation.Order, [contextTranslation]);
    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );
    const isUserRegistered = useMemo(
        () => contextUser._id && contextUser.role === UserRole.DEFAULT,
        [contextUser],
    );
    const isShowContact = useMemo(
        () => !contextUser.name || !contextUser.phone,
        [contextUser],
    );
    const totalPrice = useMemo(() => {
        if (!goods.length) {
            return 0;
        }

        const total = goods.reduce(
            (sum, item) =>
                sum +
                (item.price * item.quantity -
                    (item.quantity * item.price * (item.sale?.value || 0)) /
                        100),
            0,
        );

        if (promocode?.value) {
            return total - (total * promocode.value) / 100;
        }

        return total;
    }, [goods, promocode]);

    const paymentSum: number = useMemo(() => {
        if (paymentType == PaymentType.ONLY_DELIVERY) {
            return totalPrice > deliveryPrepayment
                ? deliveryPrepayment
                : totalPrice;
        } else if (paymentType == PaymentType.CARD) {
            return totalPrice;
        }
        return 0;
    }, [totalPrice, paymentType]);

    const DeliveryServices: { name: string; value: DeliveryServiceType }[] = [
        { name: texts.deliveryService.own, value: DeliveryServiceType.OWN },
        { name: texts.deliveryService.nova, value: DeliveryServiceType.NOVA },
        // { name: texts.deliveryService.ukr, value: DeliveryServiceType.UKR },
        // {
        //     name: texts.deliveryService.meest,
        //     value: DeliveryServiceType.MEEST,
        // },
    ];
    const [selectedDeliveryService, setSelectedDeliveryService] = useState(
        DeliveryServices[0],
    );

    const navigate = useNavigate();

    const minCityQueryLength = 3;
    const minNameLength = 5;
    const maxNameLength = 30;

    const isPaymentDisabled = useMemo(
        () =>
            ((contextUser.name.length < minNameLength ||
                contextUser.name.length > maxNameLength) &&
                (name.length < minNameLength || name.length > maxNameLength)) ||
            (!Validator.isMobilePhone(contextUser.phone) &&
                !Validator.isMobilePhone(phone)) ||
            (!Validator.isEmail(contextUser.email) &&
                !Validator.isEmail(email)) ||
            (selectedDeliveryService.value === DeliveryServiceType.NOVA &&
                !selectedCity) ||
            !selectedWarehouse,
        [
            contextUser,
            name,
            phone,
            email,
            selectedDeliveryService,
            selectedWarehouse,
        ],
    );

    useLayoutEffect(() => {
        if (!contextUser._id) {
            return;
        }

        setContextUserDefaultData({ ...contextUser });
        setName(contextUser.name);
        setPhone(contextUser.phone);
        setEmail(contextUser.email);
    }, [contextUser]);

    useLayoutEffect(() => {
        if (!Object.keys(contextCartList).length) {
            return;
        }

        loadGoods();
    }, [contextCartList]);

    useEffect(() => {
        if (cityQuery.length < minCityQueryLength) {
            return;
        }

        loadCities(cityQuery);
    }, [cityQuery]);

    useEffect(() => {
        if (!selectedCity) {
            return;
        }

        loadWareHouses(selectedCity.value);
    }, [selectedCity]);

    async function loadGoods(): Promise<void> {
        try {
            const _ids = Object.keys(contextCartList).filter(
                key => contextCartList[key],
            );
            const data = (await GoodService.get({ _ids })).filter(
                product => product.quantity > 0,
            );

            setGoods(
                data.map(product => ({
                    ...product,
                    quantity:
                        (product._id && contextCartList[product._id]) || 1,
                })),
            );
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
        }
    }

    async function loadCities(query: string): Promise<void> {
        try {
            const response = await OrderService.getCities(query);

            const data = response.map((city: CityData) => ({
                name: `${city.Description} (${city.AreaDescription})`,
                value: city.Ref,
            }));

            setCities(data);
            setSelectedCity(data[0]);
        } catch (error) {
            console.log("Cities were't loaded: ", error);
        }
    }

    async function loadWareHouses(cityId: string): Promise<void> {
        try {
            const response = await OrderService.getWareHouses(cityId);

            const data = response.map((city: Warehouse) => ({
                name: city.Description,
                value: city.Description,
            }));

            setWarehouses(data);
            setSelectedWarehouse(data[0]);
        } catch (error) {
            console.log("Warehouses were't loaded: ", error);
        }
    }

    async function order(): Promise<void> {
        setIsPaymentStarted(true);
        const amount = paymentSum.toFixed(2);

        try {
            if (isUserRegistered) {
                await updateUserInfo();
            }

            const _id = await addOrder();

            if (paymentType !== PaymentType.CASH) {
                await proceedPayment(_id, amount);
            }
        } catch (error) {
            console.log('Order create error: ', error);
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.message.paymentError,
                ),
            );
        }
    }

    async function updateUserInfo(): Promise<void> {
        if (!name.length || contextUserDefaultData.name !== name) {
            if (name.length < minNameLength || name.length > maxNameLength) {
                setContextNotification(prev =>
                    NotificationService.add(
                        prev,
                        NotificationType.ERROR,
                        contextTranslation.PersonalArea.settings.messages
                            .nameLength,
                    ),
                );
                throw 'Incorrect name length';
            }

            await UserService.update('name', name);
        }

        if (phone.length < 4 || contextUserDefaultData.phone !== phone) {
            if (!Validator.isMobilePhone(phone)) {
                setContextNotification(prev =>
                    NotificationService.add(
                        prev,
                        NotificationType.ERROR,
                        contextTranslation.PersonalArea.settings.messages
                            .incorrectPhone,
                    ),
                );
                throw 'Incorrect phone';
            }

            await UserService.update('phone', phone);
        }
    }

    async function checkPromocode(): Promise<void> {
        try {
            const response = await PromocodesService.get({
                userEmail: contextUser.email || email,
                title: promocodeQuery,
            });

            if (!response[0]) {
                throw "Promocode wasn't found";
            }
            
            setPromocode(response[0]);
        } catch (error) {
            console.log('Order checkPromocode error: ', error);
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.message.promocodeError,
                ),
            );
        }
    }

    async function proceedPayment(_id: string, amount: string): Promise<void> {
        try {
            const link = await OrderService.getLink(_id, amount);

            finishOrder();

            if (paymentType == PaymentType.CASH) {
                navigate(`/${ScreenPath.CATALOGUE}`);
            } else {
                window.location.href = link;
            }
        } catch (error) {
            throw `get link exception ${error}`;
        }
    }

    async function addOrder(): Promise<string> {
        console.log(promocode);
        
        const _id = await OrderService.add({
            goods: goods.reduce(
                (
                    acc: {
                        [key: string]: {
                            quantity: number;
                            price: number;
                        };
                    },
                    curr,
                ) => {
                    if (curr._id) {
                        acc[curr._id] = {
                            quantity: curr.quantity,
                            price: curr.price - (curr.price * (curr.sale?.value || 0)) / 100,
                        };
                    }
                    return acc;
                },
                {},
            ),
            name: contextUser.name || name,
            phone: contextUser.phone || phone,
            email: contextUser.email || email,
            deliveryService: selectedDeliveryService.value,
            warehouse: selectedWarehouse.value,
            paymentType,
            paymentSum,
            promocode,
        });

        if (promocode?._id) {
            await PromocodesService.apply(
                promocode?._id,
                contextUser.email || email,
            );
        }

        return _id;
    }

    function finishOrder(): void {
        setContextCartList({});
        CartListService.setData({});
    }

    const selectDeliveryService = useCallback(
        (value: SelectItem) => {
            setSelectedDeliveryService(value);
        },
        [selectedDeliveryService],
    );

    const selectPaymentType = useCallback(
        (value: PaymentType) => {
            setPaymentType(value);
        },
        [paymentType],
    );

    return (
        <div className="order container">
            <h2>{texts.title}</h2>

            <div className="order_main">
                <div className="order_main_delivery-info">
                    {isShowContact ? (
                        <>
                            <h4 className="order_main_delivery-info_title">
                                {texts.subTitle.contactData}
                            </h4>
                            <div className="order_main_delivery-info_contact-data">
                                <Input
                                    isReadOnly={!!contextUser.name}
                                    value={contextUser.name || name}
                                    onChange={setName}
                                    placeholder={texts.placeholder.name}
                                />
                                <Input
                                    isReadOnly={!!contextUser.phone}
                                    type="phone"
                                    value={contextUser.phone || phone}
                                    onChange={setPhone}
                                />
                                <Input
                                    isReadOnly={!!contextUser.email}
                                    value={contextUser.email || email}
                                    onChange={setEmail}
                                    placeholder={texts.placeholder.email}
                                />
                            </div>
                        </>
                    ) : (
                        ''
                    )}

                    <h4 className="order_main_delivery-info_title">
                        {texts.subTitle.delivery}
                    </h4>
                    <div className="order_main_delivery-info_delivery">
                        <Select
                            itemsList={DeliveryServices}
                            selectedItem={selectedDeliveryService}
                            onChange={selectDeliveryService}
                        />
                        {selectedDeliveryService.value !==
                        DeliveryServiceType.OWN ? (
                            <>
                                <Input
                                    value={cityQuery}
                                    onChange={setCityQuery}
                                    placeholder={texts.placeholder.cityQuery}
                                />

                                {cities.length ? (
                                    <>
                                        <Select
                                            itemsList={cities}
                                            selectedItem={selectedCity}
                                            onChange={setSelectedCity}
                                        />

                                        {warehouses.length ? (
                                            <Select
                                                itemsList={warehouses}
                                                selectedItem={selectedWarehouse}
                                                onChange={setSelectedWarehouse}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : selfExportAdresses.length ? (
                            <Select
                                itemsList={selfExportAdresses}
                                selectedItem={selectedWarehouse}
                                onChange={setSelectedWarehouse}
                            />
                        ) : (
                            ''
                        )}

                        <p>* {texts.freeDelivery}</p>
                    </div>

                    <h4 className="order_main_delivery-info_title">
                        {texts.subTitle.payment}
                    </h4>
                    <div className="order_main_delivery-info_payment">
                        <InputRadio
                            name="payment"
                            value={PaymentType.CARD}
                            label={texts.payment.card}
                            onChange={selectPaymentType}
                            isSelected={true}
                        />

                        {selectedDeliveryService.value ===
                        DeliveryServiceType.OWN ? (
                            <InputRadio
                                name="payment"
                                value={PaymentType.CASH}
                                label={texts.payment.cash}
                                onChange={selectPaymentType}
                            />
                        ) : (
                            <InputRadio
                                name="payment"
                                value={PaymentType.ONLY_DELIVERY}
                                label={texts.payment.onlyDelivery}
                                onChange={selectPaymentType}
                            />
                        )}
                    </div>

                    <h4 className="order_main_delivery-info_title">
                        {texts.subTitle.promocode}
                    </h4>
                    <div className="order_main_delivery-info_promocode">
                        <Input
                            isReadOnly={!!promocode?.value}
                            value={promocodeQuery}
                            onChange={setPromocodeQuery}
                            placeholder={texts.subTitle.promocode}
                        />
                        {!promocode?.value ? (
                            <Button
                                disabled={isPaymentStarted}
                                label={texts.buttons.apply}
                                size={ButtonSize.SMALL}
                                type={ButtonType.OUTLINED}
                                handlerClick={checkPromocode}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <div className="order_main_products">
                    <h4 className="order_main_products_title">
                        {texts.subTitle.order}
                    </h4>
                    {goods.length
                        ? goods.map(good => (
                              <div
                                  className="order_main_products_item"
                                  key={`order product ${good._id}`}>
                                  <figure>
                                      {good.media[0].includes('data:video/') ? (
                                          <video
                                              src={good.media[0]}
                                              autoPlay
                                              muted></video>
                                      ) : (
                                          <img
                                              src={`${good.media[0]}`}
                                              alt={good.title.ua}
                                          />
                                      )}
                                  </figure>

                                  <Link
                                      to={`/${ScreenPath.CATALOGUE}/${good?.type}/${good?._id}`}
                                      className="order_main_products_item_description">
                                      <h4>{good.manufacturer.toUpperCase()}</h4>
                                      <p>
                                          {
                                              good.title[
                                                  language as keyof typeof good.title
                                              ]
                                          }
                                      </p>
                                  </Link>

                                  <h4 className="order_main_products_item_quantity">
                                      {good.quantity}
                                  </h4>

                                  <h4 className="order_main_products_item_price">
                                      UAH{' '}
                                      {good.sale?.value ? (
                                          <>
                                              {(
                                                  good.quantity * good.price -
                                                  (good.quantity *
                                                      good.price *
                                                      good.sale?.value) /
                                                      100
                                              ).toFixed(2)}
                                              <sup>
                                                  <s>
                                                      {(
                                                          good.quantity *
                                                          good.price
                                                      ).toFixed(2)}
                                                  </s>
                                              </sup>
                                          </>
                                      ) : (
                                          (good.quantity * good.price).toFixed(
                                              2,
                                          )
                                      )}
                                  </h4>
                              </div>
                          ))
                        : ''}

                    {paymentType == PaymentType.ONLY_DELIVERY ? (
                        <h4 className="order_main_products_prepayment">
                            {texts.subTitle.prepayment}: UAH{' '}
                            {paymentSum.toFixed(2)}
                        </h4>
                    ) : (
                        ''
                    )}

                    <h4 className="order_main_products_total">
                        {texts.subTitle.total}: UAH {totalPrice.toFixed(2)}
                    </h4>

                    {!isPaymentDisabled ? (
                        <Button
                            disabled={isPaymentStarted}
                            label={texts.buttons.confirm}
                            size={ButtonSize.BIG}
                            handlerClick={order}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}
