import { Link, useParams } from 'react-router-dom';
import './CardPage.scss';
import ImageScroller from '@app/components/common/image-scroller/ImageScroller';
import Rating from '@app/components/common/rating/Rating';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import injectorService from '@app/services/injector.service';
import { Good, SortType } from '@app/types/good.type';
import { LanguageType } from '@app/types/language.type';
import { CartListContext } from '@app/contexts/cartListContext';
import GoodsList from '@app/components/common/goods-list/GoodsList';
import { IconType } from '@app/types/image.type';
import { ScreenPath } from '@app/types/screen.type';
import { LoaderContext } from '@app/contexts/loaderContext';
import { AppInfoContext } from '@app/contexts/appInfoContext';

export default function CardPage() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextLoader } = useContext(LoaderContext);
    const { contextAppInfo } = useContext(AppInfoContext);
    const { contextCartList, setContextCartList } = useContext(CartListContext);

    const [product, setProduct] = useState<Good>();
    const [isApplicationOpened, setIsApplicationOpened] = useState(false);
    const [isAdditionalOpened, setIsAdditionalOpened] = useState(false);
    const [recomendations, setRecomendations] = useState<Good[]>([]);

    const { id } = useParams();

    const texts = useMemo(
        () => contextTranslation.CardPage,
        [contextTranslation],
    );

    const TranslationService = injectorService.get('TranslationService');
    const GoodService = injectorService.get('GoodService');
    const SeoService = injectorService.get('SeoService');
    const CartListService = injectorService.get('CartListService');

    const language = useMemo(
        () =>
            LanguageType[TranslationService.language].toLowerCase() as
                | 'ua'
                | 'en'
                | 'ru',
        [TranslationService.language],
    );

    useLayoutEffect(() => {
        loadData();
    }, [id]);

    useLayoutEffect(() => {
        if (!product) {
            return;
        }

        setSeo({
            title: product.title.ua,
            description: product.description.ua,
        });
    }, [product]);

    useLayoutEffect(() => {
        if (!contextAppInfo.projectTitle || !product) {
            return setContextLoader({ show: true });
        }

        setContextLoader({ show: false });
    }, [contextAppInfo, product]);

    async function loadData(): Promise<void> {
        try {
            if (!id) {
                return;
            }
            const data = await GoodService.get({ _ids: [id] });
            setProduct(data[0]);

            await loadRecomendations(data[0]);
        } catch (error) {
            console.error("Product wasn't loaded");
        }
    }

    async function loadRecomendations({
        _id,
        type,
        subType,
    }: {
        _id?: string;
        type: string;
        subType: string;
    }): Promise<void> {
        try {
            if (!id) {
                return;
            }
            const data = await GoodService.get({
                from: 0,
                to: 20,
                type,
                sortBy: SortType.RATE_TOP,
            });

            const newRecomendations = data
                .filter(product => product._id !== _id)
                .sort((a, b) => {
                    if (a.rate !== b.rate) {
                        return b.rate - a.rate;
                    }

                    if (a.subType === subType && b.subType !== subType) {
                        return 1;
                    }
                    if (b.subType === subType && a.subType !== subType) {
                        return -1;
                    }
                    return 0;
                });

            setRecomendations([...newRecomendations.slice(0, 4)]);
        } catch (error) {
            console.error("Recomendations weren't loaded");
        }
    }

    function setSeo(data: { title: string; description: string }): void {
        SeoService.update(data);
    }

    function updateList(id?: string): void {
        if (!id) {
            return console.error('id is undefined');
        }

        const isNew = id && !contextCartList[id];
        const newList = isNew
            ? addItem(id, contextCartList)
            : removeItem(id, contextCartList);

        setContextCartList({ ...newList });
        CartListService.setData({ ...newList });
    }

    function addItem(
        id: string,
        context: { [key: string]: number },
    ): { [key: string]: number } {
        return { ...context, [id]: 1 };
    }

    function removeItem(
        id: string,
        context: { [key: string]: number },
    ): { [key: string]: number } {
        const newContext = { ...context };
        delete newContext[id];
        return { ...newContext };
    }

    return (
        <>
            {product ? (
                <div className="cardPage container">
                    <div className="cardPage_main">
                        <ImageScroller media={product.media} />

                        <div className="cardPage_main_description">
                            <div className="cardPage_main_description_title">
                                <Link
                                    to={`/${ScreenPath.CATALOGUE}/manufacturer/${product.manufacturer.toLowerCase()}`}>
                                    <h3>
                                        {product.manufacturer.toUpperCase()}
                                    </h3>
                                </Link>

                                <h4>{product.title[language]}</h4>
                            </div>

                            <div>
                                <pre>{product.description[language]}</pre>
                                <Rating
                                    _id={product?._id || ''}
                                    rate={product.rate}
                                />
                            </div>

                            <div className="cardPage_main_other">
                                <div
                                    className={`cardPage_main_other_application ${isApplicationOpened ? 'opened' : ''}`}
                                    onClick={() =>
                                        setIsApplicationOpened(
                                            !isApplicationOpened,
                                        )
                                    }>
                                    <div className="cardPage_main_other_application_button">
                                        <h4>{texts.howToUse}</h4>
                                        {isApplicationOpened ? (
                                            <IconType.MINUS />
                                        ) : (
                                            <IconType.PLUS />
                                        )}
                                    </div>
                                    <pre className="cardPage_main_other_application_info">
                                        {product.application[language]}
                                    </pre>
                                </div>
                                <div
                                    className={`cardPage_main_other_additional ${isAdditionalOpened ? 'opened' : ''}`}
                                    onClick={() =>
                                        setIsAdditionalOpened(
                                            !isAdditionalOpened,
                                        )
                                    }>
                                    <div className="cardPage_main_other_additional_button">
                                        <h4>{texts.additionalParams}</h4>
                                        {isAdditionalOpened ? (
                                            <IconType.MINUS />
                                        ) : (
                                            <IconType.PLUS />
                                        )}
                                    </div>
                                    <pre className="cardPage_main_other_additional_info">
                                        {product.additional[language]}
                                    </pre>
                                </div>
                            </div>

                            <div className="cardPage_main_description_buy">
                                <h4>
                                    UAH{' '}
                                    {product.sale?.value ? (
                                        <>
                                            {(
                                                product?.price -
                                                (product?.price *
                                                    product.sale?.value) /
                                                    100
                                            ).toFixed(2)}{' '}
                                            <sup>
                                                <s>
                                                    {product?.price.toFixed(2)}
                                                </s>
                                            </sup>
                                        </>
                                    ) : (
                                        product?.price.toFixed(2)
                                    )}
                                </h4>

                                <Button
                                    disabled={product.quantity === 0}
                                    type={ButtonType.OUTLINED}
                                    label={
                                        product._id &&
                                        contextCartList[product._id]
                                            ? contextTranslation.Buttons
                                                  .removeFromCart
                                            : contextTranslation.Buttons
                                                  .addToCart
                                    }
                                    handlerClick={() => updateList(product._id)}
                                />
                            </div>
                        </div>
                    </div>

                    {recomendations.length ? (
                        <div className="cardPage_other">
                            <h2>{texts.mayAlsoLike}</h2>
                            <div className="cardPage_other_goods">
                                <GoodsList list={recomendations} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    );
}
