import { useContext, useMemo } from 'react';
import './Delivery.scss';
import { TranslationContext } from '@app/contexts/translationContext';

export default function Delivery() {
    const { contextTranslation } = useContext(TranslationContext);

    const texts = useMemo(
        () => contextTranslation.Delivery,
        [contextTranslation],
    );

    return (
        <div className="delivery container">
            {texts.sections.map(
                (section: { title: string; texts: string[] }, i: number) => (
                    <section key={`delivery section #${i}`}>
                        <h4>{section.title}</h4>
                        {section.texts.map((paragraph: string, k: number) => (
                            <p key={`delivery section #${i} paragraph #${k}`}>
                                {paragraph}
                            </p>
                        ))}
                    </section>
                ),
            )}
        </div>
    );
}
