import {
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Cart.scss';
import { CartListContext } from '@app/contexts/cartListContext';
import { Good } from '@app/types/good.type';
import { TranslationContext } from '@app/contexts/translationContext';
import { IconType } from '@app/types/image.type';
import Button from '@app/components/common/button/Button';
import injectorService from '@app/services/injector.service';
import { LanguageType } from '@app/types/language.type';
import { Link, useNavigate } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';

export default function Cart() {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextCartList, setContextCartList } = useContext(CartListContext);

    const [goodQuantity, setGoodQuantity] = useState<{ [key: string]: number }>(
        {},
    );
    const [goods, setGoods] = useState<Good[]>([]);

    const GoodService = injectorService.get('GoodService');
    const CartListService = injectorService.get('CartListService');
    const TranslationService = injectorService.get('TranslationService');

    const texts = useMemo(() => contextTranslation.Cart, [contextTranslation]);
    const totalPrice = useMemo(
        () =>
            goods.length &&
            goods.reduce(
                (sum, item) =>
                    sum +
                    (item.price * item.quantity -
                        (item.quantity * item.price * (item.sale?.value || 0)) /
                            100),
                0,
            ),
        [goods],
    );
    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (!Object.keys(contextCartList).length) {
            return;
        }

        loadGoods();
        CartListService.setData({ ...contextCartList });
    }, [contextCartList]);

    useEffect(() => {
        goods.forEach(good => {
            if (!good.quantity) {
                removeFromCart(good._id);
            }
        });
    }, [goods]);

    async function loadGoods(): Promise<void> {
        try {
            const _ids = Object.keys(contextCartList).filter(
                key => contextCartList[key],
            );
            const data = (await GoodService.get({ _ids })).filter(
                product => product.quantity > 0,
            );

            updateQuantity(data);
            setGoods(
                data.map(product => ({
                    ...product,
                    quantity:
                        (product._id && contextCartList[product._id]) || 1,
                })),
            );
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
        }
    }

    function updateQuantity(products: Good[]): void {
        products.forEach(product => {
            if (typeof product._id === 'string') {
                setGoodQuantity(prev => ({
                    ...prev,
                    [String(product._id)]: product.quantity,
                }));
            }
        });
    }

    function increaseQuantity(_id?: string): void {
        if (!_id) {
            return console.error('id is undefined');
        }
        setGoods(prevGoods =>
            prevGoods.map(good =>
                good._id === _id
                    ? { ...good, quantity: good.quantity + 1 }
                    : good,
            ),
        );
        setContextCartList(prev => ({
            ...prev,
            [_id]: prev[_id] + 1,
        }));
    }

    function decreaseQuantity(_id?: string): void {
        if (!_id) {
            return console.error('id is undefined');
        }
        setGoods(prevGoods =>
            prevGoods.map(good =>
                good._id === _id
                    ? { ...good, quantity: good.quantity - 1 }
                    : good,
            ),
        );
        setContextCartList(prev => {
            return {
                ...prev,
                [_id]: prev[_id] - 1,
            };
        });
    }

    function removeFromCart(id?: string): void {
        if (!id) {
            return;
        }

        setContextCartList(prev => {
            delete prev[id];
            CartListService.setData({ ...prev });
            return { ...prev };
        });
    }

    function order(): void {
        navigate(`/${ScreenPath.ORDER}`);
    }

    return (
        <div className="cart container">
            <h2 className="cart_title">{texts.title}</h2>

            {goods.length ? (
                <>
                    {goods.map(good => (
                        <div className="cart_item" key={good._id}>
                            <figure>
                                {good.media[0].includes('data:video/') ? (
                                    <video
                                        src={good.media[0]}
                                        autoPlay
                                        muted></video>
                                ) : (
                                    <img
                                        src={`${good.media[0]}`}
                                        alt={good.title.ua}
                                    />
                                )}
                            </figure>
                            <Link
                                to={`/${ScreenPath.CATALOGUE}/${good?.type}/${good?._id}`}
                                className="cart_item_description">
                                <h4>{good.manufacturer.toUpperCase()}</h4>
                                <p>
                                    {
                                        good.title[
                                            language as keyof typeof good.title
                                        ]
                                    }
                                </p>
                            </Link>
                            <div className="cart_item_other">
                                <div className="cart_item_other_quantity">
                                    <div
                                        className="cart_item_other_quantity_button"
                                        onClick={() =>
                                            decreaseQuantity(good._id)
                                        }>
                                        <IconType.MINUS />
                                    </div>

                                    <p>{good.quantity}</p>
                                    <div
                                        className={`cart_item_other_quantity_button ${
                                            good._id &&
                                            good.quantity <
                                                goodQuantity[good._id]
                                                ? ''
                                                : 'disabled'
                                        }`}
                                        onClick={() =>
                                            good._id &&
                                            good.quantity <
                                                goodQuantity[good._id]
                                                ? increaseQuantity(good._id)
                                                : ''
                                        }>
                                        <IconType.PLUS />
                                    </div>
                                </div>
                                <h4 className="cart_item_other_price">
                                    UAH{' '}
                                    {good.sale?.value ? (
                                        <>
                                            {(
                                                good.quantity * good.price -
                                                (good.quantity *
                                                    good.price *
                                                    good.sale?.value) /
                                                    100
                                            ).toFixed(2)}
                                            <sup>
                                                <s>
                                                    {(
                                                        good.quantity *
                                                        good.price
                                                    ).toFixed(2)}
                                                </s>
                                            </sup>
                                        </>
                                    ) : (
                                        (good.quantity * good.price).toFixed(2)
                                    )}
                                </h4>
                                <IconType.CROSS
                                    className="cart_item_other_delete"
                                    onClick={() => removeFromCart(good._id)}
                                />
                            </div>
                        </div>
                    ))}
                    <h4 className="cart_total">
                        {texts.total}: UAH {totalPrice.toFixed(2)}
                    </h4>
                    <Button
                        label={contextTranslation.Buttons.order}
                        handlerClick={order}
                    />
                </>
            ) : (
                <h3>{texts.empty}</h3>
            )}
        </div>
    );
}
