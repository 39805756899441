import ReactDOM from 'react-dom/client';
import App from './app/App';
import TranslationContextProvider from '@app/contexts/translationContext';
import NotificationContextProvider from '@app/contexts/notificationContext';
import UserContextProvider from '@app/contexts/userContext';
import AppInfoContextProvider from '@app/contexts/appInfoContext';
import LoaderContextProvider from '@app/contexts/loaderContext';
import WishListContextProvider from '@app/contexts/wishListContext';
import CartListContextProvider from '@app/contexts/cartListContext';
import ManufacturersContextProvider from '@app/contexts/manufacturersContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <TranslationContextProvider>
        <LoaderContextProvider>
            <ManufacturersContextProvider>
                <NotificationContextProvider>
                    <UserContextProvider>
                        <AppInfoContextProvider>
                            <WishListContextProvider>
                                <CartListContextProvider>
                                    <App />
                                </CartListContextProvider>
                            </WishListContextProvider>
                        </AppInfoContextProvider>
                    </UserContextProvider>
                </NotificationContextProvider>
            </ManufacturersContextProvider>
        </LoaderContextProvider>
    </TranslationContextProvider>,
);
