/** @format */

import './ArticleConstructor.scss';
import Textarea from '../textarea/Textarea';
import { IconType, ImageTypes } from '@app/types/image.type';
import { useCallback, useRef } from 'react';
import InputFile from '../input-file/InputFile';

enum ArticleConstructorButton {
    TITLE,
    PARAGRAPH,
    IMAGE,
}

export default function ArticleConstructor({
    value,
    onChange,
}: {
    value: string;
    onChange: Function;
}) {
    const inputFileRef = useRef<HTMLDivElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    function handlerChange(newValue: string, cursorPosition?: number): void {
        if (onChange) {
            onChange(newValue);

            if (cursorPosition !== undefined && textareaRef.current) {
                requestAnimationFrame(() => {
                    textareaRef.current!.selectionStart = cursorPosition;
                    textareaRef.current!.selectionEnd = cursorPosition;
                    textareaRef.current!.focus();
                });
            }
        }
    }

    const addElement = useCallback(
        (type: ArticleConstructorButton) => {
            let newElement = '<p></p>';

            switch (type) {
                case ArticleConstructorButton.TITLE:
                    newElement = '<h3></h3>';
                    break;

                case ArticleConstructorButton.IMAGE:
                    if (inputFileRef.current) {
                        return inputFileRef.current.click();
                    }
                    break;
            }

            if (textareaRef.current) {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;

                const beforeCursor = value.slice(0, start);
                const afterCursor = value.slice(end);

                const newValue = `${beforeCursor}${newElement}${afterCursor}`;
                const newCursorPosition = start + newElement.length;

                handlerChange(newValue, newCursorPosition);
            }
        },
        [value],
    );

    const addMedia = useCallback(
        (newMedia: string) => {
            if (textareaRef.current) {
                const textarea = textareaRef.current;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;

                const beforeCursor = value.slice(0, start);
                const afterCursor = value.slice(end);

                const newElement = `<figure><img src="${newMedia}" /></figure>`;
                const newValue = `${beforeCursor}${newElement}${afterCursor}`;
                const newCursorPosition = start + newElement.length;

                handlerChange(newValue, newCursorPosition);
            }
        },
        [value],
    );

    return (
        <div className="articleConstructor">
            <Textarea
                customRef={textareaRef}
                value={value}
                onChange={(newValue: string) => handlerChange(newValue)}
            />
            <div className="articleConstructor_controls">
                <div
                    className="articleConstructor_controls_item button type-outlined"
                    onClick={() => addElement(ArticleConstructorButton.TITLE)}>
                    H
                </div>
                <div
                    className="articleConstructor_controls_item button type-outlined"
                    onClick={() =>
                        addElement(ArticleConstructorButton.PARAGRAPH)
                    }>
                    P
                </div>
                <div
                    className="articleConstructor_controls_item button type-outlined"
                    onClick={() => addElement(ArticleConstructorButton.IMAGE)}>
                    <IconType.IMAGE />
                </div>
                <InputFile
                    customRef={inputFileRef}
                    types={ImageTypes}
                    onChange={addMedia}
                />
            </div>
        </div>
    );
}
