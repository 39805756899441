import './WishList.scss';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import GoodsList from '@app/components/common/goods-list/GoodsList';
import { TranslationContext } from '@app/contexts/translationContext';
import { WishListContext } from '@app/contexts/wishListContext';
import injectorService from '@app/services/injector.service';
import { Good } from '@app/types/good.type';

export default function WishList() {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextWishList } = useContext(WishListContext);

    const texts = useMemo(
        () => contextTranslation.WishList,
        [contextTranslation],
    );

    const [goods, setGoods] = useState<Good[]>([]);

    const GoodService = injectorService.get('GoodService');

    useLayoutEffect(() => {
        if (!contextWishList.length) {
            return;
        }
        
        loadGoods();
    }, [contextWishList]);

    async function loadGoods(): Promise<void> {
        try {
            const data = await GoodService.get({
                _ids: contextWishList,
            });

            setGoods([...data]);
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
        }
    }

    return (
        <div className="wishList container">
            {goods.length ? (
                <>
                    <h2 className="cart_title">{texts.title}</h2>
                    <GoodsList list={goods} />
                </>
            ) : (
                <h3 className="wishList_empty">{texts.empty}</h3>
            )}
        </div>
    );
}
