import { Link, useLocation } from 'react-router-dom';
import './Header.scss';
import { useContext, useEffect, useMemo } from 'react';
import { ImageType, IconType } from '@app/types/image.type';
import { ScreenPath } from '@app/types/screen.type';
import BreadCrumbs from '../breadcrumbs/BreadCrumbs';
import BackButton from '../back-button/BackButton';
import LanguageSelector from '../language-selector/LanguageSelector';
import injectorService from '@app/services/injector.service';
import { AppInfoContext } from '@app/contexts/appInfoContext';
import Search from '../search/Search';
import { CartListContext } from '@app/contexts/cartListContext';
import { WishListContext } from '@app/contexts/wishListContext';

export default function Header() {
    const { contextAppInfo } = useContext(AppInfoContext);
    const { contextCartList } = useContext(CartListContext);
    const { contextWishList } = useContext(WishListContext);

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        setSeo(location.pathname);
    }, [location, contextAppInfo]);

    const breadcrumbsHiddenForScreens = [
        '/',
        `/${ScreenPath.CART}`,
        `/${ScreenPath.AUTH}`,
        `/${ScreenPath.PERSONAL_AREA}`,
    ];
    const isShowBreadcrumbs = useMemo(
        () => !breadcrumbsHiddenForScreens.includes(location.pathname),
        [location],
    );

    const backButtonShownForScreens = [
        `/${ScreenPath.WISH_LIST}`,
        `/${ScreenPath.CART}`,
        `/${ScreenPath.ORDER}`,
    ];
    const isShowBackButton = useMemo(
        () => backButtonShownForScreens.includes(location.pathname),
        [location],
    );

    const SeoService = injectorService.get('SeoService');

    function setSeo(path: string): void {
        if (path.includes(ScreenPath.CATALOGUE)) {
            return; // SEO set in cardPage
        } else if (path.includes(ScreenPath.AUTH)) {
            SeoService.update({
                title: `Авторизація | Інтернет-магазин парфумерії - ${contextAppInfo.projectTitle} ${contextAppInfo.projectSubTitle}`,
                description: `Увійдіть в особистий кабінет інтернет-магазину ${contextAppInfo.projectTitle} ${contextAppInfo.projectSubTitle}, щоб відстежувати замовлення, зберігати обрані товари та швидко оформлювати покупки. Насолоджуйтеся зручним шопінгом і найкращими ароматами.`,
            });
        } else {
            SeoService.update({
                title: `${contextAppInfo.projectTitle} ${contextAppInfo.projectSubTitle}`,
                description: 'Магазин професійної косметики Павлоград',
            });
        }
    }

    return (
        <div className="container">
            <header>
                <Link to={`/${ScreenPath.HOME}`} className="logo">
                    <img src={ImageType.LOGO} alt="logo" />
                </Link>

                <div className="header_controls">
                    <Search />
                    <Link to={ScreenPath.AUTH}>
                        <IconType.PERSON />
                    </Link>
                    <Link
                        to={ScreenPath.WISH_LIST}
                        className="header_controls_control-with-quantity">
                        <IconType.HEART />
                        {contextWishList.length ? (
                            <div className="header_controls_control-with-quantity_quantity">
                                {contextWishList.length}
                            </div>
                        ) : (
                            ''
                        )}
                    </Link>
                    <Link
                        to={ScreenPath.CART}
                        className="header_controls_control-with-quantity">
                        <IconType.CART />
                        {Object.keys(contextCartList).length ? (
                            <div className="header_controls_control-with-quantity_quantity">
                                {Object.keys(contextCartList).length}
                            </div>
                        ) : (
                            ''
                        )}
                    </Link>
                    {/* <IconType.BURGER /> */}
                    <LanguageSelector />
                </div>
            </header>
            {isShowBreadcrumbs ? <BreadCrumbs /> : ''}
            {isShowBackButton ? <BackButton /> : ''}
        </div>
    );
}
