import {
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import './Article.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { Article } from '@app/types/article.type';
import injectorService from '@app/services/injector.service';
import { useParams } from 'react-router-dom';
import { LanguageType } from '@app/types/language.type';

export default function ArticlePage() {
    const [article, setArticle] = useState<Article>();

    const ArticleService = injectorService.get('ArticleService');
    const TranslationService = injectorService.get('TranslationService');

    const language = useMemo(
        () =>
            LanguageType[TranslationService.language].toLowerCase() as
                | 'ua'
                | 'en'
                | 'ru',
        [TranslationService.language],
    );

    const { id } = useParams();

    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window.location.search.includes('preview=true')) {
            (
                document.querySelector('header') as HTMLHeadingElement
            ).style.display = 'none';

            const breadCrumbs = document.querySelector(
                '.breadCrumbs',
            ) as HTMLDivElement;
            if (breadCrumbs) {
                breadCrumbs.style.display = 'none';
            }

            (document.querySelector('footer') as HTMLDivElement).style.display =
                'none';
        }
    }, [article]);

    useLayoutEffect(() => {
        if (!id) {
            return;
        }

        loadArticle();
    }, [id]);

    async function loadArticle(): Promise<void> {
        try {
            if (!id) {
                throw 'id is undefined';
            }

            const data = await ArticleService.get({
                ids: [id],
            });

            setArticle(data[0]);
        } catch (error) {}
    }

    return (
        <div className="article" ref={containerRef}>
            {article && article.content[language] ? (
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: article.content[language],
                        }}></div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
