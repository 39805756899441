/** @format */

import { iNotificationService } from '@app/types/injector.type';
import { Notification, NotificationType } from '@app/types/notification.type';

export class NotificationService implements iNotificationService {
    constructor() {}

    public add(
        notifications: Notification[],
        type: NotificationType,
        message: string,
    ): Notification[] {
        return [
            ...notifications,
            {
                id: this.getNewId(notifications),
                type,
                message,
            },
        ];
    }

    private getNewId(notifications: Notification[]): number {
        return notifications.length
            ? notifications[notifications.length - 1].id + 1
            : 0;
    }
}
