/** @format */

import React, { useState } from 'react';

interface ManufacturersContextProviderProps {
    children: React.ReactNode;
}

export interface ManufacturersContextType {
    contextManufacturers: string[];
    setContextManufacturers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ManufacturersContext = React.createContext<ManufacturersContextType>({
    contextManufacturers: [],
    setContextManufacturers: () => {},
});

export default function ManufacturersContextProvider({
    children,
}: ManufacturersContextProviderProps) {
    const [contextManufacturers, setContextManufacturers] = useState<string[]>([]);

    return (
        <ManufacturersContext.Provider
            value={{
                contextManufacturers,
                setContextManufacturers,
            }}>
            {children}
        </ManufacturersContext.Provider>
    );
}
