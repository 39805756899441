import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Orders.scss';
import injectorService from '@app/services/injector.service';
import { LanguageType } from '@app/types/language.type';
import {
    DeliveryServiceType,
    Order,
    OrderStatus,
    PaymentType,
} from '@app/types/order.type';
import { TranslationContext } from '@app/contexts/translationContext';
import Button from '@app/components/common/button/Button';
import { ButtonSize, ButtonType } from '@app/types/button.type';
import { NotificationContext } from '@app/contexts/notificationContext';
import { NotificationType } from '@app/types/notification.type';
import { Good } from '@app/types/good.type';

export default function Orders() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [orders, setOrders] = useState<Order[]>([]);
    const [openedOrder, setOpenedOrder] = useState<Order | undefined>();
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);
    const [orderGoods, setOrderGoods] = useState<Good[]>([]);

    const GoodService = injectorService.get('GoodService');
    const OrderService = injectorService.get('OrderService');
    const TranslationService = injectorService.get('TranslationService');
    const NotificationService = injectorService.get('NotificationService');

    const texts = useMemo(
        () => contextTranslation.PersonalArea.orders,
        [contextTranslation],
    );
    const language = useMemo(
        () =>
            LanguageType[TranslationService.language].toLowerCase() as
                | 'ua'
                | 'en'
                | 'ru',
        [TranslationService.language],
    );

    const paginationLength = 8;

    useLayoutEffect(() => {
        loadOrders();
    }, []);

    useLayoutEffect(() => {
        if (!openedOrder) {
            return;
        }

        loadGoods();
    }, [orders, openedOrder]);

    async function loadOrders(): Promise<void> {
        try {
            const data = await OrderService.get({
                from: orders.length,
                to: orders.length + paginationLength,
            });

            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }

            setOrders([...orders, ...data]);
        } catch (error) {}
    }

    const loadGoods = useCallback(async () => {
        try {
            setOrderGoods([]);

            if (!openedOrder) {
                return;
            }

            const data = await GoodService.get({
                _ids: Object.keys(openedOrder.goods),
            });

            const updatedOrderGoods = data.reduce((acc: Good[], curr) => {
                if (curr._id && openedOrder.goods[curr._id]) {
                    acc.push({
                        ...curr,
                        quantity: openedOrder.goods[curr._id].quantity,
                        price: openedOrder.goods[curr._id].price,
                    });
                }
                return acc;
            }, []);

            setOrderGoods([...updatedOrderGoods]);
        } catch (error) {}
    }, [orders, openedOrder]);

    const calculateTotalQuantity = useCallback(
        (orderGoods: {
            [key: string]: {
                quantity: number;
                price: number;
            };
        }) => {
            return Object.values(orderGoods).reduce(
                (sum: number, curr) => (sum += curr.quantity),
                0,
            );
        },
        [orders],
    );

    function getStatusString(order: Order): string {
        return contextTranslation.Order.statuses[
            OrderStatus[order.status].toLowerCase()
        ];
    }

    const calculateTotalPrice = useCallback(
        (order: Order) => {
            const totalPrice = Object.values(order.goods).reduce(
                (sum: number, curr) => (sum += curr.quantity * curr.price),
                0,
            );

            if (order.promocode?.value) {
                return totalPrice - (totalPrice * order.promocode.value) / 100;
            }
            return totalPrice;
        },
        [orders],
    );

    function isShowControls(order: Order): boolean {
        return (
            order.status !== OrderStatus.CONFIRMED &&
            order.status !== OrderStatus.CANCELED
        );
    }

    function isShowConfirmButton(order: Order): boolean {
        return (
            (order.status === OrderStatus.NEW &&
                order.paymentType == PaymentType.CASH) ||
            order.status === OrderStatus.PAYED
        );
    }

    function handlerOpen(order: Order): void {
        setOpenedOrder(prev => (prev?._id === order._id ? undefined : order));
    }

    async function update(order: Order, status: OrderStatus): Promise<void> {
        try {
            if (!order._id) {
                throw 'id is undefined';
            }

            await OrderService.update(order._id, 'status', status);

            setOrders(prevOrders =>
                prevOrders.map(_order =>
                    _order._id === order._id ? { ...order, status } : _order,
                ),
            );
        } catch (error) {
            console.log('Confirmation error: ', error);
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.message.notEdited,
                ),
            );
        }
    }

    async function cancel(order: Order): Promise<void> {
        try {
            if (!order._id) {
                throw 'id is undefined';
            }

            await OrderService.cancel(order._id);

            setOrders(prevOrders =>
                prevOrders.map(_order =>
                    _order._id === order._id
                        ? { ...order, status: OrderStatus.CANCELED }
                        : _order,
                ),
            );
        } catch (error) {
            console.log('Confirmation error: ', error);
            setContextNotification(prev =>
                NotificationService.add(
                    prev,
                    NotificationType.ERROR,
                    texts.message.notEdited,
                ),
            );
        }
    }

    return (
        <div className="orders">
            {orders.length ? (
                <>
                    <div className="orders_list">
                        {orders.map(order => (
                            <div
                                className={`orders_list_item ${order._id === openedOrder?._id ? 'opened' : ''}`}
                                onClick={() => handlerOpen(order)}
                                key={`user order ${order._id}`}>
                                <div className="orders_list_item_summary">
                                    <div className="orders_list_item_summary_column">
                                        <p>{order.name}</p>
                                        <p>{order.phone}</p>
                                        <p className="orders_list_item_summary_column_quantity">
                                            {calculateTotalQuantity(
                                                order.goods,
                                            )}{' '}
                                            {texts.items}
                                        </p>
                                    </div>
                                    <div className="orders_list_item_summary_column">
                                        <p className="orders_list_item_summary_column_number">
                                            {order.number
                                                ? `#${order.number}`
                                                : ''}
                                        </p>
                                        <p
                                            className={`${OrderStatus[
                                                order.status
                                            ].toLowerCase()} ${order.status === OrderStatus.NEW && order.paymentType !== PaymentType.CASH ? 'warning' : ''}`}>
                                            {getStatusString(order)}
                                        </p>
                                        <p>
                                            {new Date(
                                                order.time,
                                            ).toLocaleString()}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className="orders_list_item_details"
                                    onClick={event => event.stopPropagation()}>
                                    <div className="orders_list_item_details_delivery-payment">
                                        <div className="orders_list_item_details_delivery-payment_column">
                                            <p>
                                                {
                                                    contextTranslation.Order
                                                        .deliveryService[
                                                        DeliveryServiceType[
                                                            order
                                                                .deliveryService
                                                        ].toLowerCase()
                                                    ]
                                                }
                                            </p>
                                            <p>{order.warehouse}</p>
                                        </div>

                                        <div className="orders_list_item_details_delivery-payment_column">
                                            <p>
                                                {
                                                    contextTranslation.Order
                                                        .payment[
                                                        PaymentType[
                                                            order.paymentType
                                                        ].toLowerCase()
                                                    ]
                                                }
                                            </p>
                                            <p>
                                                UAH{' '}
                                                {order.paymentSum.toFixed(2)}
                                                {order.paymentSum <
                                                calculateTotalPrice(order)
                                                    ? `/${calculateTotalPrice(order).toFixed(2)}`
                                                    : ''}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="orders_list_item_details_goods-list">
                                        {orderGoods.map(good => (
                                            <div
                                                className="orders_list_item_details_goods-list_item"
                                                key={`user order ${order._id} good ${good._id}`}>
                                                <figure>
                                                    {good.media[0].includes(
                                                        'data:video/',
                                                    ) ? (
                                                        <video
                                                            src={good.media[0]}
                                                            autoPlay
                                                            muted></video>
                                                    ) : (
                                                        <img
                                                            src={`${good.media[0]}`}
                                                        />
                                                    )}
                                                </figure>

                                                <div className="orders_list_item_details_goods-list_item_description">
                                                    <h4>
                                                        {good.manufacturer.toUpperCase()}
                                                    </h4>
                                                    <p>
                                                        {
                                                            good.title[
                                                                language as keyof typeof good.title
                                                            ]
                                                        }
                                                    </p>
                                                </div>

                                                <p className="orders_list_item_details_goods-list_item_price">
                                                    {good.quantity}
                                                </p>

                                                <h4>
                                                    UAH{' '}
                                                    {(
                                                        good.price *
                                                        good.quantity
                                                    ).toFixed(2)}
                                                </h4>
                                            </div>
                                        ))}
                                    </div>

                                    {isShowControls(order) ? (
                                        <div className="orders_list_item_details_controls">
                                            <Button
                                                type={ButtonType.OUTLINED}
                                                label={texts.buttons.cancel}
                                                size={ButtonSize.BIG}
                                                handlerClick={() =>
                                                    cancel(order)
                                                }
                                            />

                                            {isShowConfirmButton(order) ? (
                                                <Button
                                                    type={ButtonType.OUTLINED}
                                                    label={
                                                        texts.buttons.confirm
                                                    }
                                                    size={ButtonSize.BIG}
                                                    handlerClick={() =>
                                                        update(
                                                            order,
                                                            OrderStatus.CONFIRMED,
                                                        )
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {isShowPaginationButton ? (
                        <div className="catalogue_pagination">
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadOrders}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <h4 className="orders_empty">{texts.empty}</h4>
            )}
        </div>
    );
}
