import { IconType } from '@app/types/image.type';
import './SocialMedia.scss';
import { useContext, useMemo } from 'react';
import { AppInfoContext } from '@app/contexts/appInfoContext';

export default function SocialMedia() {
    const { contextAppInfo } = useContext(AppInfoContext);

    const socialMedias = useMemo(
        () => [
            {
                url: contextAppInfo.facebook,
                image: IconType.FACEBOOK,
                alt: 'Facebook link',
            },
            {
                url: contextAppInfo.instagram,
                image: IconType.INSTAGRAM,
                alt: 'Instagram link',
            },
            {
                url: contextAppInfo.telegram,
                image: IconType.TELEGRAM,
                alt: 'Telegram link',
            },
            {
                url: contextAppInfo.viber,
                image: IconType.VIBER,
                alt: 'Viber link',
            },
        ],
        [contextAppInfo],
    );

    return (
        <div className="socialMedia">
            {socialMedias.map(socialMedia =>
                socialMedia.url ? (
                    <a
                        href={`${socialMedia.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={socialMedia.alt}>
                        <socialMedia.image />
                    </a>
                ) : (
                    ''
                ),
            )}
        </div>
    );
}
