import { useState } from 'react';
import './ImageScroller.scss';
import Slider from 'react-slick';
import { SliderSettings } from '@app/types/slick-slider.type';

export default function ImageScroller({ media }: { media: string[] }) {
    const [current, setCurrent] = useState(0);

    const slidesCount = media.length > 4 ? 4 : media.length;
    const settings: SliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: slidesCount,
        swipeToSlide: true,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                },
            },
        ],
    };

    function choose(i: number): void {
        setCurrent(i);
    }

    return (
        <>
            {media.length ? (
                <div className="imageScroller">
                    <div className="imageScroller_scroller">
                        <Slider {...settings}>
                            {media.length
                                ? media.map((source, i) => (
                                      <figure
                                          key={`scroller source #${i}`}
                                          onClick={() => choose(i)}>
                                          {source.includes('data:video/') ? (
                                              <video
                                                  src={source}
                                                  autoPlay
                                                  muted></video>
                                          ) : (
                                              <img src={`${source}`} />
                                          )}
                                      </figure>
                                  ))
                                : ''}
                        </Slider>
                    </div>

                    <figure className="imageScroller_current">
                        {media[current].includes('data:video/') ? (
                            <video src={media[current]} autoPlay muted></video>
                        ) : (
                            <img src={`${media[current]}`} />
                        )}
                    </figure>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
