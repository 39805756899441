/** @format */

import { CheckboxProps } from '@app/types/checkbox.type';
import './Checkbox.scss';
import { useId } from 'react';

export default function Checkbox({ label, isChecked, onChange }: CheckboxProps) {
    const id = useId();

    return (
        <div className="checkbox">
            <input id={id} type="checkbox" defaultChecked={isChecked} onChange={onChange} hidden />
            <label htmlFor={id}>
                <span></span>
                {label}
            </label>
        </div>
    );
}
