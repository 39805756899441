import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './ProjectSettings.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Validator from 'validator';
import Input from '@app/components/common/input/Input';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';

export default function ProjectSettings() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        projectTitle: '',
        projectSubTitle: '',
        phone: '',
        email: '',
        facebook: '',
        instagram: '',
        telegram: '',
        viber: '',
    });

    const texts = useMemo(
        () => contextTranslation.PersonalArea.projectSettings,
        [contextTranslation],
    );
    const isButtonDisabled = useMemo(() => {
        return Object.keys(formData).every(
            key => formData[key] === defaultData[key],
        );
    }, [formData, defaultData]);

    const NotificationService = injectorService.get('NotificationService');
    const AppInfoService = injectorService.get('AppInfoService');

    const projectTitleMinLength = 3;
    const projectTitleMaxLength = 20;
    const projectSubTitleMinTitle = 3;
    const projectSubTitleMaxTitle = 30;
    const socialMediaLinkMinTitle = 0;
    const socialMediaLinkMaxTitle = 100;

    useLayoutEffect(() => {
        loadData();
    }, []);

    async function loadData(): Promise<void> {
        try {
            const data = await AppInfoService.get();
            setFormData({ ...data });
            setDefaultData({ ...data });
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }
    }, [formData]);

    const verifyData = useCallback((): boolean => {
        const {
            projectTitle,
            projectSubTitle,
            phone,
            email,
            facebook,
            instagram,
            telegram,
            viber,
        } = formData;

        const errors = [
            {
                value: projectTitle.length,
                minLength: projectTitleMinLength,
                maxLength: projectTitleMaxLength,
                message: texts.messages.titleLength,
            },
            {
                value: projectSubTitle.length,
                minLength: projectSubTitleMinTitle,
                maxLength: projectSubTitleMaxTitle,
                message: texts.messages.subTitleLength,
            },
            {
                value: facebook.length,
                minLength: socialMediaLinkMinTitle,
                maxLength: socialMediaLinkMaxTitle,
                message: texts.messages.facebookLength,
            },
            {
                value: instagram.length,
                minLength: socialMediaLinkMinTitle,
                maxLength: socialMediaLinkMaxTitle,
                message: texts.messages.instagramLength,
            },
            {
                value: telegram.length,
                minLength: socialMediaLinkMinTitle,
                maxLength: socialMediaLinkMaxTitle,
                message: texts.messages.telegramLength,
            },

            {
                value: viber.length,
                minLength: socialMediaLinkMinTitle,
                maxLength: socialMediaLinkMaxTitle,
                message: texts.messages.viberLength,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                showNotification(NotificationType.ERROR, message);
                isError = true;
            }
        }
        
        if (!Validator.isMobilePhone(phone)) {
            showNotification(
                NotificationType.ERROR,
                texts.messages.incorrectPhone,
            );
            isError = true;
        }

        if (!Validator.isEmail(email)) {
            showNotification(
                NotificationType.ERROR,
                texts.messages.incorrectEmail,
            );
            isError = true;
        }

        return isError;
    }, [formData, texts.messages, showNotification]);

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const updatedFields: { [key: string]: any } = {};

                for (let key in formData) {
                    const originalValue: any =
                        defaultData[key as keyof typeof defaultData];

                    if (!originalValue) {
                        continue;
                    }

                    const newValue = formData[key];
                    if (originalValue !== newValue) {
                        updatedFields[key] = newValue;
                    }
                }

                for (const [key, value] of Object.entries(updatedFields)) {
                    await AppInfoService.set(key, value);
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );
            } catch (error) {
                console.log('Edit good error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [verifyData, formData, showNotification, texts.messages],
    );

    const formFields = [
        { key: 'projectTitle', placeholder: texts.placeholders.title },
        { key: 'projectSubTitle', placeholder: texts.placeholders.subTitle },
        { key: 'phone', placeholder: texts.placeholders.phone },
        { key: 'email', placeholder: texts.placeholders.email },
        { key: 'facebook', placeholder: 'Facebook' },
        { key: 'instagram', placeholder: 'Instagram' },
        { key: 'telegram', placeholder: 'Telegram' },
        { key: 'viber', placeholder: 'Viber' },
    ];

    return (
        <div className="projectSettings">
            <div className="projectSettings_form">
                {formFields.map(field =>
                    field.key === 'phone' ? (
                        <Input
                            key={`admin field ${field.key}`}
                            type="phone"
                            value={formData[field.key]}
                            onChange={(value: string) =>
                                handleChange(field.key, value)
                            }
                        />
                    ) : (
                        <Input
                            key={`admin field ${field.key}`}
                            value={formData[field.key]}
                            onChange={(value: string) =>
                                handleChange(field.key, value)
                            }
                            placeholder={field.placeholder}
                        />
                    ),
                )}
                <Button
                    disabled={isButtonDisabled}
                    label={texts.buttons.edit}
                    type={ButtonType.OUTLINED}
                    handlerClick={edit}
                />
            </div>
        </div>
    );
}
