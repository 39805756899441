/** @format */

export interface iAppInfo {
    projectTitle: string;
    projectSubTitle: string;
    phone: string;
    email: string;
    facebook: string;
    instagram: string;
    telegram: string;
    viber: string;
}

export const AppInfoInitialData: iAppInfo = Object.freeze({
    projectTitle: '',
    projectSubTitle: '',
    phone: '',
    email: '',
    facebook: '',
    instagram: '',
    telegram: '',
    viber: '',
});
