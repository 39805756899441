import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Nav.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import {
    AdminPersonalAreaScreenType,
    ScreenPath,
    UserPersonalAreaScreenType,
} from '@app/types/screen.type';
import { IconType } from '@app/types/image.type';
import { useNavigate } from 'react-router-dom';
import injectorService from '@app/services/injector.service';
import { UserContext } from '@app/contexts/userContext';
import { UserRole } from '@app/types/user.type';

export default function Nav({
    isAdmin,
    activeScreen,
    changeScreen,
}: {
    isAdmin: boolean;
    activeScreen: UserPersonalAreaScreenType | AdminPersonalAreaScreenType;
    changeScreen: (
        screen: UserPersonalAreaScreenType | AdminPersonalAreaScreenType,
    ) => void;
}) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextUser } = useContext(UserContext);

    const [isShowCoursesTab, setIsShowCoursesTab] = useState(false);

    const texts = useMemo(
        () => contextTranslation.PersonalArea,
        [contextTranslation],
    );

    const tabs = useMemo<
        {
            icon: React.FC<React.SVGProps<SVGSVGElement>>;
            label: string;
            screenType?:
                | AdminPersonalAreaScreenType
                | UserPersonalAreaScreenType;
            command?: Function;
        }[]
    >(() => {
        const baseTabs = isAdmin
            ? [
                  {
                      icon: IconType.PRODUCTS,
                      label: texts.tabs.goods,
                      screenType: AdminPersonalAreaScreenType.GOODS,
                  },
                  {
                      icon: IconType.COG,
                      label: texts.tabs.projectSettings,
                      screenType: AdminPersonalAreaScreenType.PROJECT_SETTINGS,
                  },
                  {
                      icon: IconType.ORDERS,
                      label: texts.tabs.orders,
                      screenType: AdminPersonalAreaScreenType.ORDERS,
                  },
                  {
                      icon: IconType.ARTICLES,
                      label: texts.tabs.articles,
                      screenType: AdminPersonalAreaScreenType.ARTICLES,
                  },
                  {
                      icon: IconType.BOOK,
                      label: texts.tabs.courses,
                      screenType: AdminPersonalAreaScreenType.COURSES,
                  },
                  {
                      icon: IconType.SALE,
                      label: texts.tabs.promocodes,
                      screenType: AdminPersonalAreaScreenType.PROMOCODES,
                  },
                  {
                      icon: IconType.MANUFACTURER,
                      label: texts.tabs.manufacturers,
                      screenType: AdminPersonalAreaScreenType.MANUFACTURERS,
                  },
                  {
                      icon: IconType.PERSON,
                      label: texts.tabs.settings,
                      screenType: AdminPersonalAreaScreenType.SETTINGS,
                  },
                  {
                      icon: IconType.DOOR,
                      label: texts.tabs.logout,
                      command: () => {},
                  },
              ]
            : [
                  {
                      icon: IconType.COG,
                      label: texts.tabs.settings,
                      screenType: UserPersonalAreaScreenType.SETTINGS,
                  },
                  {
                      icon: IconType.ORDERS,
                      label: texts.tabs.orders,
                      screenType: UserPersonalAreaScreenType.ORDERS,
                  },
                  {
                      icon: IconType.DOOR,
                      label: texts.tabs.logout,
                      command: () => {},
                  },
              ];

        if (isShowCoursesTab) {
            baseTabs.splice(2, 0, {
                icon: IconType.BOOK,
                label: texts.tabs.courses,
                screenType: UserPersonalAreaScreenType.COURSES,
            });
        }

        return baseTabs;
    }, [isAdmin, isShowCoursesTab, texts]);

    const navigate = useNavigate();

    const UserService = injectorService.get('UserService');
    const CoursesService = injectorService.get('CoursesService');

    useLayoutEffect(() => {
        if (!contextUser._id || contextUser.role !== UserRole.DEFAULT) {
            return;
        }

        loadCourses();
    }, [contextUser]);

    const loadCourses = useCallback(async () => {
        try {
            const data = await CoursesService.get({
                from: 0,
                to: 1,
                userId: contextUser._id,
            });

            if (data.length) {
                setIsShowCoursesTab(true);
            }
        } catch (error) {
            console.log("Courses weren't loaded: ", error);
        }
    }, [contextUser]);

    function handleClick(
        screenType?: AdminPersonalAreaScreenType | UserPersonalAreaScreenType,
    ): void {
        if (screenType !== undefined) {
            changeScreen(screenType);
        } else {
            UserService.removeToken();
            navigate(`/${ScreenPath.HOME}`);
        }
    }

    return (
        <>
            <div className="personalArea_left_user-info">
                {contextUser.media ? (
                    <figure>
                        <img src={contextUser.media} />
                    </figure>
                ) : (
                    ''
                )}
                <h4>{contextUser.name}</h4>
            </div>
            <nav>
                {tabs.map((tab, i) => (
                    <div
                        className={`nav_item ${activeScreen === tab.screenType ? 'active' : ''}`}
                        onClick={() => handleClick(tab.screenType)}
                        key={`personal area nav tab #${i}`}>
                        <tab.icon />
                        <h4>{tab.label}</h4>
                    </div>
                ))}
            </nav>
        </>
    );
}
