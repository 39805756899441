import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Articles.scss';
import injectorService from '@app/services/injector.service';
import { LanguageType } from '@app/types/language.type';
import { TranslationContext } from '@app/contexts/translationContext';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { NotificationContext } from '@app/contexts/notificationContext';
import { Article } from '@app/types/article.type';
import { NotificationType } from '@app/types/notification.type';
import Input from '@app/components/common/input/Input';
import ArticleConstructor from '@app/components/common/article-constructor/ArticleConstructor';

enum FormMode {
    HIDDEN,
    ADD,
    EDIT,
}

export default function Articles() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [showForm, setShowForm] = useState(FormMode.HIDDEN);
    const [articles, setArticles] = useState<Article[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);
    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        titleEn: '',
        titleUa: '',
        titleRu: '',
        contentEn: '',
        contentUa: '',
        contentRu: '',
    });
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(
        LanguageType.EN,
    );

    const ArticleService = injectorService.get('ArticleService');
    const TranslationService = injectorService.get('TranslationService');
    const NotificationService = injectorService.get('NotificationService');

    const texts = useMemo(
        () => contextTranslation.PersonalArea.articles,
        [contextTranslation],
    );
    const language = useMemo(
        () =>
            LanguageType[TranslationService.language].toLowerCase() as
                | 'ua'
                | 'en'
                | 'ru',
        [TranslationService.language],
    );
    const isShowForm = useMemo(
        () => showForm === FormMode.ADD || showForm === FormMode.EDIT,
        [showForm],
    );
    const isButtonDisabled = useMemo(() => {
        return (
            (showForm === FormMode.ADD &&
                Object.keys(formData).some(
                    key => key !== '_id' && formData[key] === '',
                )) ||
            (showForm === FormMode.EDIT &&
                Object.keys(formData).every(
                    key => formData[key] === defaultData[key],
                ))
        );
    }, [showForm, formData, defaultData]);

    const paginationLength = 8;
    const titleMinLength = 5;
    const titleMaxLength = 50;
    const contentMinLength = 20;
    const contentMaxLength = 1000000;
    const languages = Object.values(LanguageType).filter(
        language => typeof language === 'string',
    );

    useLayoutEffect(() => {
        loadArticles();
    }, []);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    async function loadArticles(): Promise<void> {
        try {
            const data = await ArticleService.get({
                from: articles.length,
                to: articles.length + paginationLength,
            });

            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }

            setArticles([...articles, ...data]);
        } catch (error) {}
    }

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }
    }, [formData]);

    const verifyData = useCallback((): boolean => {
        const { titleEn, titleUa, titleRu, contentEn, contentUa, contentRu } =
            formData;

        const errors = [
            {
                value: titleEn.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.en,
            },
            {
                value: titleUa.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.ua,
            },
            {
                value: titleRu.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength.ru,
            },
            {
                value: contentEn.length,
                minLength: contentMinLength,
                maxLength: contentMaxLength,
                message: texts.messages.contentLength.en,
            },
            {
                value: contentUa.length,
                minLength: contentMinLength,
                maxLength: contentMaxLength,
                message: texts.messages.contentLength.ua,
            },
            {
                value: contentRu.length,
                minLength: contentMinLength,
                maxLength: contentMaxLength,
                message: texts.messages.contentLength.ru,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                showNotification(NotificationType.ERROR, message);
                isError = true;
            }
        }

        return isError;
    }, [formData]);

    const add = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const newArticle = await ArticleService.add({
                    title: {
                        en: formData.titleEn,
                        ua: formData.titleUa,
                        ru: formData.titleRu,
                    },
                    content: {
                        en: formData.contentEn,
                        ua: formData.contentUa,
                        ru: formData.contentRu,
                    },
                });

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.added,
                );

                clearFields();
                setArticles([...articles, newArticle]);
            } catch (error) {
                console.log('Add course error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notAdded,
                );
            }
        },
        [
            verifyData,
            formData,
            articles,
            ArticleService,
            showNotification,
            texts.messages,
        ],
    );

    const clearFields = useCallback(() => {
        for (let key in formData) {
            formData[key] = '';
        }
    }, [formData]);

    const setUpEditData = useCallback(
        async (_id?: string) => {
            try {
                if (!_id) {
                    return console.log('Error edit article');
                }

                const article = articles.find(article => article._id === _id);

                if (!article) {
                    return console.log('Error edit article');
                }

                const newData = {
                    _id,
                    titleEn: article.title.en,
                    titleUa: article.title.ua,
                    titleRu: article.title.ru,
                    contentEn: article.content.en,
                    contentUa: article.content.ua,
                    contentRu: article.content.ru,
                };

                setFormData({ ...newData });
                setDefaultData({ ...newData });

                setShowForm(FormMode.EDIT);
            } catch (error) {
                console.log('Error loading course data:', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notLoaded,
                );
            }
        },
        [articles, formData],
    );

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const articleToEdit = articles.find(
                    article => article._id === formData._id,
                );
                if (!articleToEdit) {
                    throw "Article wasn't found";
                }

                formData.title = {
                    en: formData.titleEn,
                    ua: formData.titleUa,
                    ru: formData.titleRu,
                };
                formData.content = {
                    en: formData.contentEn,
                    ua: formData.contentUa,
                    ru: formData.contentRu,
                };

                const updatedFields: { [key: string]: any } = {};

                for (let key in articleToEdit) {
                    const originalValue: any =
                        articleToEdit[key as keyof typeof articleToEdit];

                    if (!originalValue) {
                        continue;
                    }

                    const newValue = formData[key];
                    if (key === 'title' || key === 'content') {
                        if (
                            !Object.keys(originalValue).every(
                                (objectKey: string) =>
                                    originalValue[objectKey] ===
                                    newValue[objectKey],
                            )
                        ) {
                            updatedFields[key] = newValue;
                        }
                    } else if (originalValue !== newValue) {
                        updatedFields[key] = newValue;
                    }
                }

                for (const [key, value] of Object.entries(updatedFields)) {
                    await ArticleService.update(formData._id, key, value);
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );
                clearFields();
                setShowForm(FormMode.HIDDEN);

                setArticles([]);
                setTimeout(() => {
                    loadArticles();
                }, 100);
            } catch (error) {
                console.log('Edit course error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [formData, articles],
    );

    const remove = useCallback(async (_id?: string) => {
        try {
            if (!_id) {
                throw '_id is undefined';
            }

            await ArticleService.remove(_id);
            setArticles(prev => prev.filter(article => article._id !== _id));
            showNotification(NotificationType.SUCCESS, texts.messages.removed);
        } catch (error) {
            console.log('Remove article error: ', error);
            showNotification(NotificationType.ERROR, texts.messages.notRemoved);
        }
    }, []);

    return (
        <div className="articles">
            <p
                className="articles_show-hide"
                onClick={() =>
                    setShowForm(isShowForm ? FormMode.HIDDEN : FormMode.ADD)
                }>
                {isShowForm ? texts.buttons.hide : texts.buttons.show}
            </p>
            <form className={`articles_form ${isShowForm ? 'show' : ''}`}>
                <Input
                    value={formData.titleEn}
                    onChange={(value: string) => handleChange('titleEn', value)}
                    placeholder={texts.placeholders.title.en}
                />
                <Input
                    value={formData.titleUa}
                    onChange={(value: string) => handleChange('titleUa', value)}
                    placeholder={texts.placeholders.title.ua}
                />
                <Input
                    value={formData.titleRu}
                    onChange={(value: string) => handleChange('titleRu', value)}
                    placeholder={texts.placeholders.title.ru}
                />

                <div className="articles_form_languageSelector">
                    {languages.map(language => (
                        <div key={`languageSelector ${language}`}>
                            <Button
                                label={language}
                                type={
                                    selectedLanguage ===
                                    LanguageType[
                                        language as keyof typeof LanguageType
                                    ]
                                        ? undefined
                                        : ButtonType.OUTLINED
                                }
                                handlerClick={event => {
                                    event.preventDefault();
                                    setSelectedLanguage(
                                        LanguageType[
                                            language as keyof typeof LanguageType
                                        ],
                                    );
                                }}
                            />
                        </div>
                    ))}
                </div>

                {selectedLanguage === LanguageType.EN ? (
                    <ArticleConstructor
                        value={formData.contentEn}
                        onChange={(value: string) =>
                            handleChange('contentEn', value)
                        }
                    />
                ) : selectedLanguage === LanguageType.UA ? (
                    <ArticleConstructor
                        value={formData.contentUa}
                        onChange={(value: string) =>
                            handleChange('contentUa', value)
                        }
                    />
                ) : (
                    <ArticleConstructor
                        value={formData.contentRu}
                        onChange={(value: string) =>
                            handleChange('contentRu', value)
                        }
                    />
                )}

                <Button
                    label={
                        showForm === FormMode.ADD
                            ? texts.buttons.add
                            : texts.buttons.edit
                    }
                    disabled={isButtonDisabled}
                    type={ButtonType.OUTLINED}
                    handlerClick={showForm === FormMode.ADD ? add : edit}
                />
            </form>

            {articles.length ? (
                <>
                    <div className="articles_list">
                        {articles.map(article => (
                            <div
                                className="articles_list_item"
                                key={`admin article ${article._id}`}>
                                <div className="articles_list_item_description">
                                    <h4>{article.title[language]}</h4>
                                    <p>
                                        {article.content[language].slice(0, 50)}
                                    </p>
                                </div>
                                <div className="articles_list_item_controls">
                                    <Button
                                        type={ButtonType.OUTLINED}
                                        label={texts.buttons.edit}
                                        handlerClick={() =>
                                            setUpEditData(article._id)
                                        }
                                    />
                                    <Button
                                        type={ButtonType.OUTLINED}
                                        label={texts.buttons.remove}
                                        handlerClick={() => remove(article._id)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    {isShowPaginationButton ? (
                        <div>
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadArticles}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <h4 className="articles_empty">{texts.empty}</h4>
            )}
        </div>
    );
}
