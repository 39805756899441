/** @format */

export enum NotificationType {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export interface Notification {
    id: number;
    type: NotificationType;
    message: string;
    showTime?: number;
    timeoutId?: number;
}
