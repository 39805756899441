/** @format */

import { iUserService, iWebSocketService } from '@app/types/injector.type';
import { User, UserRole } from '@app/types/user.type';
import injectorService from './injector.service';

const urls = Object.freeze({
    GET_ONE: 'get-one',
    GET_ALL: 'get-all',
    REGISTER: 'register',
    LOGIN: 'login',
    CHECK: 'check',
    UPDATE: 'update',
    RESTORE: 'restore',
});

export default class UserService implements iUserService {
    private source: string;
    private websocket!: iWebSocketService;
    private readonly localstorageTokenKey = 'Token';
    private id: string = '-1';

    constructor() {
        this.source = 'user';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async getOne(): Promise<User> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET_ONE}`,
                data: {
                    _id: this.id,
                },
            });

            if (!success) {
                throw "User wasn't got";
            }

            return data;
        } catch (error) {
            throw `UserService getOne error: ${error}`;
        }
    }

    public async getAll(): Promise<User[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET_ALL}`,
            });

            if (!success) {
                throw "Users weren't got";
            }

            return data;
        } catch (error) {
            throw `UserService getAll error: ${error}`;
        }
    }

    public async register(email: string, password: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.REGISTER}`,
                data: { email, password },
            });

            if (!success) {
                throw "Member wasn't registered";
            }

            localStorage.setItem(this.localstorageTokenKey, data);
        } catch (error) {
            throw `UserService login error: ${error}`;
        }
    }

    public async login(email: string, password: string): Promise<User> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.LOGIN}`,
                data: { email, password },
            });

            if (!success || !data) {
                throw '[401]: Not Authorized';
            }

            localStorage.setItem(this.localstorageTokenKey, data.token);

            this.id = data._id;

            return data;
        } catch (error) {
            throw `UserService login error: ${error}`;
        }
    }

    public async checkToken(): Promise<User> {
        try {
            const token = localStorage.getItem(this.localstorageTokenKey);

            if (!token) {
                throw 'Token is empty';
            }

            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.CHECK}`,
                data: { token },
            });

            if (!success || !data) {
                throw '[401]: Not Authorized';
            }

            this.id = data._id;

            return data;
        } catch (error) {
            throw `UserService checkToken error: ${error}`;
        }
    }

    public removeToken(): void {
        localStorage.setItem(this.localstorageTokenKey, '');
    }

    public async update(key: string, value: string): Promise<void> {
        try {
            const { success } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.UPDATE}`,
                data: {
                    _id: this.id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw "data wasn't edited";
            }
        } catch (error) {
            throw `UserService update error: ${error}`;
        }
    }

    public async restore(email: string): Promise<void> {
        try {
            const { success } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.RESTORE}`,
                data: { email },
            });

            if (!success) {
                throw "data wasn't edited";
            }
        } catch (error) {
            throw `UserService update error: ${error}`;
        }
    }
}
