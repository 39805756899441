import { Good } from '@app/types/good.type';
import Card from '../card/Card';
import './GoodsList.scss';

export default function GoodsList({ list }: { list: Good[] }) {
    return (
        <div className="goodsList">
            {list.map(good => (
                <Card data={good} key={good._id} />
            ))}
        </div>
    );
}
