/** @format */

import { iAppInfo } from '@app/types/app-info.type';
import { iAppInfoService, iWebSocketService } from '@app/types/injector.type';
import injectorService from './injector.service';

const urls = Object.freeze({
    GET: 'get',
    SET: 'set',
});

export default class AppInfoService implements iAppInfoService {
    private source: string;
    private id: string = '-1';
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'project';
        this.websocket = injectorService.get('WebSocketService');
    }

    async get(): Promise<iAppInfo> {
        try {
            const response = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
            });

            if (!response.success) {
                throw response.data;
            }

            const {
                _id,
                projectTitle,
                projectSubTitle,
                phone,
                email,
                facebook,
                instagram,
                telegram,
                viber,
            } = response.data;

            if (
                !_id ||
                !projectTitle ||
                !projectSubTitle ||
                !phone ||
                !email
            ) {
                throw 'Not all data provided';
            }

            this.id = _id;

            return {
                projectTitle,
                projectSubTitle,
                phone,
                email,
                facebook,
                instagram,
                telegram,
                viber,
            };
        } catch (error) {
            throw `AppInfoService get error: ${error}`;
        }
    }

    async set(key: string, value: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.SET}`,
                data: {
                    _id: this.id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `AppInfoService set error: ${error}`;
        }
    }
}
