/** @format */

import { iSeoService } from '@app/types/injector.type';

const urls = Object.freeze({
    GET_ALL: 'get-all',
    REGISTER: 'register',
    LOGIN: 'login',
    CHECK: 'check',
    UPDATE: 'update',
    RESTORE: 'restore',
});

export default class SeoService implements iSeoService {
    constructor() {}

    public update(data: { title: string; description: string }): void {
        const { title, description } = data;

        document.title = title;

        const metaOgTitle = document.querySelector('meta[property="og:title"]');
        metaOgTitle?.setAttribute('content', title);

        const metaTwitterTitle = document.querySelector(
            'meta[name="twitter:title"]',
        );
        metaTwitterTitle?.setAttribute('content', title);

        const metaDescription = document.querySelector(
            'meta[name="description"]',
        );
        metaDescription?.setAttribute('content', description);

        const metaOgDescription = document.querySelector(
            'meta[property="og:description"]',
        );        
        metaOgDescription?.setAttribute('content', description);

        const metaTwitterDescription = document.querySelector(
            'meta[name="twitter:description"]',
        );
        metaTwitterDescription?.setAttribute('content', description);
    }
}
