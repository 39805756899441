/** @format */

import sendRequest from '@app/api/baseAPI';
import { iOrderService, iWebSocketService } from '@app/types/injector.type';
import {
    CityData,
    DeliveryServiceType,
    Order,
    PaymentType,
    Warehouse,
} from '@app/types/order.type';
import injectorService from './injector.service';
import { Promocode } from '@app/types/promocode.type';

const API_URL = 'https://api.novaposhta.ua/v2.0/json/';

const urls = Object.freeze({
    ADD: 'add',
    GET: 'get',
    SET: 'set',
    CANCEL: 'cancel',
    GET_LINK: 'getLink',
});

export default class OrderService implements iOrderService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'order';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async getCities(value: string): Promise<CityData[]> {
        try {
            const response = await sendRequest<{
                success: boolean;
                data: CityData[];
                errors: string[];
            }>(API_URL, '', {
                apiKey: '8c94ae70dca120ea1357e4bc219f4ff1',
                modelName: 'Address',
                calledMethod: 'getCities',
                methodProperties: {
                    FindByString: value,
                },
            });

            const { success, data, errors } = response;

            if (!success) {
                throw errors.join(';');
            }

            return data;
        } catch (error) {
            throw `OrderService getCities error: ${error}`;
        }
    }

    public async getWareHouses(cityId: string): Promise<Warehouse[]> {
        try {
            const response = await sendRequest<{
                success: boolean;
                data: Warehouse[];
                errors: string[];
            }>(API_URL, '', {
                apiKey: '8c94ae70dca120ea1357e4bc219f4ff1',
                modelName: 'Address',
                calledMethod: 'getWarehouses',
                methodProperties: {
                    CityRef: cityId,
                },
            });

            const { success, data, errors } = response;

            if (!success) {
                throw errors.join(';');
            }

            return data;
        } catch (error) {
            throw `OrderService getWareHouses error: ${error}`;
        }
    }

    public async add({
        goods,
        name,
        phone,
        email,
        deliveryService,
        warehouse,
        paymentType,
        paymentSum,
        promocode,
    }: {
        goods: {
            [key: string]: {
                quantity: number;
                price: number;
            };
        };
        name: string;
        phone: string;
        email: string;
        deliveryService: DeliveryServiceType;
        warehouse: string;
        paymentType: PaymentType;
        paymentSum?: number;
        promocode?: Promocode;
    }): Promise<string> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: {
                    goods,
                    name,
                    phone,
                    email,
                    deliveryService,
                    warehouse,
                    paymentType,
                    paymentSum,
                    promocode,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `OrderService add error: ${error}`;
        }
    }

    public async get({
        from,
        to,
        userId,
    }: {
        from?: number;
        to?: number;
        userId?: string;
    }): Promise<Order[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: {
                    from,
                    to,
                    userId,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `OrderService get error: ${error}`;
        }
    }

    public async update(_id: string, key: string, value: any): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.SET}`,
                data: {
                    _id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw data;
            }
        } catch (error) {
            throw `OrderService set error: ${error}`;
        }
    }

    public async getLink(_id: string, amount: string): Promise<string> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET_LINK}`,
                data: {
                    _id,
                    amount,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `OrderService getLink error: ${JSON.stringify(error)}`;
        }
    }

    public async cancel(_id: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.CANCEL}`,
                data: { _id },
            });

            if (!success) {
                throw data;
            }
        } catch (error) {
            throw `OrderService cancel error: ${error}`;
        }
    }
}
