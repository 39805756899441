import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './Notifications.scss';
import { NotificationContext } from '@app/contexts/notificationContext';
import { Notification } from '@app/types/notification.type';

export default function Notifications() {
    const { contextNotification, setContextNotification } =
        useContext(NotificationContext);

    const [portal, setPortal] = useState<HTMLDivElement | undefined>(undefined);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const notificationsPortal = 'portal-notifications';
    const notificationsMaxCount = 5;
    const expirationTime = 5;

    useEffect(() => {
        if (contextNotification.length) {
            const newNotifications = contextNotification.slice(
                0,
                notificationsMaxCount,
            );

            if (!portal) {
                const newPortal = document.createElement('div');
                newPortal.classList.add(notificationsPortal);
                setPortal(newPortal);
                document.body.appendChild(newPortal);
            }

            const uniqueNotifications = newNotifications.filter(
                newNotification => !notifications.includes(newNotification),
            );

            uniqueNotifications.forEach(notificationWithoutTimeout => {
                setTimeout(() => {
                    setContextNotification(prevContextNotification =>
                        prevContextNotification.filter(
                            notification =>
                                notification !== notificationWithoutTimeout,
                        ),
                    );
                }, expirationTime * 1000);
            });

            setNotifications(newNotifications);
        } else if (portal) {
            document.body.removeChild(portal);
            setPortal(undefined);
        }
    }, [contextNotification]);

    if (!portal) return null;

    return ReactDOM.createPortal(
        <>
            {contextNotification
                .slice(0, notificationsMaxCount)
                .map((notification, i) => (
                    <div
                        className={`notification type-${notification.type}`}
                        key={`notification #${i}`}>
                        {notification.message}
                    </div>
                ))}
        </>,
        portal,
    );
}
