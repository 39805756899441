/** @format */

import { InputRadioProps } from '@app/types/input.type';
import './InputRadio.scss';

export default function InputRadio({
    name,
    label,
    value,
    onChange,
    isSelected
}: InputRadioProps) {
    function handlerChange(newValue: any): void {
        if (onChange) {
            onChange(newValue);
        }
    }

    return (
        <div className="inputRadio">
            <label>
                <input
                    defaultChecked={isSelected}
                    name={name}
                    type="radio"
                    value={value}
                    onChange={({ target }) => handlerChange(target.value)}
                />
                {label}
            </label>
        </div>
    );
}
