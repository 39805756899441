import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Home.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import SocialMedia from '@app/components/common/social-media/SocialMedia';
import Button from '@app/components/common/button/Button';
import { Link } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import { IconType, ImageType } from '@app/types/image.type';
import { AppInfoContext } from '@app/contexts/appInfoContext';
import Card from '@app/components/common/card/Card';
import { Good, SortType } from '@app/types/good.type';
import injectorService from '@app/services/injector.service';
import { LoaderContext } from '@app/contexts/loaderContext';
import { SliderSettings } from '@app/types/slick-slider.type';
import Slider from 'react-slick';

export default function Home() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextLoader } = useContext(LoaderContext);
    const { contextAppInfo } = useContext(AppInfoContext);

    const [news, setNews] = useState<Good[]>([]);
    const [makeup, setMakeup] = useState<Good[]>([]);
    const [hair, setHair] = useState<Good[]>([]);
    const [care, setCare] = useState<Good[]>([]);

    const texts = useMemo(() => contextTranslation.Home, [contextTranslation]);
    const isShowLoader = useMemo(
        () => !news.length || !makeup.length || !hair.length || !care.length,
        [contextAppInfo, news, makeup, hair, care],
    );

    const GoodService = injectorService.get('GoodService');

    const CustomNextArrow = (props: any) => {
        const { className, onClick } = props;
        return <IconType.ARROW_RIGHT className={className} onClick={onClick} />;
    };

    const CustomPrevArrow = (props: any) => {
        const { className, onClick } = props;
        return <IconType.ARROW_LEFT className={className} onClick={onClick} />;
    };
    const settings: SliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        swipeToSlide: false,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    swipeToSlide: true,
                },
            },
        ],
    };

    useLayoutEffect(() => {
        getNews();
        getMakeup();
        getHair();
        getCare();
    }, []);

    useLayoutEffect(() => {
        const isShow = !contextAppInfo.projectTitle || isShowLoader;

        setContextLoader({ show: isShow });
    }, [isShowLoader, contextAppInfo]);

    const getNews = useCallback(async (): Promise<void> => {
        try {
            const firstPart = await GoodService.get({
                from: 0,
                to: 4,
                type: 'news',
            });

            setNews(firstPart);

            setTimeout(async () => {
                const secondPart = await GoodService.get({
                    from: 4,
                    to: 12,
                    type: 'news',
                });
                
                setNews(prev => [...prev, ...secondPart]);
            }, 1500);
        } catch (error) {
            console.error("Products (News) weren't loaded");
        }
    }, [news]);

    async function getMakeup(): Promise<void> {
        try {
            const data = await GoodService.get({
                from: 0,
                to: 4,
                sortBy: SortType.RATE_TOP,
                type: 'makeup',
            });

            setMakeup(data);
        } catch (error) {
            console.error("Products (Makeup) weren't loaded");
        }
    }

    async function getHair(): Promise<void> {
        try {
            const data = await GoodService.get({
                from: 0,
                to: 4,
                sortBy: SortType.RATE_TOP,
                type: 'hair',
            });
            setHair(data);
        } catch (error) {
            console.error("Products (Hair) weren't loaded");
        }
    }

    async function getCare(): Promise<void> {
        try {
            const data = await GoodService.get({
                from: 0,
                to: 4,
                sortBy: SortType.RATE_TOP,
                type: 'care',
            });

            setCare(data);
        } catch (error) {
            console.error("Products (Care) weren't loaded");
        }
    }

    return (
        <div className="home">
            <div className="home_header container">
                <div className="home_header_text">
                    <h1>{contextAppInfo.projectTitle}</h1>
                    <h2>{contextAppInfo.projectSubTitle}</h2>
                    <h4>{texts.assortment}</h4>

                    <figure className="home_header_text_image_block">
                        <div className="home_header_text_image_block_controls">
                            <Link to={`/${ScreenPath.CATALOGUE}`}>
                                <Button
                                    label={
                                        contextTranslation.Buttons.goCatalogue
                                    }
                                />
                            </Link>
                        </div>
                    </figure>
                </div>

                <SocialMedia />
            </div>

            <section className="container news">
                <h2 className="section_title">{texts.sections.news}</h2>
                <div className="news_body">
                    {news.length ? (
                        <Slider {...settings}>
                            {news.map(product => (
                                <Card
                                    data={product}
                                    key={`hair #${product._id}`}
                                />
                            ))}
                        </Slider>
                    ) : (
                        ''
                    )}
                </div>
            </section>

            <section className="container makeup">
                <h2 className="section_title">
                    {contextTranslation.GoodTypes.makeup.title}
                </h2>
                <div className="section_body">
                    <Link to={`/${ScreenPath.CATALOGUE}/makeup`}>
                        <img src={ImageType.MAKEUP} alt="makeup preview" />
                    </Link>
                    <div className="section_body_goods">
                        {makeup.length
                            ? makeup.map(product => (
                                  <Card
                                      data={product}
                                      key={`makeup #${product._id}`}
                                  />
                              ))
                            : ''}
                    </div>
                    <div className="section_body_mobile-text">
                        <p>{texts.sections.mobile.makeup}</p>
                        <Link to={`/${ScreenPath.CATALOGUE}/makeup`}>
                            {contextTranslation.Buttons.goCatalogue}
                        </Link>
                    </div>
                </div>
            </section>

            <section className="container hair">
                <h2 className="section_title">
                    {contextTranslation.GoodTypes.hair.title}
                </h2>
                <div className="section_body">
                    <div className="section_body_goods">
                        {hair.length
                            ? hair.map(product => (
                                  <Card
                                      data={product}
                                      key={`hair #${product._id}`}
                                  />
                              ))
                            : ''}
                    </div>
                    <div className="section_body_mobile-text">
                        <p>{texts.sections.mobile.hair}</p>
                        <Link to={`/${ScreenPath.CATALOGUE}/hair`}>
                            {contextTranslation.Buttons.goCatalogue}
                        </Link>
                    </div>
                    <Link to={`/${ScreenPath.CATALOGUE}/hair`}>
                        <img src={ImageType.HAIR} alt="hair preview" />
                    </Link>
                </div>
            </section>

            <section className="container care">
                <h2 className="section_title">
                    {contextTranslation.GoodTypes.care.title}
                </h2>
                <div className="section_body">
                    <Link to={`/${ScreenPath.CATALOGUE}/care`}>
                        <img src={ImageType.CARE} alt="care preview" />
                    </Link>
                    <div className="section_body_goods">
                        {care.length
                            ? care.map(product => (
                                  <Card
                                      data={product}
                                      key={`care #${product._id}`}
                                  />
                              ))
                            : ''}
                    </div>
                    <div className="section_body_mobile-text">
                        <p>{texts.sections.mobile.care}</p>
                        <Link to={`/${ScreenPath.CATALOGUE}/care`}>
                            {contextTranslation.Buttons.goCatalogue}
                        </Link>
                    </div>
                </div>
            </section>

            <section className="container blog_section">
                <h2 className="section_title">{texts.sections.blog.title}</h2>
                <div className="blog_section_body">
                    <div className="blog_section_body_info">
                        <h4>{texts.sections.blog.subTitle}</h4>
                        <p>{texts.sections.blog.description}</p>
                        <Link to={`/${ScreenPath.BLOG}`}>
                            <Button label={contextTranslation.Buttons.goBlog} />
                        </Link>
                    </div>
                    <img src={ImageType.BLOG} alt="blog preview" />
                </div>
            </section>
        </div>
    );
}
