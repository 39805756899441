import {
    forwardRef,
    useCallback,
    useContext,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Filter.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { GenderType, Good, SortType } from '@app/types/good.type';
import { SelectItem } from '@app/types/select.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import injectorService from '@app/services/injector.service';
import { LoaderContext } from '@app/contexts/loaderContext';
import { AppInfoContext } from '@app/contexts/appInfoContext';
import Checkbox from '../checkbox/Checkbox';
import { ManufacturersContext } from '@app/contexts/manufacturersContext';
import RadioButton from '../radiobutton/Radiobutton';
import Select from '../select/Select';
import Button from '../button/Button';
import { ButtonType } from '@app/types/button.type';
import { IconType } from '@app/types/image.type';

const Filter = forwardRef(
    (
        {
            goods,
            setFilteredGoods,
            setIsShowPaginationButton,
        }: {
            goods: Good[];
            setFilteredGoods: Function;
            setIsShowPaginationButton: Function;
        },
        ref,
    ) => {
        useImperativeHandle(ref, () => ({
            loadData: async () => {
                await setLoadingStarted(true);
            },
        }));

        const { setContextLoader } = useContext(LoaderContext);
        const { contextTranslation } = useContext(TranslationContext);
        const { contextAppInfo } = useContext(AppInfoContext);
        const { contextManufacturers } = useContext(ManufacturersContext);

        const TranslationService = injectorService.get('TranslationService');

        const [isMobileFilterOpened, setIsMobileFilterOpened] = useState(false);
        const [loadingStarted, setLoadingStarted] = useState(true);
        const [lastQuery, setLastQuery] = useState<{
            type: string;
            subType: string;
            subSubType: string;
            manufacturers: string[];
            sex?: GenderType;
            sortBy: SortType;
        }>({
            type: '',
            subType: '',
            subSubType: '',
            manufacturers: [],
            sortBy: SortType.RATE_TOP,
        });
        const isMobileScreen = useMemo(
            () => !window.matchMedia('(min-width: 601px)').matches,
            [window],
        );
        const sortVariants = useMemo(
            () => [
                {
                    name: contextTranslation.Filter.rateTop,
                    value: SortType.RATE_TOP,
                },
                {
                    name: contextTranslation.Filter.priceLow,
                    value: SortType.PRICE_LOW,
                },
                {
                    name: contextTranslation.Filter.priceTop,
                    value: SortType.PRICE_TOP,
                },
                // {
                //     name: contextTranslation.Filter.saleLow,
                //     value: SortType.SALE_LOW,
                // },
                {
                    name: contextTranslation.Filter.saleTop,
                    value: SortType.SALE_TOP,
                },
            ],
            [contextTranslation],
        );

        const [sortBy, setSortBy] = useState(sortVariants[0]);
        const [manufacturers, setManufacturers] = useState<string[]>([]);
        const sexs: SelectItem[] = useMemo(
            () => [
                {
                    name: contextTranslation.Filter.all,
                    value: undefined,
                },
                {
                    name: contextTranslation.Filter.woman,
                    value: GenderType.WOMAN,
                },
                { name: contextTranslation.Filter.man, value: GenderType.MAN },
            ],
            [contextTranslation, TranslationService.language],
        );
        const [sex, setSex] = useState(sexs[0]);

        const { id } = useParams();
        const location = useLocation();
        const navigate = useNavigate();

        const texts = useMemo(
            () => contextTranslation.GoodTypes,
            [contextTranslation],
        );

        const GoodService = injectorService.get('GoodService');

        const paginationLength = 8;

        const goodTypes = Object.keys(texts).map(key => ({
            name: texts[key].title,
            value: key,
        }));
        const [type, setType] = useState<SelectItem | undefined>();
        const [openedType, setOpenedType] = useState<SelectItem | undefined>();
        const goodSubTypes = useMemo(() => {
            if (!type) {
                return [];
            }
            const currentType = texts[type.value];
            if (!currentType || !currentType.subTypes) return [];
            return Object.keys(currentType.subTypes).map(key => ({
                name:
                    typeof currentType.subTypes[key] === 'string'
                        ? currentType.subTypes[key]
                        : currentType.subTypes[key].title || '',
                value: key,
            }));
        }, [texts, type]);
        const [subType, setSubType] = useState<SelectItem | undefined>();
        const [openedSubType, setOpenedSubType] = useState<
            SelectItem | undefined
        >();
        const goodSubSubTypes = useMemo(() => {
            if (!type || !subType) {
                return [];
            }
            const currentType = texts[type.value];
            const currentSubType = currentType?.subTypes?.[subType.value];
            if (
                !currentSubType ||
                typeof currentSubType === 'string' ||
                !currentSubType.subTypes
            )
                return [];
            return Object.keys(currentSubType.subTypes).map(key => ({
                name: currentSubType.subTypes[key],
                value: key,
            }));
        }, [texts, type, subType]);
        const [subSubType, setSubSubType] = useState<SelectItem | undefined>();
        const [isFirstChange, setIsFirstChange] = useState(false);
        useLayoutEffect(() => {
            if (!id) {
                return;
            }

            if (location.pathname.includes('manufacturer')) {
                if (!isFirstChange) {
                    setManufacturers([id]);
                    setIsFirstChange(true);
                }
            } else {
                if (!goodTypes.length) {
                    return;
                }

                const foundType = goodTypes.find(
                    goodType => goodType.value === id,
                );

                if (foundType && !isFirstChange) {
                    setType(foundType);
                    setIsFirstChange(true);
                }
            }
        }, [location, id, goodTypes, isFirstChange]);

        useLayoutEffect(() => {
            if (!loadingStarted) {
                return;
            }

            loadData();
        }, [loadingStarted]);

        useLayoutEffect(() => {
            if (location.pathname.includes('manufacturer') && !isFirstChange) {
                return;
            }

            setLoadingStarted(true);
        }, [sex, sortBy, manufacturers, isFirstChange, location]);

        useLayoutEffect(() => {
            setSortBy(
                prevSortBy =>
                    sortVariants.find(v => v.value === prevSortBy?.value) ||
                    sortVariants[0],
            );
        }, [sortVariants]);

        useLayoutEffect(() => {
            if (!contextAppInfo.projectTitle || !contextManufacturers.length) {
                return setContextLoader({ show: true });
            }

            setContextLoader({ show: loadingStarted });
        }, [contextAppInfo, loadingStarted, contextManufacturers]);

        function filterTypeClick(event: any, type: SelectItem): void {
            event.stopPropagation();

            handlerSetType(type);

            if (!isMobileScreen) {
                setOpenedType(type);
            } else {
                setType(type);
                setLoadingStarted(true);
            }
        }

        function handlerSetType(item: SelectItem): void {
            // setSubType(undefined);
            // setSubSubType(undefined);

            if (isMobileScreen) {
                return setType(item);
            }

            if (openedType?.value === item.value) {
                setType(undefined);
                setOpenedType(undefined);
            } else {
                setType(item);
                setOpenedType(item);

                if (!texts[item.value].subTypes) {
                    setLoadingStarted(true);
                }
            }
        }

        function filterSubTypeClick(event: any, subType: SelectItem): void {
            event.stopPropagation();

            handlerSetSubType(subType);
            setOpenedSubType(subType);
        }

        function handlerSetSubType(item: SelectItem): void {
            // setSubSubType(undefined);

            if (openedSubType?.value === item.value) {
                setSubType(undefined);
                setOpenedSubType(undefined);
            } else {
                setSubType(item);
                setOpenedSubType(item);

                if (!texts[type?.value].subTypes[item.value].subTypes) {
                    setLoadingStarted(true);
                }
            }
        }

        function filterSubSubTypeClick(
            event: any,
            subSubType: SelectItem,
        ): void {
            event.stopPropagation();
            handlerSetSubSubType(subSubType);
        }

        function handlerSetSubSubType(item: SelectItem): void {
            setSubSubType(item);
            setLoadingStarted(true);
        }

        const loadData = useCallback(async () => {
            const isLoadMore =
                type?.value === lastQuery.type &&
                subType?.value === lastQuery.subType &&
                subSubType?.value === lastQuery.subSubType &&
                manufacturers === lastQuery.manufacturers &&
                sex.value === lastQuery.sex &&
                sortBy.value === lastQuery.sortBy;
            const from = isLoadMore ? goods.length : 0;

            try {
                console.log({
                    from: from,
                    to: from + paginationLength,
                    type: type?.value,
                    subType: subType?.value,
                    subSubType: subSubType?.value,
                    manufacturers,
                    sex: sex.value,
                    sortBy: sortBy.value,
                });

                const data = await GoodService.get({
                    from: from,
                    to: from + paginationLength,
                    type: type?.value,
                    subType: subType?.value,
                    subSubType: subSubType?.value,
                    manufacturers,
                    sex: sex.value,
                    sortBy: sortBy.value,
                });

                if (isLoadMore) {
                    setFilteredGoods([...goods, ...data]);
                } else {
                    setFilteredGoods([...data]);
                }

                setLastQuery({
                    type: type?.value,
                    subType: subType?.value,
                    subSubType: subSubType?.value,
                    manufacturers,
                    sex: sex.value,
                    sortBy: sortBy.value,
                });

                setIsShowPaginationButton(data.length === paginationLength);
                close();
            } catch (error) {
                console.error('loadData exceprion: ', error);
            } finally {
                setLoadingStarted(false);
            }
        }, [
            goods,
            lastQuery,
            type,
            subType,
            subSubType,
            manufacturers,
            sex,
            sortBy,
        ]);

        function close(): void {
            setOpenedType(undefined);
            setOpenedSubType(undefined);
        }

        const handlerCheck = useCallback(
            (event: any, type: string, value: string) => {
                const { checked } = event.target;
                value = value.toLowerCase();

                if (checked) {
                    if (type === 'manufacturer') {
                        if (!manufacturers.includes(value)) {
                            setManufacturers([...manufacturers, value]);
                        }
                    } else {
                    }
                } else {
                    if (type === 'manufacturer') {
                        if (manufacturers.includes(value)) {
                            const newManufacturers = manufacturers.filter(
                                manufacturer => manufacturer !== value,
                            );

                            setManufacturers([...newManufacturers]);
                        }
                    } else {
                    }
                }
            },
            [manufacturers],
        );

        const handlerSelectRadio = useCallback((value: SelectItem) => {
            setSex(value);
        }, []);

        return (
            <>
                <div className="filter">
                    <div className="container filter_categories">
                        {goodTypes.map(goodType => (
                            <h4
                                className={`${openedType?.value === goodType.value ? 'active' : ''}`}
                                onClick={event =>
                                    filterTypeClick(event, goodType)
                                }
                                key={`filter type ${goodType.value}`}>
                                {goodType.name}
                            </h4>
                        ))}
                    </div>
                    <div
                        className={`filter_subcategories ${openedType && goodSubTypes.length ? 'active' : ''}`}>
                        {goodSubTypes.length
                            ? Array.from({ length: 4 }, (_, colIndex) => (
                                  <div
                                      className="filter_subcategories_column"
                                      key={`column-${colIndex}`}>
                                      {goodSubTypes
                                          .filter((_, index) => {
                                              const perColumn = Math.ceil(
                                                  goodSubTypes.length / 4,
                                              );
                                              return (
                                                  index >=
                                                      colIndex * perColumn &&
                                                  index <
                                                      (colIndex + 1) * perColumn
                                              );
                                          })
                                          .map(goodSubType => (
                                              <div
                                                  className="filter_subcategories_column_item"
                                                  onClick={event =>
                                                      filterSubTypeClick(
                                                          event,
                                                          goodSubType,
                                                      )
                                                  }
                                                  key={`filter subcategories ${goodSubType.value}`}>
                                                  <div className="filter_subcategories_column_item_value">
                                                      <span>
                                                          {openedType &&
                                                          texts[
                                                              openedType.value
                                                          ].subTypes[
                                                              goodSubType.value
                                                          ].subTypes
                                                              ? openedSubType?.value ===
                                                                goodSubType.value
                                                                  ? '-'
                                                                  : '+'
                                                              : ''}
                                                      </span>
                                                      <span>
                                                          {goodSubType.name}
                                                      </span>
                                                  </div>
                                                  {openedType &&
                                                  texts[openedType.value]
                                                      .subTypes[
                                                      goodSubType.value
                                                  ].subTypes ? (
                                                      <div
                                                          className={`filter_subcategories_column_item_subTypes ${goodSubType.value === openedSubType?.value ? 'opened' : ''}`}>
                                                          {goodSubType.value ===
                                                              openedSubType?.value &&
                                                              goodSubSubTypes.map(
                                                                  subSubType => (
                                                                      <div
                                                                          className="filter_subcategories_column_item_subTypes_item"
                                                                          onClick={event =>
                                                                              filterSubSubTypeClick(
                                                                                  event,
                                                                                  subSubType,
                                                                              )
                                                                          }
                                                                          key={`goodSubSubTypes ${subSubType.value}`}>
                                                                          <span>
                                                                              {
                                                                                  subSubType.name
                                                                              }
                                                                          </span>
                                                                      </div>
                                                                  ),
                                                              )}
                                                      </div>
                                                  ) : (
                                                      ''
                                                  )}
                                              </div>
                                          ))}
                                  </div>
                              ))
                            : ''}
                    </div>
                </div>
                <div className="filter-controls">
                    <div className="filter-controls_mobile-filters">
                        <Button
                            label={contextTranslation.Filter.filters}
                            type={ButtonType.OUTLINED}
                            handlerClick={() => setIsMobileFilterOpened(true)}
                        />
                    </div>

                    <div className="filter-controls_sorting">
                        <h4>{contextTranslation.Filter.sorting}</h4>
                        <Select
                            selectedItem={sortBy}
                            itemsList={sortVariants}
                            onChange={setSortBy}
                        />
                    </div>

                    <div className="filter-controls_manufacturers">
                        <h4>{contextTranslation.Filter.manufacturers}</h4>
                        {contextManufacturers.length
                            ? contextManufacturers.map((manufacturer, i) => (
                                  <Checkbox
                                      label={manufacturer}
                                      onChange={event => {
                                          handlerCheck(
                                              event,
                                              'manufacturer',
                                              manufacturer,
                                          );
                                      }}
                                      isChecked={
                                          id?.toLowerCase() ===
                                          manufacturer.toLowerCase()
                                      }
                                      key={`filter-controls_manufacturers #${i}`}
                                  />
                              ))
                            : ''}
                    </div>

                    <div className="filter-controls_sex">
                        <h4>{contextTranslation.Filter.sex}</h4>
                        {sexs.map((sexItem, i) => (
                            <RadioButton
                                label={sexItem.name}
                                isSelected={sex.value === sexItem.value}
                                onChange={() => handlerSelectRadio(sexItem)}
                                key={`filter-controls_sexs #${i}`}
                            />
                        ))}
                    </div>

                    <div
                        className={`filter-controls_mobile ${isMobileFilterOpened ? 'open' : ''}`}>
                        <IconType.CROSS
                            onClick={() => setIsMobileFilterOpened(false)}
                        />

                        <div className="filter-controls_mobile_manufacturers">
                            <h4>{contextTranslation.Filter.manufacturers}</h4>
                            {contextManufacturers.length
                                ? contextManufacturers.map(
                                      (manufacturer, i) => (
                                          <Checkbox
                                              label={manufacturer}
                                              onChange={event => {
                                                  handlerCheck(
                                                      event,
                                                      'manufacturer',
                                                      manufacturer,
                                                  );
                                              }}
                                              isChecked={
                                                  id?.toLowerCase() ===
                                                  manufacturer.toLowerCase()
                                              }
                                              key={`filter-controls_mobile_manufacturers #${i}`}
                                          />
                                      ),
                                  )
                                : ''}
                        </div>

                        <div className="filter-controls_mobile_sex">
                            <h4>{contextTranslation.Filter.sex}</h4>
                            {sexs.map((sexItem, i) => (
                                <RadioButton
                                    label={sexItem.name}
                                    isSelected={sex.value === sexItem.value}
                                    onChange={() => handlerSelectRadio(sexItem)}
                                    key={`filter-controls_mobile_sexs #${i}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    },
);

export default Filter;
