/** @format */

export enum UserRole {
    GUEST,
    DEFAULT,
    ADMIN,
}

export interface User {
    _id: string;
    email: string;
    phone: string;
    name: string;
    media: string;
    password?: string;
    role: UserRole;
}

export const InitialUserData: User = Object.freeze({
    _id: '',
    email: '',
    phone: '',
    name: '',
    media: '',
    password: '',
    role: UserRole.GUEST,
});

export interface LoginResponse {
    token: string;
}

export enum LoginStatus {
    OK = 'OK',
    ERROR = 'ERROR',
}
