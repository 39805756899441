/** @format */

import { TranslatableVariable } from './language.type';
import { Promocode } from './promocode.type';

export enum DeliveryServiceType {
    OWN,
    NOVA,
    UKR,
    MEEST,
}

export interface CityData {
    Ref: string;
    Description: string;
    AreaDescription: string;
}

export interface Warehouse {
    Description: string;
}

export enum PaymentType {
    CARD,
    CASH,
    ONLY_DELIVERY,
}

export enum OrderStatus {
    NEW,
    PAYED,
    CONFIRMED,
    CANCELED,
}

export interface Order {
    _id?: string;
    number: number;
    name: string;
    phone: string;
    email: string;
    deliveryService: DeliveryServiceType;
    warehouse: string;
    paymentType: PaymentType;
    paymentSum: number;
    promocode?: Promocode;
    goods: {
        [key: string]: {
            quantity: number;
            price: number;
        };
    };
    status: OrderStatus;
    time: Date;
}
