/** @format */

import { InputProps } from '@app/types/input.type';
import './InputFile.scss';
import Button from '../button/Button';
import { ButtonType } from '@app/types/button.type';
import { useContext, useMemo, useRef } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import { ImageTypes, VideoTypes } from '@app/types/image.type';

export default function InputFile({ types, onChange, customRef }: InputProps) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextNotification, setContextNotification } =
        useContext(NotificationContext);

    const texts = useMemo(
        () => contextTranslation.Inputs.messages,
        [contextTranslation],
    );

    const inputRef = useRef<HTMLInputElement | null>(null);

    const NotificationService = injectorService.get('NotificationService');

    const maxFileSize = {
        image: 20000000,
        video: 200000000,
    };

    const getMaxFileSize = (extension: string): number | null => {
        if (ImageTypes.includes(extension as (typeof ImageTypes)[number])) {
            return maxFileSize.image;
        }
        if (VideoTypes.includes(extension as (typeof VideoTypes)[number])) {
            return maxFileSize.video;
        }
        return null;
    };

    function clickInput(): void {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    function handlerChange(event: any): void {
        if (onChange) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }

            const extension = `.${file.name.split('.').pop().toLowerCase()}`;
            const exit = (message: string) => {
                event.target.value = '';
                return showError(message);
            };

            const maxSize = getMaxFileSize(extension);
            if (!maxSize || !types?.includes(extension)) {
                return exit(`[${extension}] ${texts.extension}`);
            }

            if (file.size > maxSize) {
                return exit(texts.fileSize);
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result?.toString();

                if (!base64String) {
                    return exit(texts.fileRead);
                }
                
                onChange(base64String);
            };

            reader.onerror = error => {
                console.error(texts.fileRead + ': ', error);
                showError(texts.fileRead);
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function showError(message: string): void {
        setContextNotification(
            NotificationService.add(
                contextNotification,
                NotificationType.ERROR,
                message,
            ),
        );
    }

    return (
        <div className="inputFile" ref={customRef} onClick={clickInput}>
            <input
                ref={inputRef}
                accept={types?.join(', ')}
                hidden
                type="file"
                onChange={handlerChange}
            />
            <Button
                type={ButtonType.OUTLINED}
                label={contextTranslation.Buttons.inputFile}
                handlerClick={event => event.preventDefault()}
            />
        </div>
    );
}
