import { AuthScreenType } from '@app/types/screen.type';
import './SignUp.scss';
import Input from '@app/components/common/input/Input';
import { useContext, useMemo, useState } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import Button from '@app/components/common/button/Button';
import BackButton from '@app/components/common/back-button/BackButton';
import { NotificationContext } from '@app/contexts/notificationContext';
import { NotificationType } from '@app/types/notification.type';
import Validator from 'validator';
import injectorService from '@app/services/injector.service';

export default function SignUp({ changeScreen }: { changeScreen: Function }) {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextNotification, setContextNotification } =
        useContext(NotificationContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const minPasswordLength = 8;
    const maxPasswordLength = 20;

    const texts = useMemo(() => contextTranslation.Auth, [contextTranslation]);

    const NotificationService = injectorService.get('NotificationService');
    const UserService = injectorService.get('UserService');

    async function register(): Promise<void> {
        try {
            const isError = verifyData();
            if (isError) {
                return;
            }

            await sendData();
            
            goConfirm();
        } catch (error) {
            console.log('Registration error: ', error);
            setContextNotification(
                NotificationService.add(
                    contextNotification,
                    NotificationType.ERROR,
                    texts.error.registration,
                ),
            );
        }
    }

    function verifyData(): boolean {
        let isError = false;

        if (!Validator.isEmail(email)) {
            setContextNotification(
                NotificationService.add(
                    contextNotification,
                    NotificationType.ERROR,
                    texts.error.email,
                ),
            );
            isError = true;
        }

        if (
            password.length < minPasswordLength ||
            password.length > maxPasswordLength ||
            confirmPassword.length < minPasswordLength ||
            confirmPassword.length > maxPasswordLength
        ) {
            setContextNotification(
                NotificationService.add(
                    contextNotification,
                    NotificationType.ERROR,
                    texts.error.passwordLength,
                ),
            );
            isError = true;
        }

        if (password !== confirmPassword) {
            setContextNotification(
                NotificationService.add(
                    contextNotification,
                    NotificationType.ERROR,
                    texts.error.confirmPassword,
                ),
            );
            isError = true;
        }

        return isError;
    }

    async function sendData(): Promise<void> {
        await UserService.register(email, password);
    }

    function goLogin(): void {
        changeScreen(AuthScreenType.DEFAULT);
    }

    function goConfirm(): void {
        changeScreen(AuthScreenType.CONFIRM);
    }

    return (
        <div className="signUp">
            <div className="signIn_inputs">
                <Input
                    value={email}
                    onChange={setEmail}
                    placeholder={texts.email}
                />
                <Input
                    type="password"
                    value={password}
                    onChange={setPassword}
                    placeholder={texts.password}
                />
                <Input
                    type="password"
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    placeholder={texts.confirmPassword}
                />

                <BackButton handlerClick={goLogin} />
            </div>

            <Button label={texts.buttons.register} handlerClick={register} />
        </div>
    );
}
