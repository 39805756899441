import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import './PersonalArea.scss';
import Nav from './components/common/nav/Nav';
import {
    AdminPersonalAreaScreenType,
    ScreenPath,
    UserPersonalAreaScreenType,
} from '@app/types/screen.type';
import UserOrders from './components/user/orders/Orders';
import AdminOrders from './components/admin/orders/Orders';
import Settings from './components/common/settings/Settings';
import ProjectSettings from './components/admin/project-settings/ProjectSettings';
import Goods from './components/admin/goods/Goods';
import { UserContext } from '@app/contexts/userContext';
import { UserRole } from '@app/types/user.type';
import { useNavigate } from 'react-router-dom';
import injectorService from '@app/services/injector.service';
import AdminCourses from './components/admin/courses/Courses';
import UserCourses from './components/user/courses/Courses';
import Articles from './components/admin/articles/Articles';
import Promocodes from './components/admin/promocodes/Promocodes';
import Manufacturers from './components/admin/manufacturers/Manufacturers';

enum DirectionType {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export default function PersonalArea() {
    const { contextUser, setContextUser } = useContext(UserContext);

    const userRole = useMemo(() => contextUser.role, [contextUser]);
    const [state, setState] = useState<{
        activeScreen: UserPersonalAreaScreenType | AdminPersonalAreaScreenType;
        prevScreen: undefined | number;
        direction: DirectionType;
    }>({
        activeScreen: UserPersonalAreaScreenType.SETTINGS,
        prevScreen: undefined,
        direction: DirectionType.BOTTOM,
    });

    const isAdmin = useMemo(
        () => contextUser && contextUser.role > UserRole.DEFAULT,
        [contextUser],
    );

    const navigate = useNavigate();

    const UserService = injectorService.get('UserService');

    useLayoutEffect(() => {
        checkToken();
    }, []);

    useLayoutEffect(() => {
        setState(prevState => ({
            ...prevState,
            activeScreen: isAdmin
                ? AdminPersonalAreaScreenType.GOODS
                : UserPersonalAreaScreenType.SETTINGS,
        }));
    }, [isAdmin]);

    async function checkToken(): Promise<void> {
        try {
            const user = await UserService.checkToken();

            setContextUser(user);

            if (user.role < UserRole.DEFAULT) {
                throw "Account wasn't verified";
            }
        } catch (error) {
            navigate(`/${ScreenPath.AUTH}`, { replace: true });
        }
    }

    const changeScreen = (
        screen: UserPersonalAreaScreenType | AdminPersonalAreaScreenType,
    ) => {
        if (screen === state.activeScreen) {
            return;
        }

        setState(prevState => ({
            prevScreen: prevState.activeScreen,
            activeScreen: screen,
            direction:
                screen > prevState.activeScreen
                    ? DirectionType.TOP
                    : DirectionType.BOTTOM,
        }));
    };

    const renderScreen = (screen: number) => {
        if (userRole === UserRole.DEFAULT) {
            switch (screen) {
                case UserPersonalAreaScreenType.SETTINGS:
                    return <Settings />;
                case UserPersonalAreaScreenType.ORDERS:
                    return <UserOrders />;
                case UserPersonalAreaScreenType.COURSES:
                    return <UserCourses />;
            }
        } else if (userRole === UserRole.ADMIN) {
            switch (screen) {
                case AdminPersonalAreaScreenType.GOODS:
                    return <Goods />;
                case AdminPersonalAreaScreenType.PROJECT_SETTINGS:
                    return <ProjectSettings />;
                case AdminPersonalAreaScreenType.ORDERS:
                    return <AdminOrders />;
                case AdminPersonalAreaScreenType.ARTICLES:
                    return <Articles />;
                case AdminPersonalAreaScreenType.COURSES:
                    return <AdminCourses />;
                case AdminPersonalAreaScreenType.PROMOCODES:
                    return <Promocodes />;
                case AdminPersonalAreaScreenType.MANUFACTURERS:
                    return <Manufacturers />;
                case AdminPersonalAreaScreenType.SETTINGS:
                    return <Settings />;
            }
        }
    };

    if (contextUser.role < UserRole.DEFAULT) {
        return null;
    }

    return (
        <div className="personalArea container">
            <div className="personalArea_left">
                <Nav
                    isAdmin={isAdmin}
                    activeScreen={state.activeScreen}
                    changeScreen={changeScreen}
                />
            </div>
            <div className="personalArea_main">
                {state.prevScreen !== undefined && (
                    <div
                        className={`personalArea_main_slide personalArea_main_slide--exit-${state.direction}`}
                        key={`prev-${state.prevScreen}`}>
                        {renderScreen(state.prevScreen)}
                    </div>
                )}
                <div
                    className={`personalArea_main_slide personalArea_main_slide--enter-${state.direction}`}
                    key={`active-${state.activeScreen}`}>
                    {renderScreen(state.activeScreen)}
                </div>
            </div>
        </div>
    );
}
