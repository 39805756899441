import { IconType } from '@app/types/image.type';
import './Search.scss';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import injectorService from '@app/services/injector.service';
import { TranslationContext } from '@app/contexts/translationContext';
import Input from '../input/Input';
import { LanguageType, TranslatableVariable } from '@app/types/language.type';
import { useNavigate } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';
import { ManufacturersContext } from '@app/contexts/manufacturersContext';

interface SearchItem {
    title: {
        left: TranslatableVariable | string;
        right?: string;
    };
    link: string;
}

export default function Search() {
    const { contextTranslation } = useContext(TranslationContext);
    const { contextManufacturers } = useContext(ManufacturersContext);

    const [isOpened, setIsOpened] = useState(false);
    const [value, setValue] = useState('');
    const [searchResults, setSearchResults] = useState<SearchItem[]>([]);

    const valueMinLength = 2;

    const texts = useMemo(
        () => contextTranslation.Search,
        [contextTranslation],
    );
    const searchStarted = useMemo(
        () => isOpened && value.length > valueMinLength,
        [isOpened, value],
    );

    const navigate = useNavigate();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const TranslationService = injectorService.get('TranslationService');
    const SearchService = injectorService.get('SearchService');
    const GoodService = injectorService.get('GoodService');

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                handlerBlur();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (searchStarted) {
            search();
        }
    }, [searchStarted, value]);

    async function search() {
        setSearchResults([]);

        try {
            const ids = await searchIds();
            await loadGoods(ids);

            const filteredValue = value.replace(/[`‘’]/g, "'");

            const result = contextManufacturers
                .filter(str => new RegExp(filteredValue, 'i').test(str))
                .map(item => ({
                    title: { left: item },
                    link: `/${ScreenPath.CATALOGUE}/manufacturer/${item.toLowerCase()}`,
                }));
            setSearchResults(prev => [...result, ...prev]);
        } catch (error) {
            console.log("Data wasn't find");
        }
    }

    async function searchIds(): Promise<string[]> {
        return SearchService.get('Good', 'title', value);
    }

    const loadGoods = useCallback(
        async (_ids: string[]): Promise<void> => {
            if (!_ids.length) {
                return;
            }

            const data = (await GoodService.get({ _ids })).map(item => ({
                title: {
                    left: item.title,
                    right: (
                        item.price -
                        (item.price * (item.sale?.value || 0)) / 100
                    ).toFixed(2),
                },
                link: `/${ScreenPath.CATALOGUE}/${item.type}/${item._id}`,
            }));

            setSearchResults([...data]);
        },
        [searchResults],
    );

    function goSearchResult(link: string): void {
        handlerBlur();
        setSearchResults([]);
        setValue('');
        navigate(link);
    }

    function handlerOpen(): void {
        setIsOpened(true);

        if (inputRef.current) {
            inputRef.current.focus();
            (document.querySelector('.logo') as HTMLDivElement).classList.add(
                'hidden',
            );
        }
    }

    function handlerBlur(): void {
        setIsOpened(false);
        setSearchResults([]);

        (document.querySelector('.logo') as HTMLDivElement).classList.remove(
            'hidden',
        );
    }

    return (
        <div
            ref={containerRef}
            className={`search ${isOpened ? 'opened' : ''}`}>
            <Input
                customRef={inputRef}
                value={value}
                onChange={setValue}
            />
            <IconType.SEARCH onClick={handlerOpen} />
            <div className={`search_result ${searchStarted ? 'opened' : ''}`}>
                {searchResults.length ? (
                    searchResults.map((searchResult, i) => (
                        <p
                            className="search_result_item"
                            onClick={() => goSearchResult(searchResult.link)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    goSearchResult(searchResult.link);
                                }
                            }}
                            tabIndex={0}
                            key={`search result ${i}`}>
                            <span>
                                {typeof searchResult.title.left === 'object'
                                    ? searchResult.title.left[
                                          language as keyof typeof searchResult.title.left
                                      ]
                                    : searchResult.title.left}
                            </span>
                            <span>{searchResult.title.right}</span>
                        </p>
                    ))
                ) : (
                    <p className="search_result_empty">{texts.empty}</p>
                )}
            </div>
        </div>
    );
}
