/** @format */

import { ButtonProps } from '@app/types/button.type';
import './Button.scss';

export default function Button({
    label,
    disabled,
    size,
    type,
    handlerClick,
}: ButtonProps) {
    return (
        <button
            className={`button ${size ? size : ''} ${type ? type : ''}`}
            disabled={disabled}
            onClick={handlerClick}>
            {label}
        </button>
    );
}
