import {
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import './Settings.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Input from '@app/components/common/input/Input';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import InputFile from '@app/components/common/input-file/InputFile';
import { IconType, ImageTypes } from '@app/types/image.type';
import Validator from 'validator';

export default function Settings() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const texts = useMemo(
        () => contextTranslation.PersonalArea.settings,
        [contextTranslation],
    );

    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });
    const [media, setMedia] = useState('');
    const formFields = [
        { key: 'name', placeholder: texts.placeholders.name },
        { key: 'email', placeholder: texts.placeholders.email },
        { key: 'phone', placeholder: texts.placeholders.phone },
        { key: 'password', placeholder: texts.placeholders.password },
        {
            key: 'confirmPassword',
            placeholder: texts.placeholders.confirmPassword,
        },
    ];

    const NotificationService = injectorService.get('NotificationService');
    const UserService = injectorService.get('UserService');

    const minNameLength = 5;
    const maxNameLength = 30;
    const minPasswordLength = 8;
    const maxPasswordLength = 20;

    const isButtonDisabled = useMemo(() => {
        return (
            Object.keys(formData).every(
                key => formData[key] === defaultData[key],
            ) && defaultData.media === media
        );
    }, [defaultData, formData, media]);

    useLayoutEffect(() => {
        loadData();
    }, []);

    async function loadData(): Promise<void> {
        try {
            const data: any = await UserService.getOne();

            delete data.role;
            delete data._id;
            delete data.__v;

            setFormData({ ...formData, ...data });
            setDefaultData({ ...formData, ...data });
            setMedia(data.media);
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const handleChange = useCallback(
        (field: string, value: string) => {
            setFormData(prev => ({ ...prev, [field]: value }));
        },
        [formData],
    );

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }
    }, [formData]);

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            const { name, phone, password, confirmPassword } = formData;
            const updatedValues: any = {};

            if (media !== defaultData.media) {
                updatedValues['media'] = media;
            }

            if (name !== defaultData.name) {
                if (
                    name.length < minNameLength ||
                    name.length > maxNameLength
                ) {
                    return showNotification(
                        NotificationType.ERROR,
                        texts.messages.nameLength,
                    );
                }
                updatedValues['name'] = name;
            }

            if (phone !== defaultData.phone) {
                if (!Validator.isMobilePhone(phone)) {
                    return showNotification(
                        NotificationType.ERROR,
                        texts.messages.incorrectPhone,
                    );
                }
                updatedValues['phone'] = phone;
            }

            if (password) {
                if (
                    password.length < minPasswordLength ||
                    password.length > maxPasswordLength
                ) {
                    return showNotification(
                        NotificationType.ERROR,
                        texts.messages.passwordLength,
                    );
                }
                updatedValues['password'] = password;
            }

            if (confirmPassword) {
                if (password !== confirmPassword) {
                    return showNotification(
                        NotificationType.ERROR,
                        texts.messages.incorrectConfirm,
                    );
                }
                clearFields();
            }

            try {
                for (let key in updatedValues) {
                    await UserService.update(key, updatedValues[key]);
                    setDefaultData(prev => ({
                        ...prev,
                        [key]: updatedValues[key],
                    }));
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );
            } catch (error) {
                console.log('Edit good error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [defaultData, formData, media, showNotification, texts.messages],
    );

    function clearFields(): void {
        handleChange('password', '');
        handleChange('confirmPassword', '');
    }

    const addMedia = useCallback(
        (value: string) => {
            setMedia(value);
        },
        [media],
    );

    const removeMedia = useCallback(() => {
        setMedia('');
    }, [media]);

    return (
        <div className="settings">
            <div className="settings_form">
                {media ? (
                    <figure className="settings_form_media">
                        <img src={`${media}`} />
                        <div className="media_controls">
                            <IconType.CROSS onClick={removeMedia} />
                        </div>
                    </figure>
                ) : (
                    ''
                )}

                <div className="settings_form_inputs">
                    {media ? (
                        ''
                    ) : (
                        <InputFile
                            types={[...ImageTypes]}
                            onChange={addMedia}
                        />
                    )}

                    {formFields.map(field => (
                        <Input
                            key={`admin settings field ${field.key}`}
                            isReadOnly={field.key === 'email'}
                            value={formData[field.key]}
                            onChange={(value: string) =>
                                handleChange(field.key, value)
                            }
                            type={
                                field.key === 'password' ||
                                field.key === 'confirmPassword'
                                    ? 'password'
                                    : field.key === 'phone'
                                      ? 'phone'
                                      : 'text'
                            }
                            placeholder={field.placeholder}
                        />
                    ))}
                </div>

                <Button
                    disabled={isButtonDisabled}
                    label={texts.buttons.edit}
                    type={ButtonType.OUTLINED}
                    handlerClick={edit}
                />
            </div>
        </div>
    );
}
