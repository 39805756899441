/** @format */

export enum LanguageType {
    EN,
    UA,
    RU,
}

export interface TranslatableVariable {
    en: string;
    ua: string;
    ru: string;
}
