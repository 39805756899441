/** @format */

import { iCartListService } from '@app/types/injector.type';

export default class CartListService implements iCartListService {
    private readonly localStorageKey = 'CartList';

    public getData(): { [key: string]: number } {
        const key = this.localStorageKey;

        const localStorageData = localStorage.getItem(key);

        if (!localStorageData) {
            return {};
        }

        const dataList = JSON.parse(localStorageData);
        if (!Object.keys(dataList).length) {
            return {};
        }

        return dataList;
    }

    public setData(newList: { [key: string]: number }): void {
        const key = this.localStorageKey;
        localStorage.setItem(key, JSON.stringify(newList));
    }
}
