import { useState, useRef, useEffect } from 'react';
import './Select.scss';
import { SelectItem, SelectProps } from '@app/types/select.type';

export default function Select({
    isDisabled,
    itemsList,
    selectedItem,
    onChange,
    ...args
}: SelectProps) {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);    

    const getOptionLabel = (option: SelectItem | string | number): string => {
        if (!option) {
            console.error('Option is undefined');
            return '';
        }
        return typeof option === 'string' || typeof option === 'number'
            ? option.toString()
            : option.name;
    };

    const handlerChange = (event: any, option: any): void => {
        event.stopPropagation();

        if (onChange) {
            onChange(option);
        }
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`select ${isOpen ? 'open' : ''} ${
                isDisabled || !itemsList.length ? 'disabled' : ''
            }`}
            ref={selectRef}
            onClick={() => !isDisabled && setIsOpen(prev => !prev)}
            {...args}>
            <div className="select_selected-option">
                <span>{getOptionLabel(selectedItem)}</span>
                <div className={`arrow ${isOpen ? 'open' : ''}`}></div>
            </div>
            <div className="select_options-list">
                {itemsList.length
                    ? itemsList.map((option, i) => (
                          <div
                              className={`select_options-list_option ${
                                  getOptionLabel(selectedItem) ===
                                  getOptionLabel(option)
                                      ? 'active'
                                      : ''
                              }`}
                              onClick={event => handlerChange(event, option)}
                              key={`select option #${i}`}>
                              {getOptionLabel(option)}
                          </div>
                      ))
                    : ''}
            </div>
        </div>
    );
}
