import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './Promocodes.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import { NotificationContext } from '@app/contexts/notificationContext';
import injectorService from '@app/services/injector.service';
import { NotificationType } from '@app/types/notification.type';
import Input from '@app/components/common/input/Input';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { Promocode } from '@app/types/promocode.type';

enum FormMode {
    HIDDEN,
    ADD,
    EDIT,
}

export default function Promocodes() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);

    const [showForm, setShowForm] = useState(FormMode.HIDDEN);
    const [defaultData, setDefaultData] = useState<{ [key: string]: any }>({});
    const [formData, setFormData] = useState<{ [key: string]: any }>({
        title: '',
        value: '',
    });
    const [promocodes, setPromocodes] = useState<Promocode[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);

    const texts = useMemo(
        () => contextTranslation.PersonalArea.promocodes,
        [contextTranslation],
    );
    const isShowForm = useMemo(
        () => showForm === FormMode.ADD || showForm === FormMode.EDIT,
        [showForm],
    );
    const isButtonDisabled = useMemo(() => {
        return (
            (showForm === FormMode.ADD &&
                Object.keys(formData).some(key => formData[key] === '')) ||
            (showForm === FormMode.EDIT &&
                Object.keys(formData).every(
                    key => formData[key] === defaultData[key],
                ))
        );
    }, [showForm, formData, defaultData]);

    const NotificationService = injectorService.get('NotificationService');
    const PromocodesService = injectorService.get('PromocodesService');

    const titleMinLength = 3;
    const titleMaxLength = 20;
    const valueMin = 1;
    const valueMax = 99;
    const paginationLength = 8;

    useEffect(() => {
        if (showForm === FormMode.HIDDEN) {
            clearFields();
        }
    }, [showForm]);

    useEffect(() => {
        if (promocodes.length === 0) {
            loadPromocodes();
        }
    }, [promocodes]);

    const loadPromocodes = useCallback(async () => {
        try {
            const data = await PromocodesService.get({
                from: promocodes.length,
                to: promocodes.length + paginationLength,
            });

            setPromocodes([...promocodes, ...data]);
            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }, [promocodes, setPromocodes]);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    const trimValues = useCallback(() => {
        for (let key in formData) {
            formData[key] = formData[key].trim().toLowerCase();
        }
    }, [formData]);

    const verifyData = useCallback((): boolean => {
        const { title, value } = formData;

        const errors = [
            {
                value: title.length,
                minLength: titleMinLength,
                maxLength: titleMaxLength,
                message: texts.messages.titleLength,
            },
            {
                value: Number(value),
                minLength: valueMin,
                maxLength: valueMax,
                message: texts.messages.value,
            },
        ];

        let isError = false;

        for (const { value, minLength, maxLength, message } of errors) {
            if (value < minLength || value > maxLength) {
                showNotification(NotificationType.ERROR, message);
                isError = true;
            }
        }

        return isError;
    }, [formData, texts.messages]);

    const handleChange = useCallback((field: string, value: string) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }, []);

    const add = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const { title, value } = formData;
                const newPromocode = await PromocodesService.add({
                    title: title.toLowerCase(),
                    value: Number(value),
                    active: true,
                });

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.added,
                );

                clearFields();
                setPromocodes([...promocodes, newPromocode]);
            } catch (error) {
                console.log('Add promocode error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notAdded,
                );
            }
        },
        [formData, texts.messages],
    );

    const clearFields = useCallback(() => {
        for (let key in formData) {
            formData[key] = '';
        }
    }, [formData]);

    const setUpEditData = useCallback(
        async (_id: string) => {
            try {
                const promocode = promocodes.find(
                    promocode => promocode._id === _id,
                );

                if (!promocode) {
                    return console.log('Error edit promocode');
                }

                const newData = {
                    _id,
                    title: promocode.title,
                    value: promocode.value,
                };

                setFormData({ ...newData });
                setDefaultData({ ...newData });

                setShowForm(FormMode.EDIT);
            } catch (error) {
                console.log('Error loading promocode data:', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notLoaded,
                );
            }
        },
        [promocodes, formData, texts.messages],
    );

    const activate = useCallback(
        async (_id: string) => {
            try {
                await PromocodesService.set(_id, 'active', true);
                setPromocodes([]);
            } catch (error) {
                console.log('Error promocode activation: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [promocodes],
    );

    const deactivate = useCallback(
        async (_id: string) => {
            try {
                await PromocodesService.set(_id, 'active', false);
                setPromocodes([]);
            } catch (error) {
                console.log('Error promocode deactivation: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [promocodes],
    );

    const edit = useCallback(
        async (event: any) => {
            event.preventDefault();

            trimValues();

            if (verifyData()) {
                return;
            }

            try {
                const promocodeToEdit = promocodes.find(
                    promocode => promocode._id === formData._id,
                );
                if (!promocodeToEdit) {
                    throw "Promocode wasn't found";
                }

                const updatedFields: { [key: string]: any } = {};

                for (let key in promocodeToEdit) {
                    const originalValue: any =
                        promocodeToEdit[key as keyof typeof promocodeToEdit];

                    if (!originalValue) {
                        continue;
                    }

                    const newValue = formData[key];
                    if (originalValue !== newValue) {
                        updatedFields[key] = newValue;
                    }
                }

                for (const [key, value] of Object.entries(updatedFields)) {
                    await PromocodesService.set(formData._id, key, value);
                }

                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.edited,
                );
                clearFields();
                setShowForm(FormMode.HIDDEN);

                setPromocodes([]);
            } catch (error) {
                console.log('Edit promocode error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notEdited,
                );
            }
        },
        [formData, promocodes, texts.messages],
    );

    const remove = useCallback(
        async (_id: string) => {
            try {
                await PromocodesService.remove(_id);
                setPromocodes(prev =>
                    prev.filter(promocode => promocode._id !== _id),
                );
                showNotification(
                    NotificationType.SUCCESS,
                    texts.messages.removed,
                );
            } catch (error) {
                console.log('Remove promocode error: ', error);
                showNotification(
                    NotificationType.ERROR,
                    texts.messages.notRemoved,
                );
            }
        },
        [PromocodesService, showNotification, texts.messages],
    );

    const formFields = [
        { key: 'title', placeholder: texts.placeholders.title },
        { key: 'value', placeholder: texts.placeholders.value },
    ];

    return (
        <div className="promocodes">
            <p
                className="promocodes_show-hide"
                onClick={() =>
                    setShowForm(isShowForm ? FormMode.HIDDEN : FormMode.ADD)
                }>
                {isShowForm ? texts.buttons.hide : texts.buttons.show}
            </p>
            <form className={`promocodes_form ${isShowForm ? 'show' : ''}`}>
                {formFields.map(field => (
                    <Input
                        key={`admin field ${field.key}`}
                        value={formData[field.key]}
                        onChange={(value: string) =>
                            handleChange(field.key, value)
                        }
                        placeholder={field.placeholder}
                    />
                ))}

                <div className="promocodes_form_buttons">
                    <Button
                        label={
                            showForm === FormMode.ADD
                                ? texts.buttons.add
                                : texts.buttons.edit
                        }
                        disabled={isButtonDisabled}
                        type={ButtonType.OUTLINED}
                        handlerClick={showForm === FormMode.ADD ? add : edit}
                    />
                </div>
            </form>

            {promocodes.length ? (
                <div className="promocodes_list">
                    {promocodes.map(promocode => (
                        <div
                            className="promocodes_list_item"
                            key={promocode._id}>
                            <h4>{promocode.title}</h4>

                            <p>{promocode.value}%</p>

                            <div className="promocodes_list_item_controls">
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={
                                        promocode.active
                                            ? texts.buttons.deactivate
                                            : texts.buttons.activate
                                    }
                                    handlerClick={() =>
                                        promocode._id
                                            ? promocode.active
                                                ? deactivate(promocode._id)
                                                : activate(promocode._id)
                                            : ''
                                    }
                                />
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.edit}
                                    handlerClick={() =>
                                        promocode._id &&
                                        setUpEditData(promocode._id)
                                    }
                                />
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.remove}
                                    handlerClick={() =>
                                        promocode._id && remove(promocode._id)
                                    }
                                />
                            </div>
                        </div>
                    ))}

                    {isShowPaginationButton ? (
                        <div className="promocodes_list_pagination">
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadPromocodes}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <h4 className="promocodes_empty">{texts.listEmpty}</h4>
            )}
        </div>
    );
}
