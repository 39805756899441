/** @format */

import { iWishListService } from '@app/types/injector.type';

export default class WishListService implements iWishListService {
    private readonly localStorageKey = 'WishList';

    public getData(): string[] {
        const key = this.localStorageKey;

        const localStorageData = localStorage.getItem(key);
        if (!localStorageData) {
            return [];
        }

        const dataList = JSON.parse(localStorageData);
        if (!dataList.length) {
            return [];
        }

        return dataList;
    }

    public setData(newList: string[]): void {
        const key = this.localStorageKey;
        localStorage.setItem(key, JSON.stringify(newList));
    }
}
