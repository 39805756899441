/** @format */

export interface ButtonProps {
    label: string;
    disabled?: boolean;
    size?: ButtonSize;
    type?: ButtonType;
    handlerClick?: (event: any) => void;
}

export enum ButtonSize {
    SMALL = 'size-small',
    BIG = 'size-big',
}

export enum ButtonType {
    DEFAULT = '',
    OUTLINED = 'type-outlined',
}
