/** @format */

import { iCoursesService, iWebSocketService } from '@app/types/injector.type';
import injectorService from './injector.service';
import { Course } from '@app/types/course.type';

const urls = Object.freeze({
    GET: 'get',
    SET: 'set',
    ADD: 'add',
    DELETE: 'delete',
});

export default class CoursesService implements iCoursesService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'courses';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async add(newCourse: Course): Promise<Course> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: newCourse,
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `CoursesService add error: ${error}`;
        }
    }

    async get({
        from,
        to,
        userId,
    }: {
        from: number;
        to: number;
        userId?: string;
    }): Promise<[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: { from, to, userId },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `CoursesService get error: ${error}`;
        }
    }

    async set(_id: string, key: string, value: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.SET}`,
                data: {
                    _id,
                    key,
                    value,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `CoursesService set error: ${error}`;
        }
    }

    public async remove(_id: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.DELETE}`,
                data: { _id },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `CoursesService remove error: ${error}`;
        }
    }
}
