import { Link, useLocation } from 'react-router-dom';
import './BreadCrumbs.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import { ScreenPath } from '@app/types/screen.type';

interface Crumb {
    label: string;
    link: string;
}

export default function BreadCrumbs() {
    const [crumbs, setCrumbs] = useState<Crumb[]>([]);

    const { contextTranslation } = useContext(TranslationContext);

    const location = useLocation();

    const path = useMemo(() => location.pathname, [location]);
    const texts = useMemo(
        () => contextTranslation.BreadCrumbs,
        [contextTranslation],
    );

    const isShow = useMemo(() => crumbs.length > 1, [path, crumbs]);

    useEffect(() => {
        parsePath();
    }, [path, contextTranslation]);

    function parsePath(): void {
        const chunks = path.split('/');

        if (
            (chunks.includes(ScreenPath.CATALOGUE) ||
                chunks.includes(ScreenPath.BLOG)) &&
            chunks.length > 2
        ) {
            if (
                chunks.includes(ScreenPath.CATALOGUE) &&
                chunks.includes('manufacturer')
            ) {
                chunks.pop();
            }

            chunks.pop();
        }

        const newCrumbs: Crumb[] = chunks.map((chunk, index) => {
            const link = chunks.slice(0, index + 1).join('/');
            const label = findTranslation(chunk, texts);
            return { label, link };
        });

        setCrumbs(newCrumbs);
    }

    function findTranslation(
        key: string,
        translation: string | Object,
    ): string {
        if (!key) {
            return texts.home;
        }

        const convertedKey = linkToKey(key);

        if (typeof translation === 'string') {
            return translation;
        }

        for (const [sectionKey, value] of Object.entries(translation)) {
            if (sectionKey === convertedKey) {
                return typeof value === 'string' ? value : value?.title || key;
            }

            if (typeof value === 'object') {
                const nestedTranslation = findTranslation(key, value);
                if (nestedTranslation !== key) {
                    return nestedTranslation;
                }
            }
        }

        return key;
    }

    function linkToKey(link: string): string {
        return link.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
    }

    return (
        <>
            {isShow ? (
                <div className="breadCrumbs">
                    {crumbs.map((crumb, i) => (
                        <React.Fragment key={`crumb ${crumb.link}`}>
                            <Link to={crumb.link}>
                                <h4>{crumb.label}</h4>
                            </Link>
                            {crumbs[i + 1] ? <h4>/</h4> : ''}
                        </React.Fragment>
                    ))}
                </div>
            ) : null}
        </>
    );
}
