/** @format */

export enum ScreenPath {
    HOME = '',
    CATALOGUE = 'catalogue',
    PERSONAL_AREA = 'personal-area',
    WISH_LIST = 'wish-list',
    CART = 'cart',
    ORDER = 'order',
    BLOG = 'blog',
    ABOUT_STORE = 'about-store',
    DELIVERY = 'delivery',
    PAYMENT = 'payment',
    REFUND = 'refund-and-exchange',
    PRIVACY = 'privacy',
    AGREEMENT = 'agreement',
    AUTH = 'auth',
}

export enum AuthScreenType {
    DEFAULT,
    REGISTER,
    RESTORE,
    CONFIRM,
}

export enum UserPersonalAreaScreenType {
    SETTINGS,
    ORDERS,
    COURSES,
}

export enum AdminPersonalAreaScreenType {
    GOODS,
    PROJECT_SETTINGS,
    ORDERS,
    ARTICLES,
    COURSES,
    PROMOCODES,
    MANUFACTURERS,
    SETTINGS,
}
