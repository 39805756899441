/** @format */

import { iGoodService, iWebSocketService } from '@app/types/injector.type';
import injectorService from './injector.service';
import { GenderType, Good, SortType } from '@app/types/good.type';

const urls = Object.freeze({
    ADD: 'add',
    REMOVE: 'remove',
    EDIT: 'edit',
    GET: 'get',
    GET_BRANDS: 'getBrands',
});

export default class GoodService implements iGoodService {
    private source: string;
    private websocket!: iWebSocketService;

    constructor() {
        this.source = 'good';
        this.websocket = injectorService.get('WebSocketService');
    }

    public async add(newGood: {
        title: {};
        type: string;
        subType: string;
        description: {};
        quantity: number;
        price: number;
        media: string[];
    }): Promise<Good> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.ADD}`,
                data: {
                    ...newGood,
                    rate: 0,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `GoodService add error: ${error}`;
        }
    }

    public async remove(_id: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.REMOVE}`,
                data: { _id },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `GoodService add error: ${error}`;
        }
    }

    public async edit(_id: string, key: string, value: string): Promise<void> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.EDIT}`,
                data: { _id, key, value },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `GoodService edit error: ${error}`;
        }
    }

    public async get({
        from,
        to,
        type,
        subType,
        subSubType,
        manufacturers,
        sex,
        sortBy,
        _ids,
    }: {
        from?: number;
        to?: number;
        type?: string;
        subType?: string;
        subSubType?: string;
        manufacturers?: string[];
        sex?: GenderType;
        sortBy?: SortType;
        _ids?: string[];
    }): Promise<Good[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET}`,
                data: {
                    from,
                    to,
                    type,
                    subType,
                    subSubType,
                    manufacturers,
                    sex,
                    sortBy,
                    _ids,
                },
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `GoodService get error: ${error}`;
        }
    }

    public async getBrands(): Promise<string[]> {
        try {
            const { success, data } = await this.websocket.sendMessage({
                route: `${this.source}/${urls.GET_BRANDS}`,
                data: {},
            });

            if (!success) {
                throw data;
            }

            return data;
        } catch (error) {
            throw `GoodService getBrands error: ${error}`;
        }
    }
}
