import injectorService from '@app/services/injector.service';
import './Courses.scss';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TranslationContext } from '@app/contexts/translationContext';
import { UserContext } from '@app/contexts/userContext';
import { Course } from '@app/types/course.type';
import { NotificationType } from '@app/types/notification.type';
import { NotificationContext } from '@app/contexts/notificationContext';
import { LanguageType } from '@app/types/language.type';
import { IconType } from '@app/types/image.type';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';

export default function Courses() {
    const { contextTranslation } = useContext(TranslationContext);
    const { setContextNotification } = useContext(NotificationContext);
    const { contextUser } = useContext(UserContext);

    const [courses, setCourses] = useState<Course[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState('');

    const texts = useMemo(
        () => contextTranslation.PersonalArea.courses,
        [contextTranslation],
    );

    const NotificationService = injectorService.get('NotificationService');
    const TranslationService = injectorService.get('TranslationService');
    const CoursesService = injectorService.get('CoursesService');

    const paginationLength = 5;

    const language = useMemo(
        () => LanguageType[TranslationService.language].toLowerCase(),
        [TranslationService.language],
    );

    useEffect(() => {
        if (!contextUser._id) {
            return;
        }

        loadCourses();
    }, [contextUser]);

    const loadCourses = useCallback(async () => {
        try {
            const data = await CoursesService.get({
                from: courses.length,
                to: courses.length + paginationLength,
                userId: contextUser._id,
            });

            setCourses([...courses, ...data]);
            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }
        } catch (error) {
            console.log("Data wasn't loaded: ", error);
            showNotification(NotificationType.ERROR, texts.messages.notLoaded);
        }
    }, [courses, contextUser]);

    const showNotification = useCallback(
        (type: NotificationType, message: string) => {
            setContextNotification(prev =>
                NotificationService.add(prev, type, message),
            );
        },
        [NotificationService],
    );

    return (
        <div className="courses">
            <div
                className={`courses_modal ${selectedCourse ? 'open' : ''}`}
                onClick={() => setSelectedCourse('')}>
                <IconType.CROSS />
                <figure onClick={event => event.stopPropagation()}>
                    <video src={selectedCourse} controls></video>
                </figure>
            </div>
            <div className="courses_list">
                {courses.length ? (
                    courses.map(course => (
                        <div
                            className="courses_list_item"
                            key={`course ${course._id}`}>
                            <figure>
                                <video
                                    src={course.media}
                                    autoPlay
                                    muted></video>
                            </figure>
                            <div className="courses_list_item_description">
                                <h4>
                                    {
                                        course.title[
                                            language as keyof typeof course.title
                                        ]
                                    }
                                </h4>
                                <p>
                                    {
                                        course.description[
                                            language as keyof typeof course.description
                                        ]
                                    }
                                </p>
                            </div>

                            <div className="courses_list_item_controls">
                                <Button
                                    type={ButtonType.OUTLINED}
                                    label={texts.buttons.open}
                                    handlerClick={() =>
                                        setSelectedCourse(course.media)
                                    }
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <h4 className="courses_list_empty">
                        {texts.coursesListEmpty}
                    </h4>
                )}

                {isShowPaginationButton ? (
                    <div className="goods_list_pagination">
                        <Button
                            type={ButtonType.OUTLINED}
                            label={contextTranslation.Buttons.pagination}
                            handlerClick={loadCourses}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}
