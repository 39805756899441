import {
    useContext,
    useLayoutEffect,
    useMemo,
    useState,
    useEffect,
} from 'react';
import './Blog.scss';
import { TranslationContext } from '@app/contexts/translationContext';
import injectorService from '@app/services/injector.service';
import { LanguageType } from '@app/types/language.type';
import { Article } from '@app/types/article.type';
import Button from '@app/components/common/button/Button';
import { ButtonType } from '@app/types/button.type';
import { Link } from 'react-router-dom';
import { ScreenPath } from '@app/types/screen.type';

export default function Blog() {
    const { contextTranslation } = useContext(TranslationContext);

    const [articles, setArticles] = useState<Article[]>([]);
    const [isShowPaginationButton, setIsShowPaginationButton] = useState(true);

    const ArticleService = injectorService.get('ArticleService');
    const TranslationService = injectorService.get('TranslationService');

    const texts = useMemo(() => contextTranslation.Blog, [contextTranslation]);
    const language = useMemo(
        () =>
            LanguageType[TranslationService.language].toLowerCase() as
                | 'ua'
                | 'en'
                | 'ru',
        [TranslationService.language],
    );

    const paginationLength = 8;

    useLayoutEffect(() => {
        loadArticles();
    }, []);

    async function loadArticles(): Promise<void> {
        try {
            const data = await ArticleService.get({
                from: articles.length,
                to: articles.length + paginationLength,
            });

            if (data.length !== paginationLength) {
                setIsShowPaginationButton(false);
            }

            setArticles([...articles, ...data]);
        } catch (error) {}
    }

    return (
        <div className="blog container">
            {articles.length ? (
                <>
                    <div className="blog_list">
                        {articles.map(article => (
                            <div
                                className={`blog_list_item`}
                                key={`user article ${article._id}`}>
                                <iframe
                                    src={`/${ScreenPath.BLOG}/${article._id}?preview=true`}
                                    scrolling="no"></iframe>

                                <h4>{article.title[language]}</h4>

                                <div className="blog_list_item_other">
                                    {new Date(article.date).toLocaleString()}

                                    <Link
                                        to={`/${ScreenPath.BLOG}/${article._id}`}>
                                        <Button
                                            type={ButtonType.OUTLINED}
                                            label={texts.read}
                                        />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    {isShowPaginationButton ? (
                        <div className="catalogue_pagination">
                            <Button
                                type={ButtonType.OUTLINED}
                                label={contextTranslation.Buttons.pagination}
                                handlerClick={loadArticles}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <h4 className="orders_empty">{texts.empty}</h4>
            )}
        </div>
    );
}
